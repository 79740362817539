import React from "react";
import { backendImageUrl } from "../../config/config";

const Component = ({ bannerData }) => {
    // const handleImageClick = () => {
    //     // Add logic to handle the click action, e.g., navigating to a page
    //     window.location.href = bannerData?.image_link;
    //   };
  return (
    // <div
    //   href={bannerData?.image_link}
    //   className="block w-full h-[85vh] lg:h-[80vh] relative bann_img cursor-pointer"
    //   style={{
    //     background: `url(${backendImageUrl + "" + bannerData?.image})`,
    //     backgroundSize: "cover",
    //   }}
    //   onClick={(e) => {
    //     e.preventDefault();
    //       handleImageClick();
    //     }}
    // >
    // </div>
    <a href={bannerData?.image_link}>
      <img   className=" cursor-pointer w-full" src={backendImageUrl + "" + bannerData?.image} alt={bannerData?.image}/>
    </a>
  );
};

export default Component;
