import React, { useEffect, useState } from "react";
import special_image from "../../assets/images/home/special offer.png";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialProduct } from "../../actions/GeneralActions";
import CounterTime from "./CounterTime";
import Loader from "../../components/Loader";
import { backendImageUrl } from "../../config/config";
import { Link } from "react-router-dom";
const SpecialOffer = () => {
  const [expiryTime, setExpiryTime] = useState(false);
  // const [timeRemaining, setTimeRemaining] = useState({});
  // const [timeCheck, settimeCheck] = useState(true);

  const { specialpPoduct, endDate, product, loading, status } = useSelector(
    (state) => state.specialProductState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpecialProduct);
  }, []);
  // useEffect(() => {
  //   if (status) {
  //     setExpiryTime(endDate);
  //   }
  // }, [status]);

  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const calculateTimeRemaining = () => {
      if (endDate) {
        const targetDate = new Date(endDate); // Convert the endDate string to a Date object

        // const targetDate = new Date("24 nov 2023 11:06:58"); // Convert the endDate string to a Date object
        const now = new Date().getTime();
        const difference = targetDate - now;
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          setCountdown({
            days: String(days).padStart(2, "0"),
            hours: String(hours).padStart(2, "0"),
            minutes: String(minutes).padStart(2, "0"),
            seconds: String(seconds).padStart(2, "0"),
          });
        } else {
          setExpiryTime(true);
          // If the countdown is over, you can take any action here
          setCountdown({
            days: "00",
            hours: "00",
            minutes: "00",
            seconds: "00",
          });
        }
      }
    };
    if (!expiryTime) {
      var timer = setInterval(calculateTimeRemaining, 1000);

      // Cleanup the timer on component unmount
      return () => clearInterval(timer);
    }
  }, [endDate]); // Add endDate as a dependency to recalculate when it changes

  return loading ? (
    <Loader />
  ) : (
    specialpPoduct && product && !expiryTime && (
      <div className="mt-4 sm:mt-6 md:mt-10 bg-[#f1f1f1]">
        <div className="container mx-auto py-3 px-6 sm:px-16 md:px-20 lg:px-28">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 items-center gap-y-8">
            <img
              src={backendImageUrl + specialpPoduct?.image}
              alt="special_offer"
              className="mix-blend-darken"
            />
            <div className="text-black px-0 md:pr-2 pl-0 md:pl-10 text-center md:text-left">
              <div className="text-[14px] font-medium flex items-center justify-center md:justify-start">
                <span className="tracking-wider inline-block">
                  SPECIAL OFFER
                </span>{" "}
                <span className="theme_bg_green text-white rounded  inline-block px-1.5 ml-2">
                  - {specialpPoduct?.percentage}%
                </span>
              </div>
              <div className="pt-3 tracking-wider text-[25px] font-semibold arial_font">
                {product?.title}
              </div>
              <p className="w-full md:w-[65%] pt-2 text-[13px] font-semibold opacity-70">
                {product?.description}
              </p>
              <div className="text-[31px] pt-2 theme_green font-semibold arial_font">
                {countdown.days} : {countdown.hours} : {countdown.minutes} :{" "}
                {countdown.seconds}
              </div>
              <div className="font-semibold pt-4 flex gap-x-4 justify-center md:justify-normal">
                <span className="inline-block text-[15px] py-1 px-2 bg-white rounded-sm">
              Get Only ₹ {specialpPoduct?.amount}
            </span>{" "}
                <Link
                  to={`/products/${product?.slug}`}
                  className="px-2 py-1 text-white bg-[#fe8a00] rounded-[3px] text-[17px]"
                >
                  SHOP NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SpecialOffer;
