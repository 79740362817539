import React from "react";
import Modal from "react-modal";
// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "0",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    width: "500px", // Customize the max width of the modal
    borderRadius: "30px",
  },
};

// Component for displaying the video inside the popup
const CustomerReviewSuccessPopup = ({ isOpen, onRequestClose,tickImg }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <div className=" bg-white rounded-[30px] font-semibold shadow-3xl stock_shadow border-[1px] text-[#515151] pt-6 pb-4 px-4 text-center">
        <div className="w-full md:w-[90%] mx-auto">
            <img src={tickImg} alt="tick" className="w-[80px] h-[80px] mx-auto" />
          <div className="text-[20px] mt-4">
            Your review/feedback has been submitted.
          </div>
          <div className="text-[19px] mt-3 px-1 sm:px-2 md:px-3 lg:px-5 xl:px-7">
            Once it is verified, the review will appear on the product page.
          </div>
          <div className="mt-5 sm:mt-5 md:mt-6">
            <button onClick={onRequestClose} className="px-8 py-1.5 bg-[#9bb2f8] rounded-xl text-white inline-block uppercase mx-auto min-w-[110px]">
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerReviewSuccessPopup;
