import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { backendImageUrl } from "../../config/config";

const ProductDesignCarousal = ({ carousalArray }) => {
  const finalArray =carousalArray ? JSON.parse(carousalArray && carousalArray) : [];

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: finalArray.length >= 3 ? 3 : finalArray.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1181,
        settings: {
          slidesToShow: finalArray.length >= 2 ? 2 : finalArray.length,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: finalArray.length >= 1 ? 1 : finalArray.length,
        },
      },
      // {
      //   breakpoint: 500,
      //   settings: {
      //     slidesToShow: finalArray.length >= 1 ? 1 : finalArray.length,
      //   },
      // },
    ],
  };

  return (
    <div className="star_goodness">
      <Slider {...carouselSettings}>
        {finalArray.map((finArray , index) => (
          <div className=" px-2 h-full" key={index}>
            <div className="bg-[#d2d7d1] pt-4 pb-5 px-3 rounded-3xl h-full">
              <img
                src={backendImageUrl + finArray?.image}
                alt="carousal_image"
                // className="w-[60px] h-[60px] sm:w[60px] sm:w-[60px] md:w-[80px] md:h-[80px] mx-auto rounded-full"
                className="w-[110px] h-[110px] mx-auto rounded-full"
              />
              <h3 className="text-center mt-2 text-md capitalize text-[#4f504f] font-semibold">
                {finArray?.title}
              </h3>
              <p className="mt-2 font-normal px-2">{finArray?.text}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="text-3xl font-semibold absolute top-1/2  -left-8 md:-left-14 -translate-y-1/2 cursor-pointer z-10 text-[#8c9991]"
    >
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-3xl font-semibold absolute top-1/2 -right-8 md:-right-14 -translate-y-1/2 cursor-pointer z-10 text-[#8c9991]"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );
};
export default ProductDesignCarousal;
