import React from "react";
import MyOrdersComponent from "./MyOrdersComponent";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrders } from "../../actions/userDashboardActions";
import Loader from "../Loader";
import ViewOrders from "./ViewOrders";
import { useState } from "react";
const MyOrders = ({ setOptionChange, optionChange }) => {
  const dispatch = useDispatch();
  const [singleData, setSingleData] = useState({});
   const [fullData, setFullData] = useState([]);
  const { orderDetails, loading } = useSelector(
    (state) => state.myOrdersDetailsState
  );
  useEffect(() => {
    dispatch(getMyOrders);
  }, []);
  const getOrderId = (data) => {
    setSingleData(JSON.parse(data?.cart_data));
    setFullData(data);
  };
  return (
    <>
      {optionChange === "my_order" &&
        (loading ? (
          <Loader />
        ) : (
            orderDetails.length > 0 &&
            <div className="mx-auto mt-5 sm:mt-6 md:mt-7 pb-2">
                <div className=" flex flex-col gap-y-2 sm:gap-y-2 md:gap-y-3 lg:gap-y-4 overflow-x-auto">
                  {orderDetails &&
                    orderDetails.map((orderDetails, index) => (
                      <MyOrdersComponent
                        key={orderDetails.id}
                        optionChange={optionChange}
                        setOptionChange={setOptionChange}
                        OrderData={orderDetails}
                        getOrderId={getOrderId}
                      />
                    ))}
                </div>
          </div>
          ))}
      {optionChange === "vieworders" && (
        <div className="max-w-[700px] mx-auto mt-5 sm:mt-6 md:mt-7 pb-2 overflow-x-auto sm:overflow-x-auto md:overflow-x-auto lg:overflow-x-visible">
          <div className="min-w-[900px] lg:min-w-fit">
          <ViewOrders setOptionChange={setOptionChange} singleData={singleData} orderNumber={fullData?.checkout_number} status={fullData?.status} date={fullData?.created_at} OrderData={fullData} />

          </div>
        </div>
      )}
    </>
  );
};

export default MyOrders;
