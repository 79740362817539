import React from 'react'
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import { FaPlay } from "react-icons/fa";


const Component = ({ name, desc, img }) => {
  return (
    <div className='relative py-3 px-2 text-black mx-5 sm:mx-6 md:mx-8 text-center rounded-lg '>
      <img src={img} alt="image" className='mx-auto h-52 object-cover' />
      <div>
        <div className='text-lg font-semibold'>{name}</div>
        <p className=' text-[14px] text-[#757575] font-medium pt-1 mt-2'>{desc}</p>
      </div>
    </div>
  )
}

export default Component