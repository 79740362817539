import React, { useState } from "react";
import { HiMinus, HiPlus } from "react-icons/hi";
import { backendImageUrl, backendUrl } from "../../config/config";
import { toast } from "react-toastify";
import axios from "axios";
import { getWishListDetails } from "../../actions/userDashboardActions";
import { useDispatch } from "react-redux";
import { addSingleCart } from "../../actions/CartActions";

const WhislistComponents = ({ product }) => {
  const [count, setCount] = useState(1);
  const [wishlistId, setWishlistId] = useState(null);
  const [stock, setStock] = useState(product?.stock);
  const [loading, setLoading] = useState(false);

  const increment =async () => {
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    let targetObject = getSingleDatailCart(checkcart , product?.id);
    if (loading) {
      // If an increment operation is already in progress, return early
      return;
    }

    setLoading(true);

    try {
      const response = await checkVariant();
      let msg = "Stock Limit Reached";
      if (stock === 0) {
        msg = "This product is out of Stock ";
      }
      if (
        response?.data?.variation &&
        response?.data?.variation?.stock > 0 &&
        response?.data?.variation?.stock > (targetObject?.quantity ? (targetObject?.quantity+count) : count)
      ) {
        setCount((prevCount) => prevCount + 1);
      } else {
        toast.warning(msg);
      }
    } catch (error) {
      console.error("Error in increment:", error);
    } finally {
      setLoading(false);
    }
  };
  async function checkVariant() {
    const response = await axios.get(
      backendUrl + `get_variationtypebyproductVariationId/${product?.id}`
    );
    return response;
  }
  const decrement = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };
  const dispatch = useDispatch();

  const removeWishlist = async () => {
    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.post(
        backendUrl + "removeWishlist",
        {
            wishlistId: product?.wishlistId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === "1") {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
        dispatch(getWishListDetails);
        setWishlistId(product?.wishlistId)
      }
    } catch (error) {
      toast.error("Something went wrong , Please try again !", {
        position: "top-right",
        autoClose: 3000, // Auto-close the toast after 3 seconds
      });
    }
  };
  const AddCart = async() => {
      if (stock > 0) {
        const checkcart = JSON.parse(localStorage.getItem("cart"));
        let targetObject = getSingleDatailCart(checkcart , product?.id);
        if (loading) {
          // If an increment operation is already in progress, return early
          return;
        }
      
        setLoading(true);
        try {
          const response = await checkVariant();
          let msg = "Stock Limit Reached";
          if (stock === 0) {
            msg = "This product is out of Stock ";
          }
  
          if (
            response?.data?.variation &&
            response?.data?.variation?.stock >= (targetObject?.quantity ? (targetObject?.quantity+count) : count)
          ) {
            if (count > 0) {
              const sendData = {
                id: product?.product_id,
                quantity: count,
                title: product?.title,
                image_link: backendImageUrl + product?.product_image,
                variationId: product?.id,
                variation: product?.productvariationdata,
              };
              dispatch(addSingleCart({ sendData }));
            } else {
              toast.warning("Product Count should atleast be 1");
            }
          } else {
            toast.warning(msg);
          }
        } catch (error) {
          console.error("Error in increment:", error);
        } finally {
          setLoading(false);
        }
      }else{
        toast.warning("Product is Out of Stock", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
  };
  function getSingleDatailCart (checkcart , productId){
    let targetObject = null;
    if(checkcart?.length){
      targetObject = checkcart.find(
       (item) => item.variationId === productId
     );
   }
   return targetObject;
  }
  return (
    <>
      <div className={`flex items-center gap-x-2 sm:gap-x-2 md:gap-x-3 justify-normal sm:justify-normal md:justify-center font-medium ${wishlistId === product?.wishlistId ? "hidden" : ""}`}>
        <div className="w-[50%] sm:w-[50%] md:w-[70%] py-1 px-1 shadow flex items-center rounded-xl text-center text-sm justify-around gap-x-2">
          <div className="w-[55%]">
            <div class=" flex items-center gap-x-3">
              <img
                src={backendImageUrl + product?.product_image}
                alt="cartImage"
                class="w-[35px] h-[45px] object-cover"
              />
              <div class=" flex flex-col">
                <h3 class=" font-medium text-base text-left">{product?.title}</h3>
                <span class=" text-xs text-left">
                  {product?.variation_name} {product?.variation_type_name}
                </span>
              </div>
            </div>
          </div>
          <div className="w-[15%]">
            <div className="text-black text-base font-semibold flex gap-x-3 px-4  py-1 bg-[#e7e7e7] rounded items-center justify-center">
              <span onClick={decrement} className=" cursor-pointer">
                <HiMinus />
              </span>
              <span>{count}</span>
              <span onClick={increment} className=" cursor-pointer">
                <HiPlus />
              </span>
            </div>
          </div>
          <div className="w-[15%]">{product?.sale_price}</div>
          <div className="w-[15%] theme_green">In Stock</div>
        </div>
        <div className="py-1 px-3 bg-orange-400 rounded-xl text-white font-medium cursor-pointer text-sm sm:tex-sm md:text-base" onClick={AddCart}>
          Add to Cart
        </div>
        <div className="py-1 px-3 theme_light_bg_green rounded-xl text-white font-medium cursor-pointer text-sm sm:tex-sm md:text-base" onClick={removeWishlist}>
          Remove
        </div>
      </div>
    </>
  );
};

export default WhislistComponents;
