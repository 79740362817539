import React from 'react';
import Modal from 'react-modal';
import CartPopupComponents from './CartPopupComponents';
import { Link } from 'react-router-dom';
import { getCouponValue } from '../actions/userDashboardActions';
import { useDispatch } from 'react-redux';


// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(154 172 248 / 70%)',
    },
    content: {
        top: '0',
        right: '2%',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        width: '400px', // Customize the max width of the modal
        minHeight: "50%",
        borderRadius: "20px",
        maxHeight: '80%',
        height: "80%",
    },
};




// Component for displaying the video inside the popup
const CartPopup = ({ isOpen, onRequestClose, carts, removeCart, increaseCart, totalAmount, savedAmount }) => {
    const dispatch = useDispatch();
    
    function removeBodyClass() {
        dispatch(getCouponValue);
        document.body.classList.remove('cart_popup');
    }
    
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}
            ariaHideApp={false}
            appElement={document.getElementById('app')}
        >
            <div className='relative cart_products_parent flex-1'>
                <div className='mb-1 py-2 px-2 theme_bg_green text-white rounded-tl-[20px] rounded-tr-[20px] sticky top-0 z-10 text-center'>
                    Free Shipping on order above Rs.3000
                </div>
                <div className=' overflow-y-auto absolute left-0 pb-[50px] w-full h-full max-h-[80%] overflow-y-auto'>
                    {
                        carts.map((cart, index) => (
                            <CartPopupComponents key={index} cart={cart} removeCart={removeCart} increaseCart={increaseCart} />
                        ))
                    }
                </div>
            </div>
            <div className=' text-center'>
                <div className=' px-3 md:px-5 py-3'>
                    {
                        totalAmount &&
                        <div className=' flex items-center'>
                            <span className='w-1/2 text-base md:text-lg text-right'><span className='mr-3'>Subtotal</span> <span>:</span></span>
                            <span className=' w-1/2 text-base md:text-lg'>₹ {totalAmount}</span>
                        </div>
                    }{
                        savedAmount ?
                        <div className=' flex items-center'>
                            <span className='w-1/2 text-base md:text-lg text-right'><span className='mr-3'>Saved</span>  <span>:</span></span>
                            <span className=' w-1/2 text-base md:text-lg'>₹ {savedAmount}</span>
                        </div>:""
                    }

                    <p className=' text-center text-xs'>Shipping calculated at next step</p>
                    <Link to="/checkout" onClick={removeBodyClass} className=' bg-[#ff8a00] inline-block mt-3  rounded-md text-white w-auto  items-center uppercase justify-center px-3 py-2'>Check out</Link>
                </div>
            </div>
        </Modal >
    );
};


export default CartPopup