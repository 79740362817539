import React, { useEffect, useState } from "react";
import { AiFillStar, AiOutlineHeart } from "react-icons/ai";
import { HiPlus, HiMinus } from "react-icons/hi";
import SingleProductSlider from "./SingleProductSlider";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { backendImageUrl, backendUrl } from "../../config/config";
import { getVariationType } from "../../actions/variationTypeChangeActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addSingleCart } from "../../actions/CartActions";
import axios from "axios";
import { toast } from "react-toastify";
import { getWishListDetails } from "../../actions/userDashboardActions";
import OutofStockPopup from "../OutofStockPopup";
import offerImg from "../../assets/images/offerimage.png";
const SingleProductDetails = ({ product, product_images, productReviews }) => {
  const [hidePrevComponent, setHidePrevComponent] = useState(true);
  const [stock, setStock] = useState(product?.variation["0"]?.stock);
  const [wishlist, setWishlist] = useState(false);
  const [getVariationId, setGetVariationId] = useState(false);
  const [tabBgColor, setTabBgColor] = useState(0);
  const [border, setBorder] = useState(0);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [variationPercentage, setVariationPercentage] = useState(0);
  const [variationDetails, setVariationDetails] = useState([]);

  const [selectedImage, setSelectedImage] = useState(
    product_images && product_images[0]
  );

  const { variation: newVariation, loading: variationLoading } = useSelector(
    (state) => state.variationTypeChangeState
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    document.body.classList.remove("out_popup");
    setModalIsOpen(false);
  };
  const changeImage = (border, img_link) => {
    setBorder(border);
    setSelectedImage(img_link);
  };

  const increment = async () => {
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    let targetObject = getSingleDatailCart(checkcart, getVariationId);
    if (loading) {
      // If an increment operation is already in progress, return early
      return;
    }
    setLoading(true);

    try {
      const response = await checkVariant();
      let msg = "Stock Limit Reached";
      if (stock === 0) {
        msg = "This product is out of Stock ";
      }
      if (
        response?.data?.variation &&
        response?.data?.variation?.stock > 0 &&
        response?.data?.variation?.stock >
          (targetObject?.quantity ? targetObject?.quantity + count : count)
      ) {
        setCount((prevCount) => prevCount + 1);
      } else {
        toast.warning(msg);
      }
    } catch (error) {
      console.error("Error in increment:", error);
    } finally {
      setLoading(false);
    }
  };
  async function checkVariant() {
    const response = await axios.get(
      backendUrl + `get_variationtypebyproductVariationId/${getVariationId}`
    );
    return response;
  }
  const decrement = () => {
    if (stock > 0) {
      if (count > 1) {
        setCount((prevCount) => prevCount - 1);
      }
    } else {
      toast.warning("This product is out of Stock");
    }
  };

  const dispatch = useDispatch();

  const handleTabLink = (variation, index) => {
    setCount(1);
    setStock(variation?.stock);
    setGetVariationId(variation?.id);
    dispatch(getVariationType(variation?.id));
    setTabBgColor(index);
    setHidePrevComponent(false);
    setVariationPercentage(
      (
        ((parseFloat(variation?.mrp_price) -
          parseFloat(variation?.sale_price)) /
          parseFloat(variation?.mrp_price)) *
        100
      ).toFixed(0)
    );
    setVariationDetails(variation);
  };

  useEffect(() => {
    dispatch(getVariationType(product?.variation["0"].id));
    setVariationDetails(product?.variation["0"]);
    setGetVariationId(product?.variation["0"].id);
    setVariationPercentage(
      (
        ((parseFloat(product?.variation["0"]["mrp_price"]) -
          parseFloat(product?.variation["0"]["sale_price"])) /
          parseFloat(product?.variation["0"]["mrp_price"])) *
        100
      ).toFixed(0)
    );
  }, []);

  const addCart = async () => {
    if (stock > 0) {
      const checkcart = JSON.parse(localStorage.getItem("cart"));
      let targetObject = getSingleDatailCart(checkcart, getVariationId);
      if (loading) {
        // If an increment operation is already in progress, return early
        return;
      }
      setLoading(true);
      try {
        const response = await checkVariant();
        let msg = "Stock Limit Reached";
        if (stock === 0) {
          msg = "This product is out of Stock ";
        }

        if (
          response?.data?.variation &&
          response?.data?.variation?.stock >=
            (targetObject?.quantity ? targetObject?.quantity + count : count)
        ) {
          if (count > 0) {
            const sendData = {
              id: product.id,
              quantity: count,
              title: product?.title,
              image_link: backendImageUrl + product?.product_image,
              variationId: getVariationId,
              variation: variationDetails,
            };
            dispatch(addSingleCart({ sendData }));
          } else {
            toast.warning("Product Count should atleast be 1");
          }
        } else {
          toast.warning(msg);
        }
      } catch (error) {
        console.error("Error in increment:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setModalIsOpen(true);
      document.body.classList.add("out_popup");
    }
  };
  function getSingleDatailCart(checkcart, variationId) {
    let targetObject = null;
    if (checkcart?.length) {
      targetObject = checkcart.find((item) => item.variationId === variationId);
    }
    return targetObject;
  }
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      product_images && product_images.length > 2
        ? 3
        : product_images && product_images.length,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2300,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1181,
        settings: {
          slidesToShow:
            product_images && product_images.length > 2
              ? 2
              : product_images && product_images.length,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow:
            product_images && product_images.length > 2
              ? 2
              : product_images && product_images.length,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow:
            product_images && product_images.length > 2
              ? 3
              : product_images && product_images.length,
        },
      },
    ],
  };

  const addToWishlist = async () => {
    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.post(
        backendUrl + "addWishlist",
        { productId: product.id, status: wishlist, getVariationId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === "1") {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
        setWishlist(response.data.wishlist);
        dispatch(getWishListDetails);
      }
    } catch (error) {
      // Handle API request errors (e.g., show error message)
      if (error.response.status === 401) {
        toast.error("Please Login for adding in Wishlist", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      } else {
        toast.error("Something went wrong , Please try again !", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
      setWishlist(false);
    }
  };
  const styles = {
    backgroundImage: `url(${offerImg})`,
    backgroundSize: "60%",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
  };
  // const [firstPart, secondPart] = product && product.title.split(' ');

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 mt-3 sm:mt-3 md:mt-0">
        <div className="flex flex-col justify-between gap-y-1">
          <div className="flex-1 flex">
            <img
              src={product && backendImageUrl + selectedImage}
              alt="single_product"
              className="object-contain max-w-[80%] lg:max-w-[525px] mx-auto"
            />
          </div>
          <div className=" mt-3 px-[15%] sm:px-[22%] md:px-[25%]">
            <Slider {...carouselSettings}>
              {product_images &&
                product_images.map((img_arr, index) => (
                  <SingleProductSlider
                    key={index}
                    border={index}
                    border_index={border}
                    image_link={img_arr}
                    changeImage={changeImage}
                  />
                ))}
            </Slider>
          </div>
        </div>
        <div className=" flex flex-col justify-between arial_font">
          <div className="bg-[#e2f7f8] px-3 py-8 md:px-16 md:py-8 font-semibold relative overflow-hidden">
            {/* <span className="bg-[#8f65d0] py-[10px] leading-[1] text-[20px] md:text-[24px] text-white px-3 absolute top-[10%] right-[-10px] w-[80px] sm:w-[110px] md:min-w-[110px] lg:w-[120px] rounded-s-2xl">
              <p className="mt-0">
                {variationPercentage ? variationPercentage  : 0}%
              </p>
              <p className="mt-0 ">OFF</p>
            </span> */}
            <span
              className="absolute top-[10%] right-0 text-[20px] md:text-[24px] text-white"
              style={styles}
            >
              <div className="w-[170px] h-[90px] relative">
                <div className=" absolute top-1/2 right-[15%] -translate-y-1/2 leading-[1.2]">
                  <p className="mt-0">
                    {variationPercentage ? variationPercentage : 0}%
                  </p>
                  <p className="mt-0 ">OFF</p>
                </div>
              </div>
            </span>
            <h3 className="text-3xl md:text-5xl lg:text-6xl w-[45%] md:w-2/5 prod_title">
            {/* <div>{firstPart}</div>
            <div>{secondPart}</div> */}
              {product && product.title}
            </h3>
            <div className="uppercase pt-3">
              {product && product.short_desc}
            </div>
            <div className="pt-5 sm:pt-7 md:pt-10 lg:pt-12  xl:pt-16">
              <div className="flex gap-x-7">
                <div className=" bg-white px-2 py-1 flex text-[#f7c066] items-center rounded">
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <AiFillStar />
                  <span className="ml-1">
                    ({productReviews && productReviews.length})
                  </span>
                </div>
                <div
                  onClick={addToWishlist}
                  className={`cursor-pointer px-2 py-1 flex text-black items-center gap-x-2 font-normal text-sm rounded bg-white`}
                >
                  <span className={`${wishlist ? "bg-red-500" : ""}`}>
                    <AiOutlineHeart size={18} />
                  </span>
                  <span>Add to wishlist</span>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 md:px-16 mt-2 mr-4 md:mr-10 font-semibold">
            {product && product.description !== "" && (
              <div className=" leading-10">{product.description}</div>
            )}
            <div className="flex items-center text-lg gap-x-3 mt-6 gap-y-2 flex-wrap arial_font">
              <span>Size</span>
              {product &&
                product.variation &&
                product.variation.map((variation, index) => (
                  <span
                    key={variation?.id}
                    onClick={() => handleTabLink(variation, index)}
                    className={`py-1 px-2 cursor-pointer  rounded ${
                      index === tabBgColor
                        ? "text-white bg-[#87948d]"
                        : "text-black bg-[#d2d7d1]"
                    }`}
                  >
                    {variation.variation_name} {variation.variation_type_name}
                  </span>
                ))}
              {/* <span className='py-1 px-2 text-white bg-[#87948d] rounded'>50ml</span>
              <span className='py-1 px-2 text-black bg-[#d2d7d1] rounded'>100ml</span>
              <span className='py-1 px-2 text-black bg-[#d2d7d1] rounded'>200ml</span> */}
            </div>
            <div className="mt-4 flex gap-x-4 font-bold text-3xl arial_font">
              {hidePrevComponent ? (
                product &&
                product.variation &&
                product.variation.map((product_det, index) => (
                  <React.Fragment key={product_det?.sale_price}>
                    {index === 0 && (
                      <>
                        <span className=" flex gap-x-2">
                          <span>₹</span> <span>{product_det.sale_price}</span>
                        </span>
                        {product_det.mrp_price && (
                          <span className="ml-3 opacity-60">
                            <s className=" flex ">
                              <span>₹</span>{" "}
                              <span>{product_det.mrp_price}</span>
                            </s>
                          </span>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {newVariation && (
                    <>
                      <span className="flex gap-x-2">
                        <span>₹</span>
                        <span>{newVariation.sale_price}</span>
                      </span>
                      {newVariation.mrp_price && (
                        <span className="ml-3 opacity-60">
                          <s className="flex">
                            <span>₹</span>
                            <span>{newVariation.mrp_price}</span>
                          </s>
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="mt-4 flex gap-x-4 font-bold text-3xl arial_font relative">
              <div className="text-black font-semibold text-lg flex gap-x-6 px-2 py-2 bg-[#e7e7e7] rounded items-center">
                <span onClick={decrement} className=" cursor-pointer">
                  <HiMinus />
                </span>
                <span>{count}</span>
                <span onClick={increment} className=" cursor-pointer">
                  <HiPlus />
                </span>
              </div>
              <div
                className={`uppercase text-lg text-white  rounded-md px-2 py-2 cursor-pointer ${
                  stock > 0 ? "bg-[#ff8a00] " : "bg-[#9bb2f8]"
                } ${
                  variationLoading
                    ? " opacity-50 pointer-events-none cursor-none"
                    : ""
                }`}
                onClick={addCart}
              >
                {stock > 0 ? "Add to cart" : "Out of stock"}
              </div>
              {modalIsOpen && (
                <OutofStockPopup
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                />
              )}
            </div>
            <div className="mt-7">
              <p> Enjoy with free shipping for orders of INR 1499/-.</p>
              <p className="mt-3">Product price is incl. of all taxes.</p>
              <p className="mt-3">Quality and purity guaranteed.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="text-3xl font-semibold absolute top-1/2 -left-6 sm:-left-5 md:-left-10  -translate-y-1/2 cursor-pointer z-10 text-[#7e7e7e]"
    >
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-3xl font-semibold absolute top-1/2 -right-6 sm:-right-5 md:-right-10  -translate-y-1/2 cursor-pointer z-10 text-[#7e7e7e]"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );
};

export default SingleProductDetails;
