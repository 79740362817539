import React, { useState } from "react";
import { AiFillStar, AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { backendImageUrl, backendUrl } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { getProductBySlug } from "../../actions/productActions";
import VariationComponent from "./VariationComponent";
import axios from "axios";
import { toast } from "react-toastify";
import { getWishListDetails } from "../../actions/userDashboardActions";

const Component = ({
  productObject,
  productId,
  name,
  pro_name,
  stars,
  img_link,
  variations,
  pro_slug,
  getvariation = true,
  isnewArrival,
  wishlistcheck =false,
}) => {

  const [wishlist, setWishlist] = useState(wishlistcheck);
  const [getVariationId, setGetVariationId] = useState(false);
  const { variation: newVariation } = useSelector(
    (state) => state.variationTypeChangeState
  );

  const redStars = new Array(stars).fill(true);
  const whiteStars = new Array(5 - stars).fill(false);

  const allStars = [...redStars, ...whiteStars];

  const dispatch = useDispatch();
    
  const getProducts = (id) => {
    dispatch(getProductBySlug(id));
  };
  var checkNewArrival = false;
  if (isnewArrival) {
    const productArray = isnewArrival.split(",");
    // Check if "newarrival" exists in the array
    var checkNewArrival = productArray.includes("newarrival");
  }

  const addToWishlist = async () => {

    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.post(
        backendUrl + "addWishlist",
        { productId, status: wishlist,getVariationId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status === "1") {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
        setWishlist(response.data.wishlist);
        dispatch(getWishListDetails);
      }
    } catch (error) {
      // Handle API request errors (e.g., show error message)
      if (error.response.status === 401) {
        toast.error("Please Login for adding in Wishlist", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      } else {
        toast.error("Something went wrong , Please try again !", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
      setWishlist(false);
    }
  };


  return (
    <div className="relative pt-6 pb-12 px-2 text-black mx-2 rounded-[13px] border bg-[#fbfbfb] overflow-hidden">
      {checkNewArrival && (
        <span className=" absolute top-[-10px] left-[-37px] pt-[20px] bg-[#ff7200] text-[#fff] text-[20px] font-semibold px-[30px] rotate-[-45deg]">
          New
        </span>
      )}
      <Link
        to={`/products/${pro_slug && pro_slug}`}
        onClick={() => getProducts(pro_slug && pro_slug)}
        className={`relative ${productObject && productObject.upcoming === 1 ? " pointer-events-none" : ""}`}
      > 
        <img
          src={
            img_link
              ? backendImageUrl + img_link
              : "https://herbkey.netbix.online/admin/public/images/1693456510_single_product.png"
          }
          alt="pro_img"
          className=" h-[250px] object-cover mx-auto relative z-10"
        />
      </Link>
      <div className="theme_green px-4 arial_font font-bold mt-2">
        <div className="text-lg leading-[1] font-bold">{name}</div>
        <p className="text-[17px] mt-0 font-normal leading-[1] mt-[3px]">{pro_name}</p>
        <div className="text-[14px] pt-1">
          {allStars.map((isRed, index) => (
            <span
              key={index}
              className={`inline-block ${
                isRed ? "text-[#808080a6]" : "text-white"
              }`}
            >
              <AiFillStar />
            </span>
          ))}
        </div>
        <VariationComponent
          productObject={productObject}
          variations={variations}
          setGetVariationId ={setGetVariationId}
          getVariationId ={getVariationId}
          getvariation={getvariation}
          name={name}
          productId={productId}
          img_link={img_link}
          newVariation={newVariation}
        />
        <div
          className="absolute top-3 right-3 cursor-pointer"
          onClick={productObject?.upcoming === 0 ?addToWishlist : null}
        >
          <AiFillHeart
            size={26}
            className={`${wishlist ? "text-[#b92929]" : "text-[#808080a6]"}`}
          />
        </div>
        <div className="absolute top-0 left-1/2 -translate-x-1/2 theme_bg_green rounded-b-md w-[35%] h-3"></div>
      </div>
    </div>
  );
};

export default Component;
