import React, { useState } from "react";
import {
  AiFillYoutube,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { BiLogoFacebook } from "react-icons/bi";
import validator from "validator";
import axios from "axios";
import { backendUrl } from "../../config/config";
import { toast } from "react-toastify";

const TopFooter = () => {
  const initialFormData = {
    email: "",
    terms: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validation logic
    const validationErrors = {};

    if (!validator.isEmail(formData.email)) {
      validationErrors.email = "Invalid email address.";
    }

    if (!formData.terms) {
      validationErrors.terms = "Terms and Condition is required";
    }

    // Add similar validations for other fields (contact_number, reason, message, attachment_link)

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission logic
      setFormData({ ...formData });
      setErrors({});
      try {
        const response = await axios.post(
          backendUrl + "store-subscribe",
          formData
        );
        // Handle success, redirect, or update UI as needed
 
        if (response.data === 200) {
          toast.success("You have suucessfully subscribed!");
        }
        setLoading(false);
        setFormData(initialFormData);
      } catch (error) {
        toast.error("Something went wrong , Please try again !");
        // Handle error and update UI accordingly
        setLoading(false);
      }
    } else {
      // Update state with validation errors
      setErrors(validationErrors);
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    setFormData({
      ...formData,
      [name]: name === "terms" ? checked : value,
    });
  };

  return (
    <div className="px-8 md:px-10 lg:px-16">
      <div className="flex justify-between sm:px-10 md:px-16 lg:px-28 flex-wrap sm:flex-nowrap gap-y-5">
        <div className="text-center w-full sm:w-[35%]">
          <div className="text-lg font-medium">Follow Us On</div>
          <p className="text-[13px] font-semibold pt-1">
            We would love to hear from you. Follow us on the below social media
            accounts and give us a shoutout.
          </p>
          <div className="pt-3 sm:pt-6 md:pt-10">
            <ul className="flex text-xl justify-evenly">
              <li>
                <a
                  href="https://www.youtube.com/@theherbkey4140"
                  target="_blank"
                >
                  <AiFillYoutube />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/theherbkey/" target="_blank">
                  <BiLogoFacebook />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/theherbkey/" target="_blank">
                  <AiOutlineInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FTheHerbKey"
                  target="_blank"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li>
                <a href="https://wa.me/+919500088250" target="_blank">
                  <AiOutlineWhatsApp />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="text-center w-full sm:w-[55%]">
          <div className="text-lg font-medium">Let's Stay in Touch</div>
          <p className="text-[13px] font-semibold pt-1 w-[100%] sm:w-[80%] md:w-[70%] lg:w-[55%] mx-auto">
            Get 100 Rs off on your first order, Offer available on a minimum
            purchase of 999/- only
          </p>
          <div className="flex flex-col  md:w-full lg:w-[75%] mx-auto mt-3 sm:mt-4 md:mt-6">
            <div className="flex">
              <input
                type="text"
                placeholder="Enter Your email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-[70%]"
              />
              <button disabled={loading} className={`bg-[#007386] px-2 py-1 text-white rounded-md w-[30%] ${loading ? "opacity-50" : ""}`}>
                Subscribe
              </button>
            </div>
            {errors.email && (
              <p className="bg-red-500 text-white text-start text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.email}
              </p>
            )}
          </div>

          <div className="pt-2 w-full lg:w-[75%] pl-2 md:pl-3 md:pr-2 mx-auto text-left">
            <div className="flex items-center gap-x-1 lg:gap-x-0">
              <input
                type="checkbox"
                name="terms"
                id="agree"
                value="1"
                onChange={handleChange}
                checked={formData.terms}
                className="agree_checkbox align-middle ml-0 lg:ml-2"
              />
              <label
                htmlFor="agree"
                className=" cursor-pointer text-[12px] align-middle ml-2"
              >
                I accept the terms & condition and the date protection
              </label>
            </div>
            {errors.terms && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.terms}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default TopFooter;
