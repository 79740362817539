import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
    name: "categories",
    initialState: {
        loading: false,
        category: [],
    },
    reducers: {
        categoryRequest(state, action) {
            return {
                loading: true,
            }
        },
        categorySuccess(state, action) {
            return {
                loading: false,
                category: action.payload.category,
                subcategory: action.payload.subcategory,
            }
        },
        categoryFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },

});

const { actions, reducer } = categorySlice;

export const { categoryRequest, categorySuccess, categoryFail } = actions;

export default reducer;
