// const backendUrl = 'http://localhost/herb/api/'; // Replace with your actual backend URL
// const backendImageUrl = 'http://localhost/herb/public/';

// const backendUrl = 'https://herbkey.netbix.online/admin/api/'; // Replace with your actual backend URL
// const backendImageUrl = 'https://herbkey.netbix.online/admin/public/';


// const backendUrl = 'https://theherbkey.com/admin/api/'; // Replace with your actual backend URL
// const backendImageUrl = 'https://theherbkey.com/admin/public/';

// const backendUrl = 'https://herbkey.netbix.cloud/admin/api/'; // Replace with your actual backend URL
// const backendImageUrl = 'https://herbkey.netbix.cloud/admin/public/';


const backendUrl = 'https://herbkey.netbixzie.in/admin/api/'; // Replace with your actual backend URL
const backendImageUrl = 'https://herbkey.netbixzie.in/admin/public/';

export { backendUrl, backendImageUrl };
