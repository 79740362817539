import React from 'react'
import { Link} from "react-router-dom";
import img1 from "../../assets/images/home/shop by - baby care.jpg";
import img2 from "../../assets/images/home/shop by - face care.jpg";
import img3 from "../../assets/images/home/shop by - hair care.jpg";

const ShopCategory = () => {
    return (
        <div className='container mx-auto py-4 px-8 sm:px-16 md:px-20 lg:px-28'>
            <div className='py-1 md:py-2 text-center'>
                <div className=' text-2xl font-semibold'>Shop By Concern</div>
                <p className='text-[14px] text-[#757575] font-medium pt-1 mt-2'>Our products cater to all ages , genders and skin types</p>
            </div>
            <div className='mt-3'>
                <div className="flex flex-wrap md:grid grid-cols-2 gap-4 grid-flow-row text-black shop_concern_parent poppins_font">
                    <Link to={'/concern-category/skin'} className=" text-center md:row-start-1 md:row-end-4 rounded-xl relative">
                        <img src={img2} alt="face" className=' w-full h-full object-cover rounded-xl' />
                        <span className='bg-white rounded-[6px] px-2 inline-block text-[13px] sm:text-[13px] md:text-[14px] w-max absolute bottom-4 left-1/2 -translate-x-1/2'>Face Care</span>
                    </Link>
                    <Link to={'/concern-category/baby'} className="w-[45%] md:w-full text-center md:col-start-2 md:col-end-4 h-[200px] rounded-xl relative">
                        <img src={img1} alt="face" className=' w-full h-full object-cover rounded-xl' />
                        <span className='bg-white rounded-[6px] px-2 inline-block text-[13px] sm:text-[13px] md:text-[14px] w-max absolute bottom-4 left-1/2 -translate-x-1/2'>Baby Care</span>
                    </Link>
                    <Link to={'/concern-category/hair'} className="w-[45%] md:w-full text-center col-span-2 md:col-start-2 md:col-end-4 h-[200px] rounded-xl relative row-start-3" >
                        <img src={img3} alt="face" className=' w-full h-full object-cover rounded-xl' />
                        <span className='bg-white rounded-[6px] px-2 inline-block text-[13px] sm:text-[13px] md:text-[14px] w-max absolute bottom-4 left-1/2 -translate-x-1/2'>Hair Care</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ShopCategory