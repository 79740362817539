import React, { useState, useEffect } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import ChangePassword from "../components/userDashboard/ChangePassword";
import AccountDetails from "../components/userDashboard/AccountDetails";
import ShippingAddress from "../components/userDashboard/ShippingAddress";
import MyOrders from "../components/userDashboard/MyOrders";
import Whislist from "../components/userDashboard/Whislist";
import { AiOutlineUser } from "react-icons/ai";
import ViewOrders from "../components/userDashboard/ViewOrders";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckUserLogin } from "../utils/utils";
import { getAccountDetails } from "../actions/userDashboardActions";
import { useDispatch, useSelector } from "react-redux";

const UserDashboard = () => {
  const [optionChange, setOptionChange] = useState("account");
  const [viewOrdersData, setViewOrdersData] = useState([]);
  const navigate = useNavigate();
  const { loading, userDetails } = useSelector(
    (state) => state.accountDetailsState
  );
  const checkuser = async () => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");

    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === false) {
      navigate("/");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let reloaditem = localStorage.getItem("userReload");
    if (reloaditem) {
      localStorage.removeItem("userReload");
      window.location.reload();
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    checkuser();
    dispatch(getAccountDetails);
    // if (optionChange === "account") {
    //   dispatch(getAccountDetails);
    // }
  }, []);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tbOption = searchParams.get("tbOption");

  useEffect(() => {
    // Check if 'tbOption' exists and has the desired value
    if (tbOption === "my_order") {
      // Perform your desired operations here
      setOptionChange("my_order");
    } else if (tbOption === "whislists") {
      setOptionChange("whislists");
    }
  }, [tbOption]);
  const logout = () => {
    localStorage.removeItem("auth_Token");
    navigate("/");
  };
  const getMyOrders = () => {
    setOptionChange("my_order");
  };
  return (
    <div>
      <Header />
      <div className="py-2 sm:py-3 md:py-4 container profile-container mx-auto px-3">
        <div className="shadow-lg py-3 sm:py-4 md:py-5 px-4 sm:px-5 md:px-7 flex items-center theme_bg_green text-white rounded-xl gap-x-2 sm:gap-x-4 md:gap-x-5">
          <div className="flex flex-col gap-y-1 sm:gap-y-1 md:gap-y-2 items-center">
            <span className="p-2 sm:p-2 md:p-2 bg-white rounded-full">
              <AiOutlineUser size={25} className=" text-black" />
            </span>
          </div>
          {/* {!loading &&  */}
          <div className="text-lg sm:text-xl md:text-xl xl:text-2xl font-medium capitalize">
            {userDetails?.name}
          </div>
          {/* } */}
        </div>
        <div className="text-lg font-medium pt-2 sm:pt-3 md:pt-4">
          My Profile
        </div>
        <div className=" overflow-x-auto pb-[5px] sm:pb-[5px] md:pb-0">
        <div className=" overflow-x-auto min-w-max sm:min-w-max md:min-w-max lg:min-w-full">
            <ul className="flex flex-wrap sm:flex-wrap md:flex-wrap lg:flex-wrap xl:flex-nowrap justify-center sm:justify-center md:justify-center lg:justify-center xl:justify-between gap-x-1 sm:gap-x-1 md:gap-x-1 lg:gap-x-2 xl:gap-x-3 gap-y-2 text-white text-lg font-medium mt-1 sm:mt-3">
              <li
                onClick={() => setOptionChange("account")}
                className={`min-w-[190px]  text-center rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer ${
                  optionChange === "account"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                Account Details
              </li>
              <li
                onClick={() => setOptionChange("change_password")}
                className={`min-w-[190px]  text-center rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer ${
                  optionChange === "change_password"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                Change Password
              </li>
              <li
                onClick={() => setOptionChange("shipping_address")}
                className={` min-w-[190px]  text-center rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer ${
                  optionChange === "shipping_address"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                Shipping Address
              </li>
              <li
                onClick={() => getMyOrders()}
                className={` min-w-[190px]  text-center rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer ${
                  optionChange === "my_order" || optionChange === "vieworders"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                My Orders
              </li>
              <li
                onClick={() => setOptionChange("whislists")}
                className={`rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer min-w-[190px] text-center ${
                  optionChange === "whislists"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                Wishlists
              </li>
              <li
                onClick={() => setOptionChange("logout")}
                className={`rounded-b-2xl px-2 sm:px-3 md:px-4 py-1 sm:py-2  cursor-pointer min-w-[190px] text-center ${
                  optionChange === "logout"
                    ? "bg-orange-400"
                    : "theme_light_bg_green"
                }`}
              >
                Logout
              </li>
            </ul>
          </div>
        </div>
        <div className="max-w-[700px] mx-auto mt-5 sm:mt-6 md:mt-7">
          {optionChange === "account" && <AccountDetails />}
          {optionChange === "change_password" && <ChangePassword />}
          {/* {optionChange === 'vieworders' && <ViewOrders setOptionChange={setOptionChange} />} */}
        </div>
        {optionChange === "shipping_address" && <ShippingAddress />}
        {/* <div className="max-w-[1050px] mx-auto mt-5 sm:mt-6 md:mt-7 overflow-x-auto pb-2"> */}
        {optionChange === "my_order" || optionChange === "vieworders" ? (
          <MyOrders
            optionChange={optionChange}
            setOptionChange={setOptionChange}
          />
        ) : (
          <></>
        )}
        {/* </div> */}
        {/* <div className="max-w-[1050px] mx-auto mt-5 sm:mt-6 md:mt-7 overflow-x-auto"> */}
        {optionChange === "whislists" && <Whislist />}
        {/* </div> */}
        {optionChange === "logout" && (
          <div className="px-1 sm:px-1 md:px-3 flex justify-center sm:justify-center md:justify-end">
            <div className="flex flex-col gap-y-2 sm:gap-y-2 ms:gap-y-3 border border-2 shadow-lg py-2 sm:py-3 px-2 sm:px-3 md:px-4 rounded-3xl">
              <span className=" text-lg font-medium">
                Are you sure you want to logout?
              </span>
              <div className="flex gap-x-2 justify-center items-center text-white font-medium text-sm">
                <span
                  onClick={() => logout()}
                  className="px-4 sm:px-4 md:px-8 bg-orange-400 py-1 sm:py-1 rounded-xl cursor-pointer"
                >
                  Yes
                </span>
                <span
                  onClick={() => setOptionChange("account")}
                  className="px-4 sm:px-4 md:px-8 theme_light_bg_green py-1 sm:py-1 rounded-xl cursor-pointer"
                >
                  No
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default UserDashboard;
