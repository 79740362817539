import axios from "axios";
import { productBySlugRequest, productBySlugSuccess, productBySlugFail } from "../slices/ProductBySlugSlice";
import { backendUrl } from "../config/config";
import { bannerFail, bannerRequest, bannerSuccess } from "../slices/bannerSlice";

let token = localStorage.getItem("auth_Token");


export const getProductBySlug = slug => async (dispatch) => {
    try {
        dispatch(productBySlugRequest())
        const { data } = await axios.get(backendUrl + `get_products_by_slug/${slug}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        dispatch(productBySlugSuccess(data))
    } catch (error) {
        dispatch(productBySlugFail(error.response.data.message));
    }
}
export const getBanners = async (dispatch) => {
    try {
        dispatch(bannerRequest())
        const { data } = await axios.get(backendUrl + `get-all-banners`)
        dispatch(bannerSuccess(data))
    } catch (error) {
        dispatch(bannerFail(error.response.data.message));
    }
}