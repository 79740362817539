import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Banner from "../components/Banner/Banner";
import BehindTheScenes from "../components/BehindTheScenes/BehindTheScenes";
import WhyChoose from "../components/WhyChoose/WhyChoose";
import ShopCategory from "../components/ShopCategory/ShopCategory";
import SpecialOffer from "../components/SpecialOffer/SpecialOffer";
import BestSellers from "../components/BestSellers/BestSellers";
import CelebrityNotes from "../components/CelebrityNotes/CelebrityNotes";
import Info from "../components/Infos/Infos";
import Footer from "../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getSubscribeModal } from "../actions/userDashboardActions";
import SubscribePopup from "../components/SubscribePopup";

const Home = () => {
  const [subScribePopup, setSubScribePopup] = useState(false);

  const dispatch = useDispatch();

  const { modal, loading: modalLoading } = useSelector(
    (state) => state.SubscribeModalState
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    // Delay execution by 10 seconds
    const timeoutId = setTimeout(() => {
      // Dispatch the action after 10 seconds
      dispatch(getSubscribeModal);
    }, 10000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Dispatch the action after 10 seconds
      if (!modalLoading && modal && modal !== null) {
        document.body.classList.add("subscribemodel");
        setSubScribePopup(true);
      }
    }, 10000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, [modal, modalLoading]);
  const closeSubModal = () => {
    document.body.classList.remove("subscribemodel");
    setSubScribePopup(false);
  };
  return (
    <>
      <Header />
      <Banner />
      <BehindTheScenes />
      <WhyChoose />
      <ShopCategory />
      <SpecialOffer />
      <BestSellers />
      <CelebrityNotes />
      <Info />
      <Footer />
      {subScribePopup && (
        <SubscribePopup
          isOpen={subScribePopup}
          ModalData={modal}
          onRequestClose={closeSubModal}
        />
      )}
    </>
  );
};

export default Home;
