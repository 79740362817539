import React, { Fragment, useEffect, useState } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Component from "../components/BestSellers/Component";
import Footer from "../components/Footer/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../actions/categoryActions";
import { backendImageUrl } from "../config/config";
import Loader from "../components/Loader";
import { getCategoryProducts } from "../actions/subcategoryProductsActions";
import ComboCategory from "../components/Combo/ComboCategory";
const Products = () => {
  const [hidePrevComponent, setHidePrevComponent] = useState(true);
  const [tabBgColor, setTabBgColor] = useState(0);

  const { products } = useSelector((state) => state.subcategoryProductsState);
  const { loading, category, subcategory } = useSelector(
    (state) => state.categoryState
  );

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getCategory(slug));
  }, [dispatch]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabLink = (id, index) => {
    dispatch(getCategoryProducts(id));
    setHidePrevComponent(false);
    setTabBgColor(index);
  };

  return (
    <>
      <Header />

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {category?.id === 4 ? (
            <>
              <ComboCategory />
            </>
          ) : (
            <>
              <div className="w-full h-[85vh] lg:h-[80vh] relative bann_img">
                <img
                  src={backendImageUrl + category?.banner_image}
                  alt=""
                  className="h-full object-cover w-full object-[85%] md:object-center"
                />
                <div className=" absolute bottom-6 left-14 sm:left-14 md:left-24 lg:left-32 text-2xl sm:text-2xl md:text-2xl lg:text-3xl font-medium">
                  {category ? category.banner_title : ""}
                </div>
              </div>

              <div className="pb-10 bg-[#e6e7eb]">
                <div className=" overflow-x-auto pb-[5px] sm:pb-[5px] md:pb-0">
                  <div className=" overflow-x-auto min-w-max sm:min-w-max md:min-w-max lg:min-w-full">
                    <div className=" container mx-auto px-10 flex-nowrap sm:flex-nowrap md:flex-nowrap lg:flex-wrap min-w-max lg:min-w-full flex gap-3  justify-center">
                      {subcategory
                        ? subcategory &&
                          subcategory.map(
                            (subcat, index) =>
                              subcat.products && (
                                <span
                                  key={subcat.id}
                                  className={`cursor-pointer inline-block py-3 px-4  text-white font-medium rounded-b-xl min-w-[200px] text-center ${
                                    index === tabBgColor
                                      ? "bg-[#ff7200]"
                                      : "bg-[#adbbaa]"
                                  }`}
                                  onClick={() =>
                                    handleTabLink(subcat.id, index)
                                  }
                                >
                                  {subcat.name}
                                </span>
                              )
                          )
                        : ""}
                    </div>
                  </div>
                </div>

                <div className=" container mx-auto pt-20 px-8 sm:px-16 md:px-20 lg:px-28">
                  <div
                    className={`grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-6 ${
                      subcategory && subcategory.length > 0 ? "grid" : "block"
                    }`}
                  >
                    {hidePrevComponent &&
                      products &&
                      subcategory &&
                      subcategory.length > 0 &&
                      subcategory &&
                      subcategory.map(
                        (subcat, index) =>
                          subcat.products &&
                          subcat.products.map(
                            (product) =>
                              index === 0 && (
                                <Component
                                  key={product.id}
                                  productObject={product}
                                  productId={product.id}
                                  pro_slug={product.slug}
                                  name={product.title}
                                  pro_name={product.short_desc}
                                  stars={5}
                                  img_link={product.product_image}
                                  variations={product.variation}
                                  isnewArrival={product.home_products}
                                  wishlistcheck={product?.wishlist}
                                />
                              )
                          )
                      )}
                    {subcategory && subcategory.length === 0 && (
                      <div className="pb-10 text-center font-semibold text-lg sm:text-lg md:text-lg lg:text-2xl">
                        Products are Not Available
                      </div>
                    )}
                    {!hidePrevComponent &&
                      products &&
                      subcategory &&
                      subcategory.length > 0 &&
                      products.map((product, index) => (
                        <Component
                          key={product.id}
                          productObject={product}
                          productId={product.id}
                          pro_slug={product.slug}
                          name={product.title}
                          pro_name={product.short_desc}
                          stars={5}
                          img_link={product.product_image}
                          variations={product.variation}
                          isnewArrival={product.home_products}
                          wishlistcheck={product?.wishlist}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </Fragment>
      )}
      <Footer />
    </>
  );
};

export default Products;
