import React from "react";
import { AiFillStar } from "react-icons/ai";
const Component = ({ image, name, place, stars, description }) => {
  return (
    <div>
      <img
        src={image}
        alt="celb_img"
        className="w-[67px] md:w-[85px] lg:w-[120px] h-[67px] md:h-[85px] lg:h-[120px] object-cover	 rounded-full mx-auto testimonial_inner_image"
      />
      <div className="pt-0 sm:pt-1 md:pt-1 lg:pt-2 xl:pt-3">
        <div className="font-semibold text-sm sm:text-base poppins_font">{name}</div>
        <div className="text-[12px] sm:text-[13px] md:text-[14px] font-semibold opacity-50">{place}</div>
        <div className="pt-1 sm:pt-2 md:pt-3 lg:pt-2 text-sm sn:text-sm md:text-base">
          {[...Array(stars)].map((_, starIndex) => (
            <span key={starIndex} className="text-[#fca4a8] inline-block">
              <AiFillStar />
            </span>
          ))}
        </div>
        <div className="mx-6 sm:mx-2 md:mx-10 lg:mx-16 xl:mx-12 2xl:mx-20 text-[11px] sm:text-sm mt-0  sm:mt-1 md:mt-2 lg:mt-2 test_desc">
          {description}
        </div>
      </div>
    </div>
  );
};

export default Component;
