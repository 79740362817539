import { createSlice } from "@reduxjs/toolkit";

const menusSlice = createSlice({
    name: "menus",
    initialState: {
        loading: false,
        menus:[],
        topheader:null,
    },
    reducers: {
        menusRequest(state, action) {
            return {
                loading: true,
            }
        },
        menusSuccess(state, action) {
            return {
                loading: false,
                menus: action.payload.menus,
                topheader:action.payload.topheader,
            }
        },
        menusFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = menusSlice;

export const { menusRequest, menusSuccess, menusFail } = actions;

export default reducer;