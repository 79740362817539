import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import img1 from "../assets/images/herbkey-aboutus image/welcome.jpg";
import img2 from "../assets/images/herbkey-aboutus image/why herb.jpg";
import img3 from "../assets/images/herbkey-aboutus image/we say no.jpg";
import img4 from "../assets/images/herbkey-aboutus image/we say no 1.jpg";
import img5 from "../assets/images/herbkey-aboutus image/packing.jpg";
import img6 from "../assets/images/herbkey-aboutus image/working.jpg";
import img7 from "../assets/images/herbkey-aboutus image/upcoming plan.jpg";
import img8 from "../assets/images/herbkey-aboutus image/volunteer.jpg";
import "../about.css";

const About = () => {

  return (
    <>
      <Header />
      <div>
        <section data-aos="fade-up" className="about-cont">
          <div className="about-container-odd">
            <div className="left-cont">
              <img src={img1} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">Welcome to THE HERB KEY! </div>
              <p className="p1">
                Here at ‘The Herb Key’, we strongly believe in our mantra which
                is ‘As pure as gold’. We strive hard to manufacture our products
                from its natural plant based state and keep away from chemicals.
                Hence all our products are tested to be skin friendly. We wish
                to draw a path that brings raw ingredients directly to your
                doorstep without any tampering by carefully handpicking and
                sourcing directly from our farmers. All our products are
                extracted from traditional recipes that have been preserved and
                passed down from generations.
              </p>
              <p className="p2">
                We wish to also give our valued customers the confidence to be
                the best versions of themselves by nurturing their natural
                beauty and we only deliver what we truly vouch for.
              </p>
            </div>
          </div>
        </section>

        <section data-aos="fade-up" className="about-cont">
          <div className="about-container-even">
            <div className="left-cont">
              <img src={img2} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">
                Why use natural herbal skincare products?
              </div>
              <p className="p1">
                Herbal skincare products are usually gentle on the skin because
                they are made of natural ingredients. The risk of causing
                breakouts and skin irritations is relatively less. They are the
                best treat to nurture your skin with. An added bonus is going
                natural and herbal is beneficial for the environment too.
              </p>
            </div>
          </div>
        </section>

        <section data-aos="fade-up" className="about-cont">
          <div className="head">We say NO to</div>
          <div className="about-container-odd">
            <div className="left-cont">
              <img src={img3} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">Ingredients :</div>
              <p className="p1">
                NO Parabens, Synthetic colouring, artificial fragrances,
                sulphates, harsh chemical and heavy metals. Our first priority
                is to find the right ingredients. We prefer to partner with
                singe source suppliers for each ingredient. This way we can
                support a larger number of local farmers and it also reduces the
                risk of cross contamination with potential allergens. The prices
                of the raw ingredients used to make our oil, serum, mask &
                soaps. Reflect the quality of those ingredients and the
                standards we maintain during our production process
              </p>
            </div>
          </div>
        </section>

        <section className="about-cont" data-aos="fade-up">
          <div className="head">We say NO to</div>
          <div className="about-container-even">
            <div className="left-cont">
              <img src={img4} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">No Artificial Agriculture :</div>
              <p className="p1">
                Agriculture is at the core of The Herb key operations. All our
                ingredients are grown in a natural environment and are ethically
                harvested. Our parts of our products are meticulously analysed
                in our Quality Control to ensure they are free from synthetic
                chemicals.
              </p>
            </div>
          </div>
        </section>

        <section className="about-cont" data-aos="fade-up">
          <div className="head">We say NO to</div>

          <div className="about-container-odd">
            <div className="left-cont">
              <img src={img5} className="img" />
            </div>

            <div className="right-cont">
              <div className="title">Packaging :</div>

              <p className="p1">
                All our packaging and shipping material is carefully picked to
                ensure we use the most environmentally responsible packaging. We
                use lightweight, recycled content materials. Our shipping boxes
                are made from 100% environment friendly corrugated cardboard.
                Instead of excessive plastic bubble wrap and plastic air
                pillows, we use 100 percent recycled-content shredded paper that
                is easily recyclable or biodegradable plant-based packing used
                to protect the glass for shipments.
              </p>
            </div>
          </div>
        </section>

        <section className="about-cont" data-aos="fade-up">
          <div className="head">OUR UPCOMING PLAN</div>
          <div className="about-container-even">
            <div className="left-cont">
              <img src={img6} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">Working towards a beautiful tomorrow:</div>
              <p className="p1">
                We endeavour to be environmentally sustainable, improving the
                quality of life of people around us while also giving back to
                the community we live in. We are working towards being plastic
                free brand and incorporate different ranges of glassware to
                serve the purpose. All ingredients are produced in a safe and
                biodegradable environment. Our company follows the reduction,
                reclamation and recycling path and only use what we need
                reducing wastage. Currently we are at 95% plastic free status
                and are striving to achieve 100 %.
              </p>
              <p className="p2">
                Our waste management process is unwaveringly committed to the
                environment and we are constantly rethink and redesigning our
                method of production to increase more resource recovery and
                recycling making us more in sync with nature.
              </p>
            </div>
          </div>
        </section>

        <section className="about-cont" data-aos="fade-up">
          <div className="head">OUR UPCOMING PLAN</div>
          <div className="about-container-odd">
            <div className="left-cont">
              <img src={img7} className="img" />
            </div>
            <div className="right-cont">
              <p className="p1">
                Supporting the farming community is our long-term goal as part
                of our Pay it forward initiative. Farming is at the core of our
                roots and is an underappreciated community. We plan to donate 5%
                of our annual sales to the farming community and intend to help
                the community via loans, kid’s education and support for medical
                bills.
              </p>
              <p className="p2">
                You can also sponsor farmers though financial contributions and
                in-kind donations and help via our volunteer programs. This
                includes programs to support home herbalists and small farmers.
              </p>
              <p className="p3">
                {" "}
                Our Fair-trade policy ensures that we give all farmers a fair
                market value for the goods they produce. We have discontinued
                items and refused to carry particular products because laborers
                were not given an equal voice, a fair wage, or because we felt
                sensitive bioregions were being compromised.
              </p>
            </div>
          </div>
        </section>

        <section data-aos="fade-up" className="about-cont">
          <div className="head">OUR UPCOMING PLAN</div>
          <div className="about-container-even">
            <div className="left-cont">
              <img src={img8} className="img" />
            </div>
            <div className="right-cont">
              <div className="title">Volunteer Programs :</div>
              <p className="p1">
                The Herb Key offers to everyone to join hands with our local
                farming communities as a volunteer at least 2 times in a year
                and get in touch with mother nature. You will be able to learn
                farming with farmers, environmental cleanliness, and planting
                trees in surrounding of your choosing for 48 hours along with
                food and accommodation. Our intention is to help you get a
                relaxing environment to de-stress as well as helping the
                community with your work and build a better understanding of the
                lives of farmers.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
     
    </>
  );
};

export default About;
