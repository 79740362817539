import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Steps = ({ linkname }) => {
  return (
    <div>
      <div className="flex gap-x-2 items-center text-[13px]">
        <span className="opacity-50 flex items-center">
          <span>Cart</span>{" "}
          <span className="opacity-50">
            <IoIosArrowForward size={16} />
          </span>
        </span>
        <Link to="/checkout" className={`flex items-center pointer-events-none ${linkname === 'info' ? 'opacity-100' : 'opacity-50'} ${linkname==="shipping" || linkname === "payment" ? " pointer-events-auto":"" }`}>
          <span>Information</span>{" "}
          <span className={`${linkname === 'info' ? 'opacity-100' : 'opacity-50'}`}>
            <IoIosArrowForward size={16} />
          </span>
        </Link>
        <Link to="/shipping" className={`flex items-center pointer-events-none  ${linkname === 'shipping' ? 'opacity-100' : 'opacity-50'} ${ linkname === "payment" ? " pointer-events-auto":"" }`}>
          <span>Shipping</span>{" "}
          <span className={`${linkname === 'shipping' ? 'opacity-100' : 'opacity-50'}`}>
            <IoIosArrowForward size={16} />
          </span>
        </Link>
        <span className={` ${linkname === 'payment' ? 'opacity-100' : 'opacity-50'}`}>Payment</span>
      </div>
    </div>
  );
};

export default Steps;
