import { createSlice } from "@reduxjs/toolkit";

const BtsSlice = createSlice({
    name: "BtsSlice",
    initialState: {
        loading: false,
        bts:[],
    },
    reducers: {
        btsRequest(state, action) {
            return {
                loading: true,
            }
        },
        btsSuccess(state, action) {
            return {
                loading: false,
                bts: action.payload.bts,
            }
        },
        btsFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = BtsSlice;

export const { btsRequest, btsSuccess, btsFail } = actions;

export default reducer;