import "./common.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Category from "./pages/Category";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import TermsandCondition from "./pages/TermsandCondition";
import SingleProduct from "./pages/SingleProduct";
import Checkout from "./pages/Checkout";
import CheckoutShipping from "./pages/CheckoutShipping";
import SubCategory from "./pages/SubCategory";
import FAQ from "./pages/FAQ";
import Return from "./pages/Return";
import ContactUs from "./pages/ContactUs";
import Payment from "./pages/Payment";
import PaymentSuccess from "./pages/PaymentSuccess";
import UserDashboard from "./pages/UserDashboard";
import ConcernCategory from "./pages/ConcernCategory";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/category/:slug" element={<Category />}></Route>
          <Route path="/products/:slug" element={<SingleProduct />}></Route>
          <Route path="/sub-category/:slug" element={<SubCategory />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/terms-and-conditions" element={<TermsandCondition />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/shipping" element={<CheckoutShipping />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/return" element={<Return />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/payment" element={<Payment />}></Route>
          <Route path="/payment-success" element={<PaymentSuccess />}></Route>
          <Route path="/user-dashboard" element={<UserDashboard />}></Route>

          <Route
            path="/concern-category/:slug"
            element={<ConcernCategory />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
