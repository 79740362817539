import React, { useState } from "react";
import logo from "../../assets/images/logo.jpg";

const ComboCategory = () => {
  const [tabClick, setTabClick] = useState("luxurygift")
  return (
    <div className=" flex flex-wrap lg:flex-nowrap bg-[#f3f4f6] min-h-[80vh] items-stretch gap-x-1 sm:gap-x-2 md:gap-x-3 lg:gap-x-4">
      <div className="w-full lg:w-[25%] gap-x-3 bg-[#d3e3fd] py-10 flex items-center flex-row lg:flex-col px-8">
        <div>
          <img
            src={logo}
            alt="logo"
            className="w-[auto] h-[50px] sm:h-[60px] md:h-[70px] lg:h-[80px] mix-blend-multiply	"
          />
        </div>
        <div className="flex flex-col  items-end lg:items-center w-auto lg:w-[80%] justify-center flex-1">
          <span className=" font-normal text-[30px] sm:text-[35px] md:text-[40px] lg:text-[45px] flex flex-col">
            <span className=" text-center leading-[1]">Combo</span>
            <span className=" text-right leading-[1]">Gift</span>
          </span>
          <div className=" pl-10 mt-3 leading-[1] w-[70%] lg:w-[80%]">
            <span className=" text-[12px]  combo_subtitle relative">
              you can choose your favorite what you wish to get/gift
            </span>
          </div>
        </div>
      </div>
      <div className="py-4 px-2 text-center w-full lg:w-[55%] order-3 lg:order-none flex flex-col text-3xl font-semibold items-center justify-center">
        Combo Products Under Construction
      </div>
      <div className="w-full lg:w-[20%] flex gap-y-2 flex-wrap flex-row lg:flex-col gap-x-3 pt-0 lg:py-10 font-semibold justify-center">
        <div onClick={()=>{setTabClick('luxurygift')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "luxurygift" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" } py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Luxury Gift
        </div>
        <div onClick={()=>{setTabClick('skincare')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "skincare" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" }  py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Skin Care
        </div>
        <div onClick={()=>{setTabClick('skinhair')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "skinhair" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" }  py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Skin & Hair Care
        </div>
        <div onClick={()=>{setTabClick('haircare')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "haircare" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" }  py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Hair Care
        </div>
        <div onClick={()=>{setTabClick('mencare')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "mencare" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" }  py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Mens Care Gift
        </div>
        <div onClick={()=>{setTabClick('babycare')}} className={`flex justify-center items-center text-[12px] ${tabClick=== "babycare" ? "text-white bg-[#ff7200] combo_tabs" : "bg-[#d3e7dc] text-[#8d8f8f]" }  py-3  lg:py-7  cursor-pointer min-w-[200px] lg:w-auto rounded-b-xl lg:rounded-br-none lg:rounded-s-[40px]`}>
          Baby Care Gift
        </div>
      </div>
    </div>
  );
};

export default ComboCategory;
