import axios from "axios";
import {
  subCategoryproductsFail,
  subCategoryproductsSuccess,
  subCategoryproductsRequest,
  getProductsbySubcategorySlugRequest,
  getProductsbySubcategorySlugSuccess,
  getProductsbySubcategorySlugFail,
} from "../slices/productsByCategorySlice";
import { backendUrl } from "../config/config";
import { subConcernCategoryproductsFail, subConcernCategoryproductsRequest, subConcernCategoryproductsSuccess } from "../slices/productsByConcernCategorySlice";
let token = localStorage.getItem("auth_Token");

export const getCategoryProducts = (id) => async (dispatch) => {
  try {
    dispatch(subCategoryproductsRequest());
    const { data } = await axios.get(
      backendUrl + `get_products_by_category_id/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(subCategoryproductsSuccess(data));
  } catch (error) {
    dispatch(subCategoryproductsFail(error.response.data.message));
  }
};
export const getConcernCategoryProducts = (id) => async (dispatch) => {
  try {
    dispatch(subConcernCategoryproductsRequest());
    const { data } = await axios.get(
      backendUrl + `get_products_by_concern_category_id/${id}`
    );
    dispatch(subConcernCategoryproductsSuccess(data));
  } catch (error) {
    dispatch(subConcernCategoryproductsFail(error.response.data.message));
  }
};

export const getProductsBySubcategorySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getProductsbySubcategorySlugRequest());
    const { data } = await axios.get(
      backendUrl + `get_products_by_subcategory_slug/${slug}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(getProductsbySubcategorySlugSuccess(data));
  } catch (error) {
    dispatch(getProductsbySubcategorySlugFail(error.response.data.message));
  }
};
