import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";

const UserReviewDetails = ({ productReview }) => {


    const [daysAgo, setDaysAgo] = useState(null);

//   useEffect(() => {
//     const currentDate = new Date();
//     const reviewDate = new Date(productReview?.created_at);

//     // Calculate the difference in milliseconds
//     const timeDifference = currentDate - reviewDate;

//     // Convert milliseconds to days
//     const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

//     setDaysAgo(daysDifference);
//   }, [productReview?.created_at]);

const [dateString, setDateString] = useState(productReview?.created_at);

useEffect(() => {
  const calculateDaysDifference = () => {
    const currentDate = new Date(); // Current date
    const givenDate = new Date(dateString); // Date from the variable

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = currentDate - givenDate;

    // Convert milliseconds to days
    const differenceInDays = Math.abs(Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)));
    setDaysAgo(differenceInDays);
    return differenceInDays;
  };

  calculateDaysDifference();
}, [dateString]);
  return (
    <div className="mt-8">
      <div className="flex gap-x-4 items-center">
        <div className="flex text-[#f7c066]">
          {[...Array(productReview?.rating)].map((_, starIndex) => (
            <AiFillStar key={starIndex} />
          ))}
        </div>
        <div className="text-sm py-1">{daysAgo} Day{`${daysAgo>1 ? "s" :""}`} ago</div>
      </div>
      <div className="mt-4 flex items-center gap-x-4">
        <div>
          <img
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D&w=1000&q=80"
            alt="celb_img"
            className="w-[60px] h-[auto] rounded-full mx-auto"
          />
        </div>
        <div className=" font-medium">
          <div className="text-sm uppercase poppins_font">{productReview?.name}</div>
          <div className="text-sm">{productReview?.place}</div>
        </div>
      </div>
      <div className="mt-4">
        {/* <span className='font-medium'>Best Skin Foundation</span> */}
        <div className="mt-2 text-[13px] sm:text-[13px] md:text-[13px] lg:text-base">{productReview?.review}</div>
      </div>
    </div>
  );
};

export default UserReviewDetails;
