import { createSlice } from "@reduxjs/toolkit";

const concernCategorySlice = createSlice({
    name: "concerncategories",
    initialState: {
        loading: false,
        category: [],
    },
    reducers: {
        concernCategoryRequest(state, action) {
            return {
                loading: true,
            }
        },
        concernCategorySuccess(state, action) {
            return {
                loading: false,
                category: action.payload.category,
                subcategory: action.payload.subcategory,
            }
        },
        concernCategoryFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },

});

const { actions, reducer } = concernCategorySlice;

export const { concernCategoryRequest, concernCategorySuccess, concernCategoryFail } = actions;

export default reducer;
