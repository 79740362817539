import axios from "axios";
import { categoryRequest, categorySuccess, categoryFail } from "../slices/categorySlice";
import {backendUrl} from "../config/config";
import { concernCategoryFail, concernCategoryRequest, concernCategorySuccess } from "../slices/concernCategorySlice";


let token = localStorage.getItem("auth_Token");

export const getCategory = slug => async (dispatch) => {
    try {
        dispatch(categoryRequest())
        const { data } = await axios.get(backendUrl + `get_category/${slug}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        dispatch(categorySuccess(data))
    } catch (error) {
        dispatch(categoryFail(error.response.data.message));
    }
}

export const getConcernCategory = slug => async (dispatch) => {
    try {
        dispatch(concernCategoryRequest())
        const { data } = await axios.get(backendUrl + `get_concern_category/${slug}`)
        dispatch(concernCategorySuccess(data))
    } catch (error) {
        dispatch(concernCategoryFail(error.response.data.message));
    }
}