import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import menusReducer from "./slices/menusSlice";
import categoryReduceer from "./slices/categorySlice";
import productsByCategory from "./slices/productsByCategorySlice";
import variationTypeChange from "./slices/variationTypeChange";
import productBySlug from "./slices/ProductBySlugSlice";
import cartReducer from "./slices/cartSlice";
import getCartReducer from "./slices/GetCart";
import conernCategory from "./slices/concernCategorySlice";
import accountDetails from "./slices/accountDetails";
import productsByConcernCategory from "./slices/productsByConcernCategorySlice";
import myOrdersDetails from "./slices/myOrdersDetails";
import AddressSlice from "./slices/AddressSlice";
import bannerSlice from "./slices/bannerSlice";
import CouponCodeSlice from "./slices/CouponCode";
import BtsSlice from "./slices/BtsSlice";
import specialProduct from "./slices/SpecialProduct";
import testimonials from "./slices/Testimonials";
import Wishlist from "./slices/Wishlist";
import SubscribeModal from "./slices/subscribeModalSlice";

const reducer = combineReducers({
  menusState: menusReducer,
  categoryState: categoryReduceer,
  concernCategoryState: conernCategory,
  subcategoryProductsState: productsByCategory,
  subConcerncategoryProductsState: productsByConcernCategory,
  variationTypeChangeState: variationTypeChange,
  productBySlugState: productBySlug,
  cartstate: cartReducer,
  getCartState: getCartReducer,
  accountDetailsState: accountDetails,
  myOrdersDetailsState: myOrdersDetails,
  AddressState: AddressSlice,
  bannerState: bannerSlice,
  CouponCodeState: CouponCodeSlice,
  btsState: BtsSlice,
  specialProductState:specialProduct,
  testimonialsState:testimonials,
  WishlistState:Wishlist,
  SubscribeModalState:SubscribeModal,
});
const store = configureStore({
  devTools:false,
  reducer,
  middleware: [thunk],
});

export default store;
