import { createSlice } from "@reduxjs/toolkit";

const variationTypeChangeSlice = createSlice({
    name: "variationtypechange",
    initialState: {
        loading: false,
        variations: [],
    },
    reducers: {
        variationTypeChangeRequest(state, action) {
            return {
                loading: true,
            }
        },
        variationTypeChangeSuccess(state, action) {
            return {
                loading: false,
                variation: action.payload.variation,
            }
        },
        variationTypeChangeFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },

});

const { actions, reducer } = variationTypeChangeSlice;

export const { variationTypeChangeRequest, variationTypeChangeSuccess, variationTypeChangeFail } = actions;

export default reducer;
