import React, { useEffect, useRef, useState } from 'react'
import TopHeader from '../components/TopHeader';
import Header from '../components/Header';
import Footer from '../components/Footer/Footer';
import '../faq.css';
const FAQ = () => {
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    const accountCreation = [
        {
            id: 1,
            header: "How do I create an account in The Herb Key?",
            text: `Click on the top right "Log-in" icon and register your account if you are a new user. You can also use your Google or Facebook account for clicking on the respective icons`
        },
        {
            id: 2,
            header: "What are the benefits of creating an account?",
            text: `We send exciting new offers to our members and you will receive special updates on our new launches. You can also track your order history and status of your orders via your account.`
        },
        {
            id: 3,
            header: "Do I need to have an account to place an order?",
            text: `No, you can place an order using guest checkout without having an account. However, we recommend to make an account on our website to make your shopping experience simple and safer and also receive special benefits`
        },
        {
            id: 4,
            header: "I forgot my password, what can I do?",
            text: `If you have forgotten your password, you can request for a reset process to be initated via the email address you have previously registered with us by clicking on the Forgot Password link. You will be taken to a page where you will be asked to enter your registered email address and a temporary password will be sent to you. We strongly recommend that you change this at your earliest convenience.`
        },
        {
            id: 5,
            header: "I cannot complete my registration, what do I need to do?",
            text: `Contact us, detailing the problem you have encountered. You can either email us on herbkey.com or call us on 9500088250. Our Customer Care will be happy to assist you.`
        }
    ]
    const aboutPayment = [
        {
            id: 6,
            header: "What payment types do you offer?",
            text: `Domestic: UPI Scan, Visa Debit cards, Credit cards issued by banks and institutions that are part of the Visa and MasterCard Network, Net banking from select banks in India. A full list is available at the time of Check Out and prior to making payments for purchases`
        },
        {
            id: 7,
            header: "What is Cash on Delivery (COD)?",
            text: `Unfortunately, we don't have COD option currently. We are looking to implement COD mode of payment in the future.`
        },
        {
            id: 8,
            header: "How safe is it to use my Debit/Credit card or make an online payment on The Herb Key",
            text: `All transactions at The Herb Key are protected by SSL (Secure Sockets Layer). Any information you enter when transacting with The Herb Key Online is sent in a Secure Socket Layer (SSL) session and is encrypted to protect you against unintentional disclosure to third parties. This is an assurance that we follow the best security practices adopted by all major online vendor, where the payments are processed in real-time, for your security and immediate peace of mind.You can tell if your browser is in secure mode at “Checkout”, by looking for the padlock icon at the bottom corner or at the end of the address bar of your browser window.`
        },
    ]

    const aboutOrder = [
        {
            id: 9,
            header: "How do I know that my order is confirmed?",
            text: `You will get a message on your registered contact number or email .`
        },
        {
            id: 10,
            header: 'Can I order a product that is "Out of Stock"?',
            text: `Unfortunately, products listed as "Out of Stock" are not available for order. You can click on 'Notify Me' and we will send you a notification email as soon as the product is back in stock. Remember, this can only be done if you have a registered account on our site.`
        },
        {
            id: 11,
            header: "Why was my order payment rejected?",
            text: `There are various reasons why this may have happened ranging from validity of card/net banking details, insufficient funds in the account to technical difficulties. If you were recently issued a new card, some of the information may have changed. In that case, please confirm your card details and try again. Also, check that your name and address match the name and address on your current credit card.`
        },
        {
            id: 12,
            header: "How do I make changes to my order?",
            text: `Unfortunately, you cannot modify the order once it has been placed. We suggest you cancel the order and place a new order with your desired changes.`
        },
        {
            id: 13,
            header: "How do I cancel my order?",
            text: `We at The Herb Key can understand that you can have a change of heart even after placing an order. If you wish to cancel your order, you can do so by sending us an email on sale@theherbkey.com. You can also call our Customer Care on 9500088250 (Timings: 10am-5 pm IST), within 2 hours of placing the order. Because we used to dispatch the order as soon as possible once we received the order, by 11am and another by 2pm.`
        },
        {
            id: 14,
            header: "How can I use a Gift Voucher/Promotional code on my order?",
            text: `When you click on the 'Checkout' button, you will be directed to a section where you can enter your Gift Voucher/Promotional code. On entering the code, the valid amount/offer will automatically be applied to your order. You can then proceed to checkout and pay the remaining amount using your chosen form of payment.`
        },
        {
            id: 15,
            header: "What if I receive an incomplete, incorrect or damaged order?",
            text: `For any complaints you may have, with regards to your order, please contact our Customer Care. In an extremely rare occasion where you receive a product with tampered/open packaging, please inform our Customer Care within 8hrs from the order delivery, with supporting images, so we can best address your concern.`
        }
    ]

    const aboutShippingDelivery = [
        {
            id: 16,
            header: "Can I ship the products to an address that is different from my billing address?",
            text: `Yes, you can use the 'Shipping address' to deliver an address different from your billing address.`
        },
        {
            id: 17,
            header: "What is the range of locations to which we ship our products?",
            text: `Presently we ship across India (domestic orders), except for a few deep remote locations. We will be introducing international shipping soon.`
        },
        {
            id: 18,
            header: "How does the delivery process work & track my order?",
            text: `All our deliveries are serviced through well-reputed couriers. As soon as your order is dispatched, you will receive an email/SMS with your package's tracking details as well as the name of the courier company that is processing your order. You can track your order by using the tracking details which you have received.`
        },
        {
            id: 19,
            header: "By when can I expect delivery of my order?",
            text: `All domestic orders will be delivered within 1 to 5 business days and remote locations which take between 5 to 7 business days. There may be possible delays due to unforeseen circumstances. We will inform the customer upfront on such delays. We appreciate your understanding and are committed to delivering your order as soon as possible.`
        },
    ]
    const aboutStore = [
        {
            id: 20,
            header: "Do The Herb Key have any physical stores?",
            text: `We are currently an online e-commerce store and do not have any physical stores in any cities.`
        },
    ]
    return (
        <>
            
            <Header />
            {/* <div className='py-4 px-8 sm:px-16 md:px-20 lg:px-28 container mx-auto'>
               
            </div> */}
            <div className="container mx-auto mt-5 mb-5 px-2">
                <div className="flex justify-center">
                    <div className="w-full mt-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="form-heading mb-4 theme_green text-center mt-3 text-lg sm:text-xl">About Account Creation</h4>
                                {accountCreation.map((faq, index) => {
                                    return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto mt-5 mb-5 px-2">
                <div className="flex justify-center">
                    <div className="w-full mt-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="form-heading mb-4 theme_green text-center mt-3 text-lg sm:text-xl">About Payment</h4>
                                {aboutPayment.map((faq, index) => {
                                    return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-5 mb-5 px-2">
                <div className="flex justify-center">
                    <div className="w-full mt-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="form-heading mb-4 theme_green text-center mt-3 text-lg sm:text-xl">About Order</h4>
                                {aboutOrder.map((faq, index) => {
                                    return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-5 mb-5 px-2">
                <div className="flex justify-center">
                    <div className="w-full mt-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="form-heading mb-4 theme_green text-center mt-3 text-lg sm:text-xl">About Shipping/Delivery </h4>
                                {aboutShippingDelivery.map((faq, index) => {
                                    return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto mt-5 mb-5 px-2">
                <div className="flex justify-center">
                    <div className="w-full mt-2">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="form-heading mb-4 theme_green text-center mt-3 text-lg sm:text-xl">About Store</h4>
                                {aboutStore.map((faq, index) => {
                                    return (
                                        <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{header}</h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{text}</p>
                </div>
            </div>
        </div>
    )
}
export default FAQ;
