import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Component from "./Component";
import img_pc from "../../assets/images/home/newpc.png";
import img_tab from "../../assets/images/home/Ipad_02.png";
import img_mob from "../../assets/images/home/newmobtestimonial.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials } from "../../actions/userDashboardActions";
import Loader from "../Loader";
import { backendImageUrl } from "../../config/config";

const CelebrityNotes = () => {
  const dispatch = useDispatch();

  const { testimonials, loading } = useSelector(
    (state) => state.testimonialsState
  );
  useEffect(() => {
    dispatch(getTestimonials);
  }, []);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2300,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="bg-[#e4f3f8]">
      <div className=" relative  mb-4 sm:mb-20 md:mb-10 mx-auto max-w-[1400px]">
        <img src={img_pc} alt="banner_img" className="hidden xl:block" />
        <img
          src={img_tab}
          alt="banner_img"
          className="hidden md:block xl:hidden"
        />
        <img src={img_mob} alt="banner_img" className="block md:hidden" />
        <div className="container mx-auto h-full  pt-0 sm:pt-0 md:pt-1 py-1 sm:py-1 md:py-2 lg:py-3 xl:py-4 px-0 sm:px-16 md:px-18 lg:px-28 text-center  celeb_slide_par absolute  z-20 w-[75%]  top-[11%] sm:top-[15%] md:top-[10%] xl:top-[20%] left-1/2 -translate-x-1/2 ">
          {/* <div className="container mx-auto px-0 sm:px-16 md:px-18 lg:px-28 text-center  celeb_slide_par absolute  z-20 w-[75%] top-0 left-1/2 -translate-x-1/2 h-full"> */}
          <Slider {...carouselSettings}>
            {testimonials &&
              testimonials.map((singleData) => (
                <Component
                  key={singleData?.id}
                  image={backendImageUrl + "" + singleData?.image}
                  name={singleData?.name}
                  place={singleData?.place}
                  stars={singleData?.stars}
                  description={singleData?.description}
                />
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CelebrityNotes;
