import React from 'react'

const TopHeader = ({topheader}) => {
    return (
        <div className='text-center pt-2 sm:pt-3 md:pt-5 pb-2 theme_bg_green text-white uppercase text-[13px] tracking-wide font-bold px-2 sm:px-4 md:px-5 lg:px-10'>
            {topheader.heading}
        </div>
    )
}

export default TopHeader