import React, { useEffect, useState } from "react";
import ViewOrdersComponents from "./ViewOrdersComponents";
import { toast } from "react-toastify";
import { backendUrl } from "../../config/config";
import axios from "axios";
import { MdArrowDropDown } from "react-icons/md";

const ViewOrders = ({
  setOptionChange,
  singleData,
  orderNumber,
  status,
  date,
  OrderData,
}) => {
  const [cartDate, setCartDate] = useState("");
  const [changeDate, setChangeDate] = useState(date);
  // const [showAddress, setShowAddress] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [userAddress, setUserAddress] = useState(
    JSON.parse(OrderData?.user_data)
  );
  const [shippingAddress, setShippingAddress] = useState(
    OrderData?.shipping_address != null
      ? JSON.parse(JSON.parse(OrderData?.shipping_address))
      : null
  );
  const getProducts = async () => {
    const IDs = singleData.map((item) => ({
      productId: item.product.id,
      variationId: item.variation.id,
      quantity: item?.quantity ? item?.quantity : 0,
    }));
    let sendID = { IDs };
    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.post(
        backendUrl + "get-checkout-productsbyId",
        sendID,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFinalData(response.data.checkoutProducts);
    } catch (error) {
      toast.error("Something went wrong , Please try again !", {
        position: "top-right",
        autoClose: 3000, // Auto-close the toast after 3 seconds
      });
    }
  };

  useEffect(() => {
    const originalDateString = changeDate;
    const date = new Date(originalDateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    setCartDate(formattedDate);
    getProducts();
  }, []);
  return (
    <>
      <div className="px-2 py-1 flex items-center gap-x-2">
        <span className="w-[80%] text-[13px]">
          Order #{orderNumber} was placed on{" "}
          <span className=" capitalize">{cartDate}</span> and is currently{" "}
          <span className=" capitalize">{status}</span>
        </span>
        <span
          className="w-[20%] text-center cursor-pointer"
          onClick={() => setOptionChange("my_order")}
        >
          Back
        </span>
      </div>
      <div className="flex items-center flex-col gap-y-2 sm:gap-y-2 md:gap-y-4 mt-2 sm:mt-2 md:mt-3 min-w-[650px]">
        {finalData &&
          finalData.map((finalData, index) => (
            <ViewOrdersComponents key={index} singleData={finalData} />
          ))}
      </div>
      <div className="flex items-center w-full items-center gap-x-2 px-0 sm:px-3 md:px-8 lg:px-14 min-w-[650px]">
        <div className=" w-[50%] mt-3 flex flex-col sm:w-[50%] md:w-1/2 px-2 gap-y-2 text-sm  md:mx-auto">
          <div className="flex justify-between gap-2">
            <span>Subtotal</span>
            <span className=" text-nowrap">₹ {(OrderData?.total_amout).toFixed(2)}</span>
          </div>
          <div className="flex justify-between gap-2">
            <span>Saved</span>
            <span className=" text-nowrap">₹ {(OrderData?.saved_amount).toFixed(2)}</span>
          </div>
          <div className="flex justify-between gap-2">
            <span>Shipping</span>
            <span className=" text-nowrap">₹ {(OrderData?.shipping_amount).toFixed(2)}</span>
          </div>
          {OrderData?.coupon_value != null && (
            <div className="flex justify-between gap-2">
              <span>Coupon Discount</span>
              <span className=" text-nowrap">₹ {(OrderData?.coupon_value).toFixed(2)}</span>
            </div>
          )}
          <div className="flex justify-between gap-2">
            <span className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
              <span>Total :</span>
              <span className="text-[12px]">including Taxes</span>
            </span>
            <span className=" text-nowrap">
              ₹{" "}
              {(
                OrderData?.shipping_amount +
                OrderData?.total_amout -
                OrderData?.coupon_value
              ).toFixed(2)}
            </span>
          </div>
          {/* <div
            className="flex items-center w-[max-content] cursor-pointer"
            onClick={() => setShowAddress(!showAddress)}
          >
            <span>Billing Address & Shipping Address</span>
            <span>
              <MdArrowDropDown
                className={`${!showAddress ? "-rotate-[90deg]" : ""}`}
                size={22}
              />
            </span>
          </div> */}
        </div>
        <div className="w-[20%] text-center"></div>
      </div>
      {/* {showAddress && ( */}
        <>
          {userAddress && (
            <div className="mt-3 md:mt-6 px-0 sm:px-3 md:px-8 lg:px-14 text-sm w-[50%] sm:w-[50%] md:w-full">
                <h3 className=" text-center text-[16px] font-semibold">
                  Billing Address
                </h3>
              <div className="flex items-center w-full  gap-y-4  mt-3 flex-wrap p-2 shadow-lg rounded  border">
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2 capitalize">
                  <span>firstName</span>
                  <span>{userAddress?.firstName}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2 capitalize">
                  <span>LastName</span>
                  <span>{userAddress?.lastName}</span>
                </div>
                {userAddress?.apartment && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>Apartment</span>
                    <span>{userAddress?.apartment}</span>
                  </div>
                )}
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Address</span>
                  <span>{userAddress?.address}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Pincode</span>
                  <span>{userAddress?.pincode}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>City</span>
                  <span>{userAddress?.city}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>State</span>
                  <span>{userAddress?.state}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Country</span>
                  <span>{userAddress?.country}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Phone Number</span>
                  <span>{userAddress?.phoneNumber}</span>
                </div>
                {userAddress?.companyName && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>Company Name</span>
                    <span>{userAddress?.companyName}</span>
                  </div>
                )}
                {userAddress?.gstnumber && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>GST</span>
                    <span>{userAddress?.gstnumber}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {shippingAddress && (
            <div className="mt-3 md:mt-6 px-0 sm:px-3 md:px-8 lg:px-14 text-sm w-[50%] sm:w-[50%] md:w-full">
              <h3 className=" text-center text-[16px] font-semibold">
                Shipping Address
              </h3>
              <div className="flex items-center w-full items-center gap-y-4  mt-3 flex-wrap p-2 shadow-lg rounded  border">
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2 capitalize">
                  <span>firstName</span>
                  <span>{shippingAddress?.shippingFirstName}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2 capitalize">
                  <span>LastName</span>
                  <span>{shippingAddress?.shippingLastName}</span>
                </div>
                {shippingAddress?.shippingApartment && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>Apartment</span>
                    <span>{shippingAddress?.shippingApartment}</span>
                  </div>
                )}
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Address</span>
                  <span>{shippingAddress?.shippingAddress}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Pincode</span>
                  <span>{shippingAddress?.shippingPincode}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>City</span>
                  <span>{shippingAddress?.shippingCity}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>State</span>
                  <span>{shippingAddress?.shippingState}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Country</span>
                  <span>{shippingAddress?.shippingCountry}</span>
                </div>
                <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                  <span>Phone Number</span>
                  <span>{shippingAddress?.shippingPhoneNumber}</span>
                </div>
                {shippingAddress?.shippingCompanyName && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>Company Name</span>
                    <span>{shippingAddress?.shippingCompanyName}</span>
                  </div>
                )}
                {shippingAddress?.shippinggstnumber && (
                  <div className="flex flex-col w-full sm:w-1/2 md:w-1/2 px-2">
                    <span>GST</span>
                    <span>{shippingAddress?.shippinggstnumber}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      {/* )} */}
    </>
  );
};

export default ViewOrders;
