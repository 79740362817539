import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVariationType } from "../../actions/variationTypeChangeActions";
import { backendImageUrl, backendUrl } from "../../config/config";
import { addSingleCart } from "../../actions/CartActions";
import { AiOutlineShoppingCart } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import cartImg from "../../assets/images/svg/cart1.svg";
const VariationComponent = ({
  productObject,
  variations,
  productId,
  name,
  getvariation,
  newVariation,
  img_link,
  setGetVariationId,
  getVariationId,
}) => {
  const [tabBgColor, setTabBgColor] = useState(0);
  const [stock, setStock] = useState(
    variations?.[0]?.["stock"] ? variations?.[0]?.["stock"] : 0
  );
  const [hidePrevComponent, setHidePrevComponent] = useState(true);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const dispatch = useDispatch();
  const { variation: newVariations, loading: variationLoading } = useSelector(
    (state) => state.variationTypeChangeState
  );
  const [loading, setLoading] = useState(false);

  const handleTabLink = (id, index, stock) => {
    setStock(stock);
    setGetVariationId(id);
    dispatch(getVariationType(id));
    setSelectedVariation(id);
    setTabBgColor(index);
    setHidePrevComponent(false);
  };

  useEffect(() => {
    if (variations && getvariation) {
      setGetVariationId(variations[0]["id"]);
      dispatch(getVariationType(variations[0]["id"]));
      setSelectedVariation(variations[0]["id"]);
      setHidePrevComponent(false);
    }
  }, []);
  const AddCart = async () => {
    if (stock > 0) {
      const checkcart = JSON.parse(localStorage.getItem("cart"));
      let targetObject = null;
      if (checkcart?.length) {
        targetObject = checkcart.find(
          (item) => item.variationId === getVariationId
        );
      }

      if (loading) {
        // If an increment operation is already in progress, return early
        return;
      }

      setLoading(true);
      try {
        const response = await checkVariant();
        let msg = "Stock Limit Reached";
        if (stock === 0) {
          msg = "This product is out of Stock ";
        }
        if (
          response?.data?.variation &&
          response?.data?.variation?.stock > 0 &&
          response?.data?.variation?.stock >
            (targetObject?.quantity ? targetObject?.quantity : 1)
        ) {
          addingCart();
        } else {
          toast.warning(msg);
        }
      } catch (error) {
        console.error("Error in increment:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  async function checkVariant() {
    const response = await axios.get(
      backendUrl + `get_variationtypebyproductVariationId/${getVariationId}`
    );
    return response;
  }
  const addingCart = () => {
    if (selectedVariation && selectedVariation === newVariation?.id) {
      const sendData = {
        id: productId,
        quantity: 1,
        title: name,
        image_link: backendImageUrl + img_link,
        variationId: newVariation.id,
        variation: newVariation,
      };
      dispatch(addSingleCart({ sendData }));
    } else {
      const sendData = {
        id: productId,
        quantity: 1,
        title: name,
        image_link: backendImageUrl + img_link,
        variationId: variations[tabBgColor]["id"],
        variation: variations[tabBgColor],
      };
      dispatch(addSingleCart({ sendData }));
    }
  };

  return (
    <div className="pt-2">
      <div className="text-[12px] font-bold">
        <span className="text-[12px]">Size</span>
        {variations &&
          variations.map((variation, index) => (
            <Fragment key={variation.id}>
              <span
                onClick={() =>
                  handleTabLink(variation.id, index, variation?.stock)
                }
                key={variation.id}
                className={`${
                  index === tabBgColor
                    ? "bg-[#5b6b5e] text-white"
                    : " bg-[#cad6ae]"
                }  px-1 rounded-sm py-[6px] inline-block ml-1 leading-none min-w-[40px] text-center cursor-pointer`}
              >
                {variation.variation_name} {variation.variation_type_name}
              </span>
            </Fragment>
          ))}
      </div>
      <div className="text-[15px] font-bold mt-1">
        {hidePrevComponent ? (
          variations &&
          variations.map((variation, index) => (
            <Fragment key={variation.id}>
              {selectedVariation === variation.id && (
                <>
                  <span>₹ {variation.sale_price}</span>
                  {variation.mrp_price && (
                    <span className="ml-3 opacity-60">
                      <s>₹ {variation.mrp_price}</s>
                    </span>
                  )}
                </>
              )}
            </Fragment>
          ))
        ) : (
          <>
            {newVariations &&
              selectedVariation === newVariations.id &&
              !hidePrevComponent && (
                <>
                  <span>₹ {newVariations.sale_price}</span>
                  {newVariations.mrp_price && (
                    <span className="ml-3 opacity-60">
                      <s>₹ {newVariations.mrp_price}</s>
                    </span>
                  )}
                </>
              )}
          </>
        )}
        {newVariations &&
          newVariation.id !== selectedVariation &&
          variations &&
          variations.map((variation, index) => (
            <Fragment key={variation.id}>
              {selectedVariation === variation.id && (
                <>
                  <span>₹ {variation.sale_price}</span>
                  {variation.mrp_price && (
                    <span className="ml-3 opacity-60">
                      <s>₹ {variation.mrp_price}</s>
                    </span>
                  )}
                </>
              )}
            </Fragment>
          ))}
      </div>
      {productObject && productObject?.upcoming === 0 && (
        <div
          className={`absolute bottom-0 left-1/2 -translate-x-1/2  rounded-t-md  ${
            stock > 0
              ? "bg-[#5b6b5e] cursor-pointer"
              : "bg-[#9bb2f8] min-w-[50%] px-2 text-white text-center py-1.5"
          } ${
            variationLoading
              ? " opacity-50 pointer-events-none cursor-none"
              : ""
          }`}
          onClick={AddCart}
        >
          {stock > 0 ? (
            <div className="text-white px-6 pt-[5px]">
              {/* <AiOutlineShoppingCart size={30} /> */}
              <img src={cartImg} alt="cart" className="w-[34px] h-[34px]" />
            </div>
          ) : (
            "Out of Stock"
          )}
        </div>
      )}
      {productObject && productObject?.upcoming === 1 && (
        <div
          className={`absolute bottom-0 left-1/2 -translate-x-1/2  rounded-t-md bg-[#9bb2f8] min-w-[50%] text-white text-center py-1.5`}
        >
         Upcoming 
        </div>
      )}
    </div>
  );
};

export default VariationComponent;
