import React, { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { MdOutlineModeEdit } from "react-icons/md";
import CustomerReviewPopup from "./CustomerReviewPopup";
import UserReviewDetails from "./UserReviewDetails";
import CustomerReviewSuccessPopup from "../CustomerReviewSuccessPopup";
import tick from "../../assets/images/svg/check.svg";
const CustomerReviews = ({ id, productReviews, ReviewAverage }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
    document.body.classList.add("scroll_review");
  };
  const closeModal = () => {
    document.body.classList.remove("scroll_review");
    setModalIsOpen(false);
  };
  const closeSuccessModal = () => {
    document.body.classList.remove("out_popup");
    setSuccessModalIsOpen(false);
  };
  const numericRating = ReviewAverage === null ? 0 : parseFloat(ReviewAverage);

  return (
    <div className="mt-10 px-10 sm:px-20 md:px-28 mb-6 lg:mb-2">
      <div className=" font-semibold text-2xl text-center century_gothic">
        Customer Reviews
      </div>
      <div className="mt-8 md:mt-10 flex flex-wrap sm:flex-nowrap gap-y-4 gap-x-4 justify-between items-center">
        <div className="flex gap-x-6 items-center">
          <div className="font-semibold text-4xl">
            {numericRating.toFixed(2)}
          </div>
          <div>
            <div className="flex text-[#f7c066]">
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </div>
            <div className="text-sm font-medium py-1">
              {productReviews && productReviews.length} Review
              {`${productReviews && productReviews.length > 1 ? "s" : ""}`}
            </div>
          </div>
        </div>
        <div>
          <div className=" inline-block relative mt-3" onClick={openModal}>
            <input
              type="text"
              className="bg-[#edf1f0] rounded-md pr-2 pl-10 focus-visible:outline-0 py-2 w-full md:w-4/5 cursor-pointer"
              readOnly
              placeholder="Write A Review"
            />
            <span className="text-[14px] md:text-[16px] lg:text-[18px] absolute left-3 top-1/2 -translate-y-1/2 ">
              <MdOutlineModeEdit className="opacity-50" />
            </span>
          </div>
        </div>
      </div>

      {productReviews && productReviews.length ? (
        <>
          <div className=" inline-block relative mt-3">
            <input
              type="text"
              className="bg-[#edf1f0] rounded-md pr-2 pl-10 focus-visible:outline-0 py-2 w-full md:w-4/5"
              placeholder="Search"
            />
            <span className="text-[14px] md:text-[16px] lg:text-[18px] absolute left-3 top-1/2 -translate-y-1/2">
              <BsSearch />
            </span>
          </div>
          {productReviews.map((productReview, index) => (
            <UserReviewDetails productReview={productReview} key={index} />
          ))}
        </>
      ) : (
        <h3 className="pt-3 font-semibold text-2xl text-center">
          Reviews Not Added Yet
        </h3>
      )}

      <CustomerReviewPopup
        id={id}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        OpenSuccess={setSuccessModalIsOpen}
      />
      {successModalIsOpen && (
        <CustomerReviewSuccessPopup
          tickImg={tick}
          isOpen={successModalIsOpen}
          onRequestClose={closeSuccessModal}
        />
      )}
    </div>
  );
};

export default CustomerReviews;
