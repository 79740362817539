import React from 'react'
import TopFooter from './TopFooter'
import BottomFooter from './BottomFooter'

const Footer = () => {
  return (
    <div className='container mx-auto py-1 sm:py-2 md:py-4  text-center mt-10 sm:mt-8'>
        <TopFooter />
        <BottomFooter />
    </div>
  )
}

export default Footer