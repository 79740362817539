import { createSlice } from "@reduxjs/toolkit";

const productBySlugSlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    products: [],
    suggestProducts:[],
    category:{},
    productReviews:[],
    ReviewAverage:0,
    productDesign:[],
  },
  reducers: {
    productBySlugRequest(state, action) {
      return {
        loading: true,
      };
    },
    productBySlugSuccess(state, action) {
      return {
        loading: false,
        product: action.payload.product,
        product_images: action.payload.product_images,
        category: action.payload.category,
        suggestProducts:action.payload.suggestProducts,
        productReviews:action.payload.productReviews,
        ReviewAverage:action.payload.ReviewAverage,
        productDesign:action.payload.productDesign,
      };
    },
    productBySlugFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = productBySlugSlice;

export const { productBySlugRequest, productBySlugSuccess, productBySlugFail } =
  actions;

export default reducer;
