import React, { useEffect, useState } from 'react'
import { HiMinus, HiPlus } from "react-icons/hi";
import { backendImageUrl, backendUrl } from '../../config/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { addSingleCart } from '../../actions/CartActions';

const ViewOrdersComponents = ({singleData}) => {
    const [count, setCount] = useState(singleData?.quantity);
    const [stock, setStock] = useState(singleData?.productvariation?.stock);
    const [loading, setLoading] = useState(false);
    const increment = async () => {
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    let targetObject = getSingleDatailCart(checkcart , singleData?.productvariation?.id);
    if (loading) {
      // If an increment operation is already in progress, return early
      return;
    }

    setLoading(true);

    try {
      const response = await checkVariant();
      let msg = "Stock Limit Reached";
      if (stock === 0) {
        msg = "This product is out of Stock ";
      }
      if (
        response?.data?.variation &&
        response?.data?.variation?.stock > 0 &&
        response?.data?.variation?.stock > (targetObject?.quantity ? (targetObject?.quantity+count) : count)
      ) {
        setCount((prevCount) => prevCount + 1);
      } else {
        toast.warning(msg);
      }
    } catch (error) {
      console.error("Error in increment:", error);
    } finally {
      setLoading(false);
    }
    };
    async function checkVariant() {
        const response = await axios.get(
          backendUrl + `get_variationtypebyproductVariationId/${singleData?.productvariation?.id}`
        );
        return response;
      }
    const decrement = () => {
        if (count > 1) {
            setCount((prevCount) => prevCount - 1);
        }
    };
    const dispatch = useDispatch();
    const AddCart = async() => {
        if (stock > 0) {
          const checkcart = JSON.parse(localStorage.getItem("cart"));
          let targetObject = getSingleDatailCart(checkcart , singleData?.productvariation?.id);
          if (loading) {
            // If an increment operation is already in progress, return early
            return;
          }
          setLoading(true);
          try {
            const response = await checkVariant();
            let msg = "Stock Limit Reached";
            if (stock === 0) {
              msg = "This product is out of Stock ";
            }
    
            if (
              response?.data?.variation &&
              response?.data?.variation?.stock >= (targetObject?.quantity ? (targetObject?.quantity+count) : count)
            ) {
              if (count > 0) {
                const sendData = {
                  id: singleData?.product?.id,
                  quantity: count,
                  title: singleData?.product?.title,
                  image_link: backendImageUrl + singleData?.product?.product_image,
                  variationId: singleData?.productvariation?.id,
                  variation: singleData?.productvariation,
                };
                dispatch(addSingleCart({ sendData }));
              } else {
                toast.warning("Product Count should atleast be 1");
              }
            } else {
              toast.warning(msg);
            }
          } catch (error) {
            console.error("Error in increment:", error);
          } finally {
            setLoading(false);
          }
        }else{
          toast.warning("Product is Out of Stock", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
    };
    function getSingleDatailCart (checkcart , variationId){
        let targetObject = null;
        if(checkcart?.length){
          targetObject = checkcart.find(
           (item) => item.variationId === variationId
         );
       }
       return targetObject;
      }
    return (
        <>
            <div className='flex items-center w-full  gap-x-4 md:gap-x-2 min-w-[740px]'>
            <div className=' flex gap-x-3 items-center w-[50%] sm:w-[50%] md:w-[80%] px-2 sm:px-2 md:px-3 py-1 rounded-xl border shadow-lg justify-between'>
                    <div class=" flex items-center gap-x-3 w-[50%]">
                        <img src={backendImageUrl+ singleData?.product?.product_image} alt="cartImage" class="w-[35px] h-[45px] object-cover" />
                        <div class=" flex flex-col">
                            <h3 class=" font-medium text-base">{singleData?.product?.title}</h3>
                            <span class=" text-xs text-left">{singleData?.variation?.name}{' '}{singleData?.variationtype?.name}</span>
                        </div>
                    </div>
                    <div className="w-[20%] text-black text-base font-semibold flex gap-x-3 px-4  py-1 bg-[#e7e7e7] rounded items-center justify-center">
                        <span onClick={decrement} className=" cursor-pointer">
                            <HiMinus />
                        </span>
                        <span>{count}</span>
                        <span onClick={increment} className=" cursor-pointer">
                            <HiPlus />
                        </span>
                    </div>
                    <div className='w-[30%] text-right'>₹ {singleData?.productvariation?.sale_price}</div>
            </div>
            <div className='w-[20%] text-left md:text-center'>
                <span  onClick={AddCart} className='py-2 px-2 cursor-pointer text-white bg-orange-400 rounded-xl inline-block font-medium text-sm sm:text-sm md:text-sm lg:text-base'>Add to Cart</span>
            </div>
            </div>
        </>
    )
}

export default ViewOrdersComponents
