import axios from "axios"
import { backendUrl } from "../config/config";

export const CheckUserLogin = async (AuthToken) => {
    try {
        const response = await axios.post(backendUrl + 'detail', null, {
            headers: {
                'Authorization': `Bearer ${AuthToken}`
            }
        });
        if (response?.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
};