import React from 'react'
import { backendImageUrl } from '../../config/config';

const SingleProductSlider = ({ border, image_link, changeImage, border_index }) => {

    return (
        <div onClick={() => changeImage(border, image_link)} className={`single_small_car mx-auto w-[75px] h-[75px] md:h-[85px] md:w-[85px] relative py-[2px] px-1 text-black text-center rounded-lg bg-[#d9dadc]  flex items-center cursor-pointer  ${border === border_index ? 'border-[#7e7e7e]  border-2' : ""}`}>
            <img src={backendImageUrl + image_link} alt="image" className='mx-auto object-contain' />
        </div>
    )
}

export default SingleProductSlider