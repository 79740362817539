import { createSlice } from "@reduxjs/toolkit";

const accountDetails = createSlice({
  name: "accountDetails",
  initialState: {
    loading: false,
    userDetails: [],
  },
  reducers: {
    accountDetailsRequest(state, action) {
      return {
        loading: true,
      };
    },
    accountDetailsSuccess(state, action) {
      return {
        loading: false,
        userDetails: action.payload.user,
      };
    },
    accountDetailsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = accountDetails;

export const {
    accountDetailsRequest,
    accountDetailsSuccess,
    accountDetailsFail
} = actions;

export default reducer;
