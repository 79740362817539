import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%", // Customize the max width of the modal
    height: "70%",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const VideoPopup = ({ isOpen, onRequestClose, videoUrl }) => {
//   const iframeRef = useRef(null);

//   useEffect(() => {
//     const setModalWidth = () => {
//       if (iframeRef.current) {
//         const videoWidth = iframeRef.current.offsetWidth;
//         // You may need to adjust the width based on your styling preferences
//         const newModalWidth = Math.min(videoWidth, 800); // Example: Set a maximum width of 800px
//         alert(newModalWidth)
//         document.querySelector(
//           ".ReactModal__Content"
//         ).style.width = `${newModalWidth}px`;
//       }
//     };

//     setModalWidth();

//     // Attach the resize event listener to update the width on window resize
//     window.addEventListener("resize", setModalWidth);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", setModalWidth);
//     };
//   }, [isOpen, videoUrl]);
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <iframe
        className="youtube_frame"
        src={videoUrl}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <span
        onClick={onRequestClose}
        className="absolute -top-2 -right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
      >
        <FaTimes size={22} />
      </span>
    </Modal>
  );
};

export default VideoPopup;
