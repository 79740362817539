import React from 'react'
import Component from './Component'
import donate from '../../assets/images/donate.jpg';
import freeshipping from '../../assets/images/freeshipping.png';
import safetynote from '../../assets/images/safetynote.png';

const Infos = () => {
    return (
        <div className='container mx-auto py-4 px-8 sm:px-16 md:px-20 lg:px-28 text-center mt-10 md:mt-14 lg:mt-20'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                <Component name="Pay It Forward" desc="It is our intention to use a portion of our profits to take care of the various Farmers from where our ingredients are sourced." img_link={donate}/>
                <Component name="Free shipping" desc="Enjoy with free shipping for orders of INR 1499/- above. We deliver air across India. We also have partnered up with Amazon India for easy delivery." img_link={freeshipping}/>
                <Component name="Safety Update" desc="Before using any new product, please do a patch test to avoid any skin irritation or allergic reaction. Discontinue in case or irritation or allergic reaction." img_link={safetynote} />
            </div>
        </div>
    )
}

export default Infos