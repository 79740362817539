import React from "react";
import image_link from "../../assets/images/single_product.png";
const ShippingProducts = ({ cart }) => {
  return (
    <div className="pb-4 mt-3">
      <div className=" px-3 py-2 bg-white rounded-lg shadow-md relative">
        <div className=" flex items-center justify-between">
          <div className=" flex items-center gap-x-3 w-1/2">
            <div className=" relative rounded-full w-[45px] h-[45px] aspect-square">
              <img
                src={cart?.image_link}
                alt="product"
                className=" object-cover absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full p-[5px]"
              />
              <span className=" absolute top-0 -right-1 bg-[#7d7d7d] text-[10px] font-bold text-white rounded-full min-w-[16px] h-[16px] text-center">
                {cart?.quantity}
              </span>
            </div>
            <div className=" flex flex-col">
              <h3 className=" font-medium text-base">{cart?.title}</h3>
              <span className=" text-xs">
                {cart?.variation?.variation_name}
                <span className="pl-1">
                  {cart?.variation?.variation_type_name}
                </span>
              </span>
            </div>
          </div>
          <div className=" w-1/2 text-right">
            ₹ {cart?.variation?.sale_price * cart?.quantity}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingProducts;
