import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Steps from "../components/Checkout/Steps";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Products from "../components/Checkout/Products";
import { BsQuestion } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getCarts } from "../actions/CartActions";
import Loader from "../components/Loader";
import Loginpopup from "../components/UserLogin/Loginpopup";
import RegisterPopup from "../components/UserLogin/RegisterPopup";
import validator from "validator";
import { CheckUserLogin } from "../utils/utils";
import axios from "axios";
import { backendUrl } from "../config/config";
import { toast } from "react-toastify";
import { getCouponValue } from "../actions/userDashboardActions";
import { IoIosArrowDown } from "react-icons/io";

const Checkout = () => {
  const [discountCode, setDiscountCode] = useState("");
  const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(true);
  const [discountError, setDiscountError] = useState("");
  const [loading, setLoading] = useState(false);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [LoggedIn, setLoggedIn] = useState(0);
  const [formData, setFormData] = useState({
    email: "",
    country: "",
    firstName: "",
    lastName: "",
    address: "",
    apartment: "",
    city: "",
    pincode: "",
    state: "",
    phoneNumber: "",
    companyName: "",
    gstnumber: "",
    orderNotes: "",
  });
  const [shippingFormData, setShippingFormData] = useState({
    shippingCountry: "",
    shippingFirstName: "",
    shippingLastName: "",
    shippingAddress: "",
    shippingApartment: "",
    shippingCity: "",
    shippingPincode: "",
    shippingState: "",
    shippingPhoneNumber: "",
    shippingCompanyName: "",
    shippinggstnumber: "",
  });
  const [differentAddress, setDifferentAddress] = useState(false);
  const [errors, setErrors] = useState({});
  const [hideLogin, setHideLogin] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const shippingHandleChange = (e) => {
    const { name, value } = e.target;
    setShippingFormData({ ...shippingFormData, [name]: value });
  };

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};

    if (!validator.isEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (validator.isEmpty(formData.country)) {
      newErrors.country = "Country is required";
    }
    if (validator.isEmpty(formData.firstName)) {
      newErrors.firstName = "First name is required";
    }
    if (validator.isEmpty(formData.lastName)) {
      newErrors.lastName = "Last name is required";
    }
    if (validator.isEmpty(formData.address)) {
      newErrors.address = "Address is required";
    }
    if (validator.isEmpty(formData.city)) {
      newErrors.city = "City is required";
    }
    if (validator.isEmpty(formData.pincode)) {
      newErrors.pincode = "Pincode is required";
    }
    if (validator.isEmpty(formData.state)) {
      newErrors.state = "State is required";
    }
    if (
      !validator.isMobilePhone(formData.phoneNumber, "any", {
        strictMode: false,
      })
    ) {
      newErrors.phoneNumber = "Invalid phone number";
    }

    // shipping address
    if (differentAddress) {
      if (validator.isEmpty(shippingFormData.shippingCountry)) {
        newErrors.shippingCountry = "Country is required";
      }
      if (validator.isEmpty(shippingFormData.shippingFirstName)) {
        newErrors.shippingFirstName = "First name is required";
      }
      if (validator.isEmpty(shippingFormData.shippingLastName)) {
        newErrors.shippingLastName = "Last name is required";
      }
      if (validator.isEmpty(shippingFormData.shippingAddress)) {
        newErrors.shippingAddress = "Address is required";
      }
      if (validator.isEmpty(shippingFormData.shippingCity)) {
        newErrors.shippingCity = "City is required";
      }
      if (validator.isEmpty(shippingFormData.shippingPincode)) {
        newErrors.shippingPincode = "Pincode is required";
      }
      if (validator.isEmpty(shippingFormData.shippingState)) {
        newErrors.shippingState = "State is required";
      }
      if (
        !validator.isMobilePhone(shippingFormData.shippingPhoneNumber, "any", {
          strictMode: false,
        })
      ) {
        newErrors.shippingPhoneNumber = "Invalid phone number";
      }
    }
    setErrors(newErrors);

    // If there are no errors, save the data to local storage and navigate to the shipping page
    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      checkuser("shipping");
      // Navigate to the shipping page (you can use your routing method here)
    }else{
      toast.warning("Please fill all required fields");
    }
  };
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [registerModelIsOpen, seRegisternModalIsOpen] = useState(false);

  const [totalAmount, setTotalAmount] = useState(0);
  const [savedAmount, setSavedAmount] = useState(0);
  const { CartData, loading: cartLoading } = useSelector(
    (state) => state.getCartState
  );
  const { couponValue, loading: couponLoading } = useSelector(
    (state) => state.CouponCodeState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCarts);
    getAddressNames();
  }, [hideLogin]);

  const checkuser = async (logic) => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");

    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === true) {
      if (logic === "hidelogin") {
        setHideLogin(true);
      } else if (logic === "shipping") {
        localStorage.setItem("checkoutData", JSON.stringify(formData));
        localStorage.removeItem("ShippingAddress");
        if (differentAddress) {
          localStorage.setItem(
            "ShippingAddress",
            JSON.stringify(shippingFormData)
          );
        }
        navigate("/shipping");
      }
    } else {
      if (logic === "shipping") {
        LoginOpenModel();
      }
    }
  };

  useEffect(() => {
    checkuser("hidelogin");
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    if (checkcart === null) {
      navigate("/");
    } else {
      if (checkcart.length <= 0) {
        navigate("/");
      }
    }
    if (CartData) {
      let totalPrice = 0; // Initialize the totalPrice variable

      CartData.forEach((cart) => {
        const price = cart.quantity * cart.variation.sale_price;
        totalPrice += price; // Accumulate the prices
      });
      setTotalAmount(totalPrice);
      dispatch(getCouponValue(totalPrice));
      let savedAmounts = 0; // Initialize the totalPrice variable
      CartData.forEach((cart) => {
        const price =
          cart.quantity *
          (cart?.variation?.mrp_price - cart?.variation?.sale_price);
        savedAmounts += price; // Accumulate the prices
      });
      setSavedAmount(savedAmounts);
    }
    const checkoutDatas = JSON.parse(localStorage.getItem("checkoutData"));
    if (checkoutDatas) {
      setFormData(checkoutDatas);
    }
    const ShippingAddress = JSON.parse(localStorage.getItem("ShippingAddress"));
    if (ShippingAddress) {
      setDifferentAddress(true);
      setShippingFormData(ShippingAddress);
    }
  }, [CartData]);
  const LoginOpenModel = () => {
    document.body.classList.add("user_login");
    setLoginModalIsOpen(true);
  };
  const closeLoginModal = () => {
    document.body.classList.remove("user_login");
    setLoginModalIsOpen(false);
  };
  const RegisterOpenModel = () => {
    document.body.classList.remove("user_login");
    document.body.classList.add("user_register");
    seRegisternModalIsOpen(true);
    closeLoginModal(false);
  };
  const RegisterCloseModal = () => {
    document.body.classList.remove("user_register");
    seRegisternModalIsOpen(false);
  };

  const handleDiscoutSubmit = async (e) => {
    e.preventDefault();
    const isDiscountCodeValid =
      validator.isAlphanumeric(discountCode) &&
      !validator.isEmpty(discountCode) &&
      !validator.contains(discountCode, " ");

    // Perform additional actions if the form is valid
    if (isDiscountCodeValid && !validator.isEmpty(discountCode)) {
      setIsDiscountCodeValid(true);
      // Your logic for form submission
      const sendData = {
        discountCode: discountCode,
        totalAmount: totalAmount,
      };
      let couponCode = localStorage.getItem("couponCode");

      if (couponCode && couponCode != null) {
        toast.warning("Your already have Coupon Code", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      } else {
        try {
          const response = await axios.post(
            backendUrl + "get-discount",
            sendData
          );

          // Handle the success response from the backend here
          if (response.data.status === 1) {
            toast.success("Your Coupon code Applied Successfully", {
              position: "top-right",
              autoClose: 3000, // Auto-close the toast after 3 seconds
            });
            localStorage.setItem("couponCode", discountCode);
            setDiscountCode("");
            dispatch(getCouponValue(totalAmount));
          }
          setLoading(false);
        } catch (error) {
          // Handle any errors that occur during the request
          console.error("Error sending data:", error);
          setLoading(false);
          toast.error(error?.response?.data?.error, {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
      }
    } else {
      setIsDiscountCodeValid(false);
    }
  };

  const handleDiscountCodeChange = (e) => {
    const value = e.target.value;
    setDiscountCode(value);
    // Perform validation
  };
  const getAddress = async (addressValue) => {
    if (addressValue !== "") {
      let address = await getCommonAddress(addressValue);
      if (address) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          country: address?.country,
          firstName: address?.first_name,
          lastName: address?.last_name ? address?.last_name : "",
          address: address?.address,
          apartment: address?.apartment,
          city: address?.city,
          pincode: address?.pincode,
          state: address?.state,
          phoneNumber: address?.phone_number,
        }));
      }
    }
  };
  const getShippingAddress = async (addressValue) => {
    if (addressValue !== "") {
      let address = await getCommonAddress(addressValue);
      if (address) {
        setShippingFormData((prevFormData) => ({
          ...prevFormData,
          shippingCountry: address?.country,
          shippingFirstName: address?.first_name,
          shippingLastName: address?.last_name ?address?.last_name : "",
          shippingAddress: address?.address,
          shippingApartment: address?.apartment,
          shippingCity: address?.city,
          shippingPincode: address?.pincode,
          shippingState: address?.state,
          shippingPhoneNumber: address?.phone_number,
        }));
      }
    }
  };
  async function getCommonAddress(addressValue) {
    let token = localStorage.getItem("auth_Token");
    try {
      const response = await axios.post(
        backendUrl + `getAddressByValue`,
        { addressValue },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.address) {
        return response.data.address;
      } else {
        toast.warning("This address is not available please choose other", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
    } catch (error) {
      console.error("API request failed:", error);
      if (error.response.status === 401) {
        toast.warning("Please Login for changing the Address", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
    }
  }

  const getAddressNames = async () => {
    let token = localStorage.getItem("auth_Token");
    try {
      // Perform the API request here
      const response = await axios.get(backendUrl + `get-address`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response?.data?.address);
      setShippingAddress(response?.data?.address);
      setLoggedIn(response?.data?.isLoggedIn);
    } catch (error) {
      // Handle API request errors (e.g., show error message)
      toast.error("Something went wrong , Please try again !", {
        position: "top-right",
        autoClose: 3000, // Auto-close the toast after 3 seconds
      });
    }
  };
  return (
    <Fragment>
      <Header disabledCart={true} />
      <div className="container mx-auto py-4">
        <Steps linkname="info" />
        <div className=" mt-3 sm:mt-4 md:mt-5 px-3 sm:px-4 md:px-5">
          <div className=" flex flex-wrap sm:flex-wrap md:flex-nowrap items-start justify-between flex-col-reverse sm:flex-col-reverse md:flex-row">
            <form onSubmit={handleSubmit} className="w-full sm:w-full md:w-1/2">
              <div className=" flex flex-wrap items-center justify-between">
                <span className=" text-lg">Contact Information</span>
                {!hideLogin && (
                  <span
                    className=" text-xs cursor-pointer"
                    onClick={LoginOpenModel}
                  >
                    Already have an account? Log in
                  </span>
                )}
              </div>
              <div className="mt-3">
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="checkout_input"
                />
                {errors.email && (
                  <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className=" flex gap-x-2 items-center mt-3">
                <input
                  type="checkbox"
                  name="updates"
                  id="updates_check"
                  className=" cursor-pointer update_checkbox"
                />
                <label
                  htmlFor="updates_check"
                  className="text-xs cursor-pointer"
                >
                  Send me order updates and discounts
                </label>
              </div>

              <div className=" flex flex-wrap items-center justify-between mt-4">
                <span className=" text-lg">Billing Address</span>
                <span
                  className=" text-lg opacity-50 cursor-pointer"
                  onClick={() => setDifferentAddress(!differentAddress)}
                >
                  Shipping to a different address
                </span>
              </div>
              <div>
                <div className="relative  mt-2">
                  <select
                    className="checkout_input"
                    onChange={(event) => getAddress(event.target.value)}
                  >
                    {LoggedIn === 0 ? (
                      <option value="">
                        To select your own address using login
                      </option>
                    ) : (
                      <>
                        <option value="">Select Your Address (Optional)</option>
                        {shippingAddress.map((shipping) => (
                          <option key={shipping?.id} value={shipping?.id}>
                            {shipping?.addressname}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  <span className="absolute top-1/2 right-2 -translate-y-1/2 z-10 pointer-events-none">
                    <IoIosArrowDown />
                  </span>
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="Company name (optional)"
                    name="companyName"
                    className="checkout_input w-full"
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="GST (optional)"
                    name="gstnumber"
                    className="checkout_input w-full"
                    value={formData.gstnumber}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.firstName && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.lastName && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap">
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Apartment , Suite , ect."
                    name="apartment"
                    value={formData.apartment}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.apartment && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.apartment}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="checkout_input"
                  />
                  {errors.address && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.address}
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="PIN Code"
                    name="pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.pincode && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.pincode}
                    </p>
                  )}
                </div>
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.city && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.city}
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="State"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.state && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.state}
                    </p>
                  )}
                </div>
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.country && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.country}
                    </p>
                  )}
                </div>
              </div>
              <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="w-full sm:w-full md:w-1/2">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="checkout_input w-full"
                  />
                  {errors.phoneNumber && (
                    <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>
              </div>
              {differentAddress && (
                <>
                  <div className=" mt-4">
                    <span className=" text-lg">Shipping Address</span>
                  </div>
                  <div>
                    <div className="relative  mt-2">
                      <select
                        className="checkout_input"
                        onChange={(event) =>
                          getShippingAddress(event.target.value)
                        }
                      >
                        {LoggedIn === 0 ? (
                          <option value="">
                            To select your own address using login
                          </option>
                        ) : (
                          <>
                            <option value="">
                              Select Your Address (Optional)
                            </option>
                            {shippingAddress.map((shipping) => (
                              <option key={shipping?.id} value={shipping?.id}>
                                {shipping?.addressname}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                      <span className="absolute top-1/2 right-2 -translate-y-1/2 z-10 pointer-events-none">
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                    {" "}
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="Company name (optional)"
                        name="shippingCompanyName"
                        className="checkout_input w-full"
                        value={shippingFormData.shippingCompanyName}
                        onChange={shippingHandleChange}
                      />
                    </div>
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="GST Number (optional)"
                        name="shippinggstnumber"
                        className="checkout_input w-full"
                        value={shippingFormData.shippinggstnumber}
                        onChange={shippingHandleChange}
                      />
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="shippingFirstName"
                        value={shippingFormData.shippingFirstName}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingFirstName && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingFirstName}
                        </p>
                      )}
                    </div>
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="shippingLastName"
                        value={shippingFormData.shippingLastName}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingLastName && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingLastName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap">
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Apartment , Suite , ect."
                        name="shippingApartment"
                        value={shippingFormData.shippingApartment}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingApartment && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingApartment}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        placeholder="Address"
                        name="shippingAddress"
                        value={shippingFormData.shippingAddress}
                        onChange={shippingHandleChange}
                        className="checkout_input"
                      />
                      {errors.shippingAddress && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingAddress}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="PIN Code"
                        name="shippingPincode"
                        value={shippingFormData.shippingPincode}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingPincode && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingPincode}
                        </p>
                      )}
                    </div>
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="City"
                        name="shippingCity"
                        value={shippingFormData.shippingCity}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingCity && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingCity}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="State"
                        name="shippingState"
                        value={shippingFormData.shippingState}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingState && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingState}
                        </p>
                      )}
                    </div>
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="Country"
                        name="shippingCountry"
                        value={shippingFormData.shippingCountry}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingCountry && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingCountry}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" flex items-baseline justify-between gap-3 pt-4 flex-wrap sm:flex-wrap md:flex-nowrap">
                    <div className="w-full sm:w-full md:w-1/2">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="shippingPhoneNumber"
                        value={shippingFormData.shippingPhoneNumber}
                        onChange={shippingHandleChange}
                        className="checkout_input w-full"
                      />
                      {errors.shippingPhoneNumber && (
                        <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                          {errors.shippingPhoneNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className="mt-5">
                <label className=" text-lg">Order Notes (Optional)</label>
                <textarea
                  name="orderNotes"
                  id=""
                  cols="30"
                  rows="3"
                  className="mt-1 w-full checkout_input resize-none"
                  value={formData.orderNotes}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className=" flex gap-x-2 items-center mt-3">
                <input
                  type="checkbox"
                  name="save_info"
                  id="save_info"
                  className=" cursor-pointer update_checkbox"
                />
                <label htmlFor="save_info" className="text-xs cursor-pointer">
                  Save this information for next time{" "}
                </label>
              </div>
              <div className=" mt-3 sm:mt-4 md:mt-5">
                <div className=" flex items-center justify-between border-b pb-3 border-b-[#b5b5b5]">
                  <Link
                    to="/"
                    className=" opacity-40 font-medium text-[13px] flex items-center"
                  >
                    <span>
                      <MdOutlineKeyboardArrowLeft size={18} />
                    </span>
                    <span>Return to Home</span>{" "}
                  </Link>
                  <button
                    type="submit"
                    className=" text-white font-medium text-lg bg-[#ff8a00] px-4 py-1.5 rounded-2xl text-center"
                  >
                    Place Order
                  </button>
                </div>
                <div className=" flex items-center text-xs mt-3 sm:mt-4 md:mt-5 gap-x-3">
                  <Link to="/">Refund Policy</Link>
                  <Link to="/">Shipping Policy</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  <Link to="/">Terms of Service</Link>
                </div>
              </div>
            </form>
            <div className="w-full sm:w-full md:w-[45%] lg:w-[35%]">
              <div className="border-b-2">
                {cartLoading ? (
                  <Loader />
                ) : (
                  CartData &&
                  CartData.map((cart, index) => (
                    <Products key={index} cart={cart} />
                  ))
                )}
              </div>
              <div className="py-4">
                <form
                  onSubmit={handleDiscoutSubmit}
                  className=" flex items-baseline gap-x-3 justify-between border-b-2 pb-4"
                >
                  <div className="w-[65%] flex flex-col">
                    <input
                      type="text"
                      placeholder="Discount Code"
                      name="discountCode"
                      className="checkout_input w-full"
                      value={discountCode}
                      onChange={handleDiscountCodeChange}
                    />
                    {!isDiscountCodeValid && (
                      <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                        Discount code must be required.
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className=" text-white font-medium text-lg bg-[#ff8a00] px-4 py-1.5 rounded-2xl w-[30%] text-center"
                  >
                    Apply
                  </button>
                </form>
              </div>
              <div className=" pb-4 border-b">
                <div className=" flex justify-between items-center">
                  <span className=" w-1/2">Subtotal</span>
                  <span className=" w-1/2 text-right">₹ {totalAmount}</span>
                </div>
                <div className=" flex justify-between items-center mt-2">
                  <span className=" w-1/2">Saved</span>
                  <span className=" w-1/2 text-right">₹ {savedAmount}</span>
                </div>
              </div>
              <div className=" py-4 border-b">
                <div className="flex text-xs justify-between items-center">
                  <span className=" flex items-center gap-x-1">
                    <span>Shipping</span>{" "}
                    <span className=" bg-gray-500 text-white rounded-full">
                      <BsQuestion size={14} />
                    </span>
                  </span>
                  <span>Calculate at next step</span>
                </div>
              </div>
              {couponValue && (
                <div className="pt-4">
                  <div className=" flex items-center justify-between gap-x-2 text-lg">
                    <span>Discount</span>
                    <span className=" w-1/2 text-right">₹ {couponValue}</span>
                  </div>
                </div>
              )}
              <div className="pb-3 pt-4">
                <div className=" flex items-center justify-between gap-x-2 text-lg">
                  <span className=" w-1/2 flex flex-col">
                    <span>Total </span>
                    <span className=" text-xs">Including Taxes</span>
                  </span>
                  <span className=" w-1/2 text-right">
                    ₹{" "}
                    {couponValue != null
                      ? totalAmount - couponValue
                      : totalAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loginpopup
        isOpen={loginModalIsOpen}
        onRequestClose={closeLoginModal}
        onOpenRegister={RegisterOpenModel}
        currentPage={true}
        setHideLogin={setHideLogin}
      />
      <RegisterPopup
        isOpen={registerModelIsOpen}
        onRequestClose={RegisterCloseModal}
        currentPage={true}
        setHideLogin={setHideLogin}
      />
    </Fragment>
  );
};

export default Checkout;
