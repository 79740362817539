import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { backendUrl } from "../../config/config";
import { toast } from "react-toastify";
import { getAccountDetails } from "../../actions/userDashboardActions";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [clickLoad, setClockLoad] = useState(false);
  const [currenteyesOpen, setCurrenteyesOpen] = useState(false);
  const [neweyesOpen, setNeweyesOpen] = useState(false);
  const [confirmeyesOpen, setConfirmeyesOpen] = useState(false);
  const dispatch = useDispatch();
  const { loading, userDetails } = useSelector(
    (state) => state.accountDetailsState
  );

  const [formData, setFormData] = useState({
    currentpassword: "",
    newpassword: "",
    confirmnewpassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    dispatch(getAccountDetails);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (validator.isEmpty(formData.currentpassword)) {
      validationErrors.currentpassword = "Current password is required.";
    }

    if (!validator.isLength(formData.newpassword, { min: 8 })) {
      validationErrors.newpassword =
        "New password must be at least 8 characters long.";
    }

    if (!validator.equals(formData.newpassword, formData.confirmnewpassword)) {
      validationErrors.confirmnewpassword =
        "New password and confirm new password must match.";
    }
    setClockLoad(true);
    if (Object.keys(validationErrors).length === 0) {
      // Submit the form data to your API
      setErrors({});
      let token = localStorage.getItem("auth_Token");
      const sendData = {
        currentpassword: formData.currentpassword,
        newpassword: formData.newpassword,
      };
      try {
        // Perform the API request here
        const response = await axios.post(
          backendUrl + "change-password",
          sendData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === "1") {
          toast.success("Password changed Successful", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
          setFormData({
            currentpassword: "",
            newpassword: "",
            confirmnewpassword: "",
          });
        }
        setClockLoad(false);
      } catch (error) {
        // Handle API request errors (e.g., show error message)
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error, {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
        setClockLoad(false);
      }
    } else {
      setClockLoad(false);
      setErrors(validationErrors);
    }
  };

  return (
    <div className="mt-3 sm:mt-4">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center align-center gap-x-3 flex-wrap sm:flex-wrap md:flex-nowrap gap-y-2">
          <input
            type="text"
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full sm:w-full md:w-1/2"
            value={userDetails?.firstName}
            readOnly
          />
          <input
            type="text"
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full sm:w-full md:w-1/2"
            value={userDetails?.lastName}
            readOnly
          />
        </div>
        <div className="flex justify-center align-center pt-2 sm:pt-3 md:pt-4">
          <input
            type="text"
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="Email ID"
            value={userDetails?.email}
            readOnly
          />
        </div>
        <div className="flex flex-col gap-y-2 justify-center align-center mt-2 sm:mt-3 md:mt-4 relative">
          <input
            type={currenteyesOpen ? "text" : "password"}
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="Current Password"
            name="currentpassword"
            value={formData.currentpassword}
            onChange={handleInputChange}
          />
          {errors.currentpassword && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5">
              {errors.currentpassword}
            </div>
          )}
          {currenteyesOpen ? (
            <FaEye
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setCurrenteyesOpen(false)}
            />
          ) : (
            <FaEyeSlash
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setCurrenteyesOpen(true)}
            />
          )}
        </div>
        <div className="flex flex-col gap-y-2 justify-center align-center mt-2 sm:mt-3 md:mt-4 relative">
          <input
            type={neweyesOpen ? "text" : "password"}
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="New Password"
            name="newpassword"
            value={formData.newpassword}
            onChange={handleInputChange}
          />
          {errors.newpassword && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5">
              {errors.newpassword}
            </div>
          )}
          {neweyesOpen ? (
            <FaEye
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setNeweyesOpen(false)}
            />
          ) : (
            <FaEyeSlash
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setNeweyesOpen(true)}
            />
          )}
        </div>
        <div className="flex flex-col gap-y-2 justify-center align-center mt-2 sm:mt-3 md:mt-4 relative">
          <input
            type={confirmeyesOpen ? "text" : "password"}
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="Confirm New Password"
            name="confirmnewpassword"
            value={formData.confirmnewpassword}
            onChange={handleInputChange}
          />
          {errors.confirmnewpassword && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5">
              {errors.confirmnewpassword}
            </div>
          )}
          {confirmeyesOpen ? (
            <FaEye
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setConfirmeyesOpen(false)}
            />
          ) : (
            <FaEyeSlash
              className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
              onClick={() => setConfirmeyesOpen(true)}
            />
          )}
        </div>
        <div className="flex justify-center align-center pt-2 sm:pt-3 md:pt-4">
          <button
            className={`px-3 py-2 text-center text-white font-medium theme_bg_green rounded-xl ${
              clickLoad ? " opacity-50 pointer-events-none	" : ""
            }`}
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
