import React, { Fragment, useEffect } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import { getProductsBySubcategorySlug } from "../actions/subcategoryProductsActions";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { backendImageUrl } from "../config/config";
import Loader from "../components/Loader";
import Component from "../components/BestSellers/Component";
import Footer from "../components/Footer/Footer";

const SubCategory = () => {
  const { loading, products, subCategory } = useSelector(
    (state) => state.subcategoryProductsState
  );

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getProductsBySubcategorySlug(slug));
  }, [dispatch]);

  return (
    <Fragment>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="w-full h-[85vh] lg:h-[80vh] relative bann_img">
            <img
              src={backendImageUrl + subCategory?.banner_image}
              alt="banner_image"
              className="h-full object-cover mx-auto w-full object-[85%] md:object-center"
            />
            <div className=" absolute bottom-6 left-14 sm:left-14 md:left-24 lg:left-32 text-2xl sm:text-2xl md:text-2xl lg:text-3xl font-medium">
              {subCategory ? subCategory.name : ""}
            </div>
          </div>
          <div className="pb-10 bg-[#e6e7eb]">
            <div className=" container mx-auto pt-20 px-8 sm:px-16 md:px-20 lg:px-28">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-6">
                {products &&
                  products.map((product) => (
                    <Component
                      key={product.id}
                      productObject={product}
                      productId={product.id}
                      pro_slug={product.slug}
                      name={product.title}
                      pro_name={product.short_desc}
                      stars={5}
                      img_link={product.product_image}
                      variations={product.variation}
                      isnewArrival ={product.home_products}
                      wishlistcheck = {product?.wishlist}
                    />
                  ))}
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <Footer />
    </Fragment>
  );
};

export default SubCategory;
