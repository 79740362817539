import { createSlice } from "@reduxjs/toolkit";

const SpecialProduct = createSlice({
    name: "SpecialProduct",
    initialState: {
        loading: false,
        specialpPoduct:null,
        endDate:null,
        product:null,
        status:0
    },
    reducers: {
        SpecialProductRequest(state, action) {
            return {
                loading: true,
                status:0
            }
        },
        SpecialProductSuccess(state, action) {
            return {
                loading: false,
                specialpPoduct: action.payload.specialpPoduct,
                endDate: action.payload.endDate,
                product: action.payload.product,
                status:1,
            }
        },
        SpecialProductFail(state, action) {
            return {
                loading: false,
                error: action.payload,
                status:0,
            }
        }
    }
})

const { actions, reducer } = SpecialProduct;

export const { SpecialProductRequest, SpecialProductSuccess, SpecialProductFail } = actions;

export default reducer;