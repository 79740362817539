import React, { useState } from "react";
import footer_payment from "../../assets/images/footer_payment.png";
import logo from "../../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { CheckUserLogin } from "../../utils/utils";
import Loginpopup from "../UserLogin/Loginpopup";
import RegisterPopup from "../UserLogin/RegisterPopup";
import ForgetPassword from "../UserLogin/ForgetPassword";

const BottomFooter = () => {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [registerModelIsOpen, seRegisternModalIsOpen] = useState(false);
  const [forgetModelIsOpen, setForgetModelIsOpen] = useState(false);

  const navigate = useNavigate();

  const LoginOpenModel = async (navigateLink) => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");
    
    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === false) {
      document.body.classList.add("user_login");
      setLoginModalIsOpen(true);
    } else {
      navigate(navigateLink);
    }
  };
  const closeLoginModal = () => {
    document.body.classList.remove("user_login");
    setLoginModalIsOpen(false);
  };
  const RegisterOpenModel = () => {
    document.body.classList.remove("user_login");
    document.body.classList.add("user_register");
    seRegisternModalIsOpen(true);
    closeLoginModal(false);
  };
  const ForgetOpenModel = () => {
    document.body.classList.remove("user_login");
    document.body.classList.add("user_register");
    setForgetModelIsOpen(true);
    closeLoginModal(false);
  };
  
  const RegisterCloseModal = () => {
    document.body.classList.remove("user_register");
    seRegisternModalIsOpen(false);
  };
  const ForgetCloseModal = () => {
    document.body.classList.remove("user_register");
    setForgetModelIsOpen(false);
  };
  return (
    <div className="mx-auto py-4 px-4 md:px-8 lg:px-10 mt-4 sm:mt-4 md:mt-6 lg:mt-8 poppins_font">
      <div className="flex flex-wrap sm:flex-nowrap gap-x-3 items-baseline gap-y-6">
        <div className="w-[100%] sm:w-[40%] text-left">
          <div className="text-2xl font-semibold">Care for Your Skin ,</div>
          <div className="text-2xl font-semibold">Care for Your Beauty</div>
          <p className="text-[13px] font-semibold pt-1 opacity-60">
            Let Your skin define your age not your years.
          </p>
          <p className="text-[13px] font-semibold opacity-60">
            Let Your natural beauty shine through.
          </p>
        </div>
        {/* <div className='w-[100%] sm:w-[60%] text-left'>
                    <div className=' grid grid-cols-2 sm:grid-cols-3 gap-x-3 md:gap-x-5'>
                        <div>
                            <div className='text-base font-medium'>Company</div>
                            <ul className='text-[13px] font-medium pt-2 opacity-90'>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li className='pt-2 text-[#818181]'><a href="#">Locations</a></li>
                                <li className='pt-2'><a href="#">Reviews</a></li>
                            </ul>
                        </div>
                        <div>
                            <div className='text-base font-medium'>Useful Links</div>
                            <ul className='text-[13px] font-medium pt-2 opacity-90'>
                                <li><Link to="/products">New Products</Link></li>
                                <li className='pt-2'><a href="#">Best Sellers</a></li>
                                <li className='pt-2'><a href="#">Bundle & Save</a></li>
                                <li className='pt-2'><a href="#">Online Gift Cards</a></li>
                            </ul>
                        </div>
                        <div>
                            <div className='text-base font-medium'>Information</div>
                            <ul className='text-[13px] font-medium pt-2 opacity-90'>
                                <li><Link to="/return">Start a Return</Link></li>
                                <li className='pt-2'><Link to="/contact-us">Contact Us</Link></li>
                                <li className='pt-2'><Link to="/faq">FAQ</Link></li>
                                <li className='pt-2'><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                                <li className='pt-2'><Link to="/privacy-policy">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
        <div className="w-[100%] sm:w-[60%] text-left">
          <div className=" grid grid-cols-2 sm:grid-cols-3 gap-x-3 md:gap-x-5">
            <div>
              <div className="text-base font-medium">Company</div>
              <ul className="text-[13px] font-medium pt-2 opacity-90">
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="pt-2">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li className="pt-2 text-[#818181]">
                  <Link href="#">Locations</Link>
                </li>
                <li className="pt-2">
                  <a href="#">Reviews</a>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-base font-medium">Useful Links</div>
              <ul className="text-[13px] font-medium pt-2 opacity-90">
                <li>
                  <span className=" cursor-pointer"
                    onClick={() => LoginOpenModel("/user-dashboard")}
                  >
                    My Account
                  </span>
                </li>
                <li className="pt-2">
                  <a href="#">Track Your Order</a>
                </li>
                <li className="pt-2">
                  <a href="#">Best Sellers</a>
                </li>
                <li className="pt-2">
                  <a href="#">Bundle & Save</a>
                </li>
                <li className="pt-2">
                  <Link to="/category/combo">Online Gift Cards</Link>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-base font-medium">Information</div>
              <ul className="text-[13px] font-medium pt-2 opacity-90">
                <li>
                  <Link to="/return">Start a Return</Link>
                </li>
                <li className="pt-2">
                  <Link to="/faq">FAQ</Link>
                </li>
                <li className="pt-2">
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li className="pt-2">
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-3 items-center pt-4 sm:pt-6 md:pt-8 lg:pt-10 gap-y-2">
        <div className="text-xs font-semibold opacity-60 text-left">
          {" "}
          ⓒThe Herb Key
        </div>
        <div>
          <img
            src={logo}
            alt="logo"
            className="ml-auto sm:mx-auto w-[auto] h-[50px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
          />
        </div>
        <div className="text-right col-span-2 sm:col-span-1">
          <img
            src={footer_payment}
            alt="footer_payment"
            className="w-[auto] h-[50px] mx-auto"
          />
        </div>
      </div>
      <Loginpopup
        isOpen={loginModalIsOpen}
        onRequestClose={closeLoginModal}
        onOpenRegister={RegisterOpenModel}
        onOpenForget={ForgetOpenModel}
      />
      <RegisterPopup
        isOpen={registerModelIsOpen}
        onRequestClose={RegisterCloseModal}
      />
      <ForgetPassword
        isOpen={forgetModelIsOpen}
        onRequestClose={ForgetCloseModal}
      />
    </div>
  );
};

export default BottomFooter;
