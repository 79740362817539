import React, { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import ContactUsPopUp from "./ContactUsPopUp";
import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { backendImageUrl, backendUrl } from "../../config/config";
import { useDispatch } from "react-redux";
import { addSingleCart } from "../../actions/CartActions";

const MyOrdersComponent = ({
  setOptionChange,
  OrderData,
  optionChange,
  getOrderId,
}) => {

  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [cartDate, setCartDate] = useState("");
  const [loading, setLoading] = useState(false);
  const popUpOpenModel = () => {
    document.body.classList.add("contact_address");
    setPopUpIsOpen(true);
  };
  const popUpCloseModel = () => {
    document.body.classList.remove("contact_address");
    setPopUpIsOpen(false);
  };
  const dispatch = useDispatch();
  const getProducts = async () => {
    const IDs = cartData.map((item) => ({
      productId: item?.product?.id,
      variationId: item?.variation?.id,
      quantity: item?.quantity ? item?.quantity : 0,
    }));
    let sendID = { IDs };
    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.post(
        backendUrl + "get-checkout-productsbyId",
        sendID,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.checkoutProducts;
    } catch (error) {
      toast.error("Something went wrong , Please try again !", {
        position: "top-right",
        autoClose: 3000, // Auto-close the toast after 3 seconds
      });
    }
  };
  useEffect(() => {
    setCartData(JSON.parse(OrderData?.cart_data));
    const originalDateString = OrderData?.created_at;
    const date = new Date(originalDateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    setCartDate(formattedDate);
    
  }, []);
  const reOrder = async () => {
      let i =0;
      let finalData = await getProducts();
      if(finalData && finalData.length > 0){
          while(i < finalData.length){
            let stock =finalData[i]?.productvariation?.stock;
            let count = finalData[i]?.quantity;
            if (stock > 0) {
              const checkcart = JSON.parse(localStorage.getItem("cart"));
              let targetObject = getSingleDatailCart(checkcart , finalData[i]?.productvariation?.id);
              if (loading) {
                // If an increment operation is already in progress, return early
                return;
              }
              setLoading(true);
              try {
                // const response = await checkVariant(finalData[i]?.productvariation?.id);
                let msg = "Stock Limit Reached";
                if (stock === 0) {
                  msg = "This product is out of Stock ";
                }
        
                if (
                  finalData[i]?.productvariation &&
                  finalData[i]?.productvariation?.stock >= (targetObject?.quantity ? (targetObject?.quantity+count) : count)
                ) {
                  if (count > 0) {
                    const sendData = {
                      id: finalData[i]?.product?.id,
                      quantity: count,
                      title: finalData[i]?.product?.title,
                      image_link: backendImageUrl + finalData[i]?.product?.product_image,
                      variationId: finalData[i]?.productvariation?.id,
                      variation: finalData[i]?.productvariation,
                    };
                    dispatch(addSingleCart({ sendData }));
                  } else {
                    toast.warning("Product Count should atleast be 1");
                  }
                } else {
                  toast.warning(msg);
                }
              } catch (error) {
                console.error("Error in increment:", error);
              } finally {
                setLoading(false);
              }
            }else{
              toast.warning("Product is Out of Stock", {
                position: "top-right",
                autoClose: 3000, // Auto-close the toast after 3 seconds
              });
            }
            i++;
          }
      }
  }
  function getSingleDatailCart (checkcart , variationId){
    let targetObject = null;
    if(checkcart?.length){
      targetObject = checkcart.find(
       (item) => item.variationId === variationId
     );
   }
   return targetObject;
  }
  async function checkVariant(variationId) {
    const response = await axios.get(
      backendUrl + `get_variationtypebyproductVariationId/${variationId}`
    );
    return response;
  }
  return (
    <>
      {optionChange === "my_order" && (
        <>
          <div className=" flex items-stretch gap-x-3  justify-normal md:justify-around min-w-[1160px]">
            <div className="w-[70%] py-1 px-1 bg-gray-100 shadow flex gap-x-3 items-center rounded-xl text-center text-xs md:text-sm">
              <div className="w-[10%]">#{OrderData?.checkout_number}</div>
              <div className="w-[20%]">{cartDate}</div>
              <div className="w-[10%] capitalize">{OrderData?.status}</div>
              <div className="w-[10%]">
                {cartData.length}{" "}
                {OrderData?.cart_data.length > 1 ? "Items" : "Item"}
              </div>
              <div className="w-[20%]">
                ₹{" "}
                {OrderData?.coupon_value != null
                  ? (OrderData?.total_amout +
                    OrderData?.shipping_amount -
                    OrderData?.coupon_value).toFixed(2)
                  : (OrderData?.total_amout + OrderData?.shipping_amount).toFixed(2)}
              </div>
              <div className="w-[15%]">
                <span className=" capitalize">{OrderData?.payment_status}</span>
                <span className=" uppercase">({OrderData?.payment_type})</span>
              </div>
              <div
                className="w-[15%] flex items-center gap-x-1 cursor-pointer"
                onClick={() => setOptionChange("vieworders")}
              >
                <span onClick={() => getOrderId(OrderData)}>View Order</span>
                <span>
                  <MdArrowDropDown className=" -rotate-90 text-lg" />
                </span>
              </div>
            </div>
            <div onClick={reOrder} className="flex items-center cursor-pointer py-1 px-5 bg-orange-400 rounded-xl text-white text-xs sm:text-sm md:text-sm lg:text-base xl:text-md font-medium">
              Re-Order
            </div>
            <div
              className="flex items-center py-1 px-5 theme_light_bg_green rounded-xl text-white text-xs sm:text-sm md:text-sm lg:text-base xl:text-md font-medium cursor-pointer"
              onClick={popUpOpenModel}
            >
              Contact us for Change
            </div>
          </div>
          <ContactUsPopUp
          checkout_number ={OrderData?.checkout_number}
            isOpen={popUpIsOpen}
            onRequestClose={popUpCloseModel}
          />
        </>
      )}
    </>
  );
};

export default MyOrdersComponent;
