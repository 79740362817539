import { createSlice } from "@reduxjs/toolkit";

const myOrdersDetails = createSlice({
  name: "myOrdersDetails",
  initialState: {
    loading: false,
    orderDetails: [],
  },
  reducers: {
    myOrdersRequest(state, action) {
      return {
        loading: true,
      };
    },
    myOrdersSuccess(state, action) {
      return {
        loading: false,
        orderDetails: action.payload.orderDetails,
      };
    },
    myOrdersFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = myOrdersDetails;

export const {
    myOrdersRequest,
    myOrdersSuccess,
    myOrdersFail
} = actions;

export default reducer;
