import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { HiMinus, HiPlus } from "react-icons/hi";
import { backendUrl } from "../config/config";
import { toast } from "react-toastify";

const CartPopupComponents = ({ cart, removeCart, increaseCart }) => {
  const [count, setCount] = useState(cart?.quantity);
  const [stringCounter, setStringCounter] = useState("");
  const [loading, setLoading] = useState(false);

  const increment = async() => {
    if (loading) {
      // If an increment operation is already in progress, return early
      return;
    }

    setLoading(true);
    try {
      const response = await checkVariant();
      let msg = "Stock Limit Reached";
      if (
        response?.data?.variation &&
        response?.data?.variation?.stock > 0 &&
        response?.data?.variation?.stock >=count+1
      ) {
        setCount((prevCount) => prevCount + 1);
        setStringCounter("increment");
      } else {
        toast.warning(msg);
      }
    } catch (error) {
      console.error("Error in increment:", error);
    } finally {
      setLoading(false);
    }
  };
  async function checkVariant() {
    const response = await axios.get(
      backendUrl + `get_variationtypebyproductVariationId/${cart?.variation?.id}`
    );
    return response;
  }
  useEffect(() => {
    increaseCart(cart?.id, cart?.variation?.id, stringCounter ,count);
  }, [count, stringCounter]);

  const decrement = () => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
      setStringCounter("decrement");
    }
  };

  return (
    <div className="px-2 sm:px-3 mt-3">
      <div className=" px-3 py-2 bg-white rounded-lg shadow-md relative">
        <div className=" flex items-center justify-between">
          <div className=" flex items-center gap-x-3 w-[45%]">
            <img
              src={cart?.image_link}
              alt="cartImage"
              className="w-[35px] h-[45px] object-cover"
            />
            <div className=" flex flex-col">
              <h3 className=" font-medium text-base">{cart?.title}</h3>
              <span className=" text-xs">
                {cart?.variation?.variation_name}{" "}
                {cart?.variation?.variation_type_name}
              </span>
            </div>
          </div>
          <div className="text-black text-base font-semibold flex gap-x-3 px-2 w-[25%] py-1 bg-[#e7e7e7] rounded items-center justify-center">
            <span onClick={decrement} className=" cursor-pointer">
              <HiMinus />
            </span>
            <span>{count}</span>
            <span onClick={increment} className=" cursor-pointer">
              <HiPlus />
            </span>
          </div>
          <div className=" text-base w-[30%] text-right">
            ₹ <span>{(cart?.variation?.sale_price * cart?.quantity).toFixed(2)}</span>
          </div>
        </div>
        <span
          className="absolute -top-2 -right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
          onClick={() => removeCart(cart?.id, cart?.variation?.id)}
        >
          <FaTimes size={10} />
        </span>
      </div>
    </div>
  );
};

export default CartPopupComponents;
