import React, { useEffect } from 'react'
import TopHeader from '../components/TopHeader'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
            
            <Header />
            <div className='container mx-auto text-lg text-center mt-10 mb-10 font-medium term-cont'>
                <div className=' text-lg font-semibold title'>Welcome to THE HERB KEY!</div>
                <p className='pt-4'>Here in The Herb Key, we strongly believe in the PURE is GOLD manthiram and we endeavour to make products that are a 100 % natural,
                    plant based, chemical-free products which are skin friendly. We strive to follow a path of from NATURE TO YOU with no tampering by carefully handpicking and sourcing directly from farmers.
                </p>
                <p className='pt-4'>delivery what we vouch for All our items are made from traditional recipes passed down from generations and proven to work
                    based on anecdotal stories. We want to give our customers the confidence to be the best version of themselves by harnessing
                    their natural beauty.</p>

                <div className=' text-lg font-semibold pt-20 title'>We say NO to:</div>
                <div className=' text-lg font-semibold pt-3 title'>Ingredients :</div>
                <p>No Parabens, Synthetic colouring, artificial fragrances, sulphates, harsh chemical and heavy metals. Our first priority is to find the
                    right ingredients. We prefer to partner with singe source suppliers for each ingredient. This way we can support a larger number
                    of local farmers and it also reduces the risk of cross contamination with potential allergens. The prices of the raw ingredients
                    based to make our oil, serum, mask & soaps. Reflect the quality of those ingredients and the standards we maintain during our
                    production process.
                </p>
                <p className='pt-4 text-lg'>Packaging :</p>
                <p>all our packaging and shipping material is carefully picked to ensure we use the most environmentally responsible packaging.
                    The use lightweight, recycled content materials. Our shipping boxes are made from 100% environment friendly corrugated card.
                    Instead of excessive plastic bubble wrap and plastic air pillows, we use 100 percent recycled-content shredded paper that
                    is easily recyclable or biodegradable plant-based packing used to protect the glass for shipments.
                </p>

                <div className=' text-lg font-semibold pt-20 title'>No Artificial Agriculture :</div>
                <p className='pt-3'>Agriculture is at the core of The Herb key operations. All our ingredients are grown in a natural environment and are ethically
                    harvested. Our parts of our products are meticulously analysed in our Quality Control to ensure they are free from synthetic
                    chemicals.</p>

                <div className=' text-lg font-semibold pt-20 title'>Why use natural herbal skincare products?</div>
                <p className='pt-3'>Herbal skincare products are usually gentle on the skin because they are made of natural ingredients. The risk of causing break-
                    outs and skin irritations is relatively less. They are the best treat to nurture your skin with. An added bonus is going natural and
                    herbal is beneficial for the environment too.</p>


                <div className=' text-lg font-semibold pt-20 title'>Working towards a beautiful tomorrow:</div>
                <p className='pt-3'>
                    We endeavour to be environmentally sustainable, improving the quality of life of people around us while also giving back to the
                    community we live in. We are working towards being plastic free brand and incorporate different ranges of glassware to serve the
                    purpose. All ingredients are produced in a safe and biodegradable environment. Our company follows the reduction, reclamation
                    and recycling path and only use what we need reducing wastage. Currently we are at 95% plastic free status and are striving to
                    achieve 100 %.
                </p>
                <p className='pt-3'>
                    Our waste management process is unwaveringly committed to the environment and we are constantly rethink and redesigning
                    our method of production to increase more resource recovery and recycling making us more in sync with nature.
                </p>

                <div className=' text-lg font-semibold pt-20 title'>Our upcoming Plan :</div>
                <p className='pt-3'>
                    Supporting the farming community is our long-term goal as part of our Pay it forward initiative. Farming is at the core of our
                    roots and is an underappreciated community. We plan to donate 5% of our annual sales to the farming community and intend to
                    help the community via loans, kid's education and support for medical bills.
                </p>
                <p className='pt-3'>
                    You can also sponsor farmers though financial contributions and in-kind donations and help via our volunteer programs. This in-
                    cludes programs to support home herbalists and small farmers.
                </p>
                <p className='pt-3'>
                    Our Fair-trade policy ensures that we give all farmers a fair market value for the goods they produce. We have discontinued items
                    and refused to carry particular products because laborers were not given an equal voice, a fair wage, or because we felt sensitive
                    bioregions were being compromised.
                </p>

                <div className=' text-lg font-semibold pt-20 title'>Volunteer Programs :</div>
                <p className='pt-3'>
                    The Herb Key offers to everyone to join hands with our local farming communities as a volunteer at least 2 times in a year and
                    get in touch with mother nature. You will be able to learn farming with farmers, environmental cleanliness, and planting trees in
                    surrounding of your choosing for 48 hours along with food and accommodation. Our intention is to help you get a relaxing envi-
                    ronment to de-stress as well as helping the community with your work and build a better understanding of the lives of farmers.
                </p>
                <div className=' text-lg font-semibold pt-20 title'>Every little drop in the ocean counts and each one of you is beautiful inside and outside</div>
                <p className='pt-3'> BEAUTIFUL YOU = THE MORE CONFIDENT YOU</p>
            </div>
            <Footer/>
        </>
    )
}

export default PrivacyPolicy