import { createSlice } from "@reduxjs/toolkit";

const AddressSlice = createSlice({
  name: "addressDetails",
  initialState: {
    loading: false,
    addressData: [],
  },
  reducers: {
    AddressRequest(state, action) {
      return {
        loading: true,
      };
    },
    AddressSuccess(state, action) {
      return {
        loading: false,
        addressData: action.payload.addressData,
      };
    },
    AddressFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = AddressSlice;

export const {
    AddressRequest,
    AddressSuccess,
    AddressFail
} = actions;

export default reducer;
