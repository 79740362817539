import { createSlice } from "@reduxjs/toolkit";

const bannerSlice = createSlice({
    name: "bannerSlice",
    initialState: {
        loading: false,
        pcBanner: [],
        tabBanner: [],
        mobileBanner: [],
    },
    reducers: {
        bannerRequest(state, action) {
            return {
                loading: true,
            }
        },
        bannerSuccess(state, action) {
            return {
                loading: false,
                pcBanner: action.payload.pcBanner,
                tabBanner: action.payload.tabBanner,
                mobileBanner: action.payload.mobileBanner,
            }
        },
        bannerFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    },

});

const { actions, reducer } = bannerSlice;

export const { bannerRequest, bannerSuccess, bannerFail } = actions;

export default reducer;
