import React, { useEffect } from 'react'
import TopHeader from '../components/TopHeader';
import Header from '../components/Header';
import Footer from '../components/Footer/Footer';

const Return = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <>
            
            <Header />
            <div className='py-4 px-8 sm:px-16 md:px-20 lg:px-28 container mx-auto'>
                <h4 className='text-lg sm:text-lg md:text-xl font-bold'>Return Policy</h4>
                <div className='pt-2 sm:pt-3 md:pt-4 '>
                    <div>The Herb Key takes utmost pride and honour in our range of self-care products to. We can vouch 100% for the purity of our products and our packaging is done with transit in mind to avoid any damage.</div>
                    <div className='pt-2'>Keeping in mind hygienic reasons for skincare products, we do not accept any requests for RETURN. We can only provide replacements or delivery of a new order based on the complaint flagged. You are requested to go through the HOW TO USE INSTRUCTIONS and the ingredient list before any usage. No complaints due to negligence or incorrect application will be entertained.</div>
                    <div className='pt-2'>We also strongly recommend doing a patch test before any application. Any exchange or return of product due to allergies or skin irritations will not be entertained. </div>
                    <div className='pt-2'>Kindly note that some raw materials used may have altered due to seasonal harvest or climate and may be subject to slight variation in the colour or smell of the product. This has no impact on the performance of the product and will not warrant any return or replacement request. </div>
                    <div className='pt-2'>We hope you give our products a chance in good faith. If you have any questions or concerns, feel free to contact us atsales@theherbkey.com. Your feedback and opinion matter to us and we welcome any room for improvement.</div>    
                </div>

                <h4 className='text-lg sm:text-lg md:text-xl font-bold mt-4 sm:mt-5 md:mt-6'>Cancellation policy </h4>
                <div className='pt-2 sm:pt-3 md:pt-4 '>
                    <div>At The Herb Key, we understand that you can have a change of heart even after placing an order. As we dispatch parcels on the same day of receiving your request, if you choose to cancel your order, you should do so within 2 hours of ordering.</div>
                    <div className='pt-2'>To Note</div>
                    <div>Keeping in mind hygienic reasons for skincare products, we do not accept any requests for RETURN. We can only provide replacements or delivery of a new order based on the complaint flagged. You are requested to go through the HOW TO USE INSTRUCTIONS and the ingredient list before any usage. No complaints due to negligence or incorrect application will be entertained.</div>
                </div>

                
                <h4 className='text-lg sm:text-lg md:text-xl font-bold mt-4 sm:mt-5 md:mt-6'>How to cancel my order?</h4>
                <div className='pt-2 sm:pt-3 md:pt-4 '>
                    <div>In case you want to change of your order, send us an email onsales@theherbkey.com within 2 hours of placing the order. Please note that any cancellation would be charged with 5% of the total order value as a cancellation fee. After deduction, the rest of the amount will be refunded to the original payment method.</div>
                    <div className='pt-2'>In rare cases, a product may be an out-of-stock in which case your entire money will be refunded back to the original payment method, and you will be intimated through mail.</div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Return;
