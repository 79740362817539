import axios from "axios";
import { backendUrl } from "../config/config";
import { btsFail, btsRequest, btsSuccess } from "../slices/BtsSlice";
import { SpecialProductFail, SpecialProductRequest, SpecialProductSuccess } from "../slices/SpecialProduct";

export const getBts =  async (dispatch) => {
    try {
        dispatch(btsRequest())
        const { data } = await axios.get(backendUrl + `get-bts`)
        dispatch(btsSuccess(data))
    } catch (error) {
        dispatch(btsFail(error.response.data.message));
    }
}


export const getSpecialProduct =  async (dispatch) => {
    try {
        dispatch(SpecialProductRequest())
        const { data } = await axios.get(backendUrl + `get-special-product`)
        dispatch(SpecialProductSuccess(data))
    } catch (error) {
        dispatch(SpecialProductFail(error.response.data.message));
    }
}