import { toast } from "react-toastify";
import { cartFail, cartRequest, cartSuccess } from "../slices/GetCart";

export const saveCartToLocalStorage = (cart) => {
  localStorage.setItem("cart", JSON.stringify(cart));
};

export const getCartFromLocalStorage = () => {
  const cartData = localStorage.getItem("cart");
  return cartData ? JSON.parse(cartData) : [];
};

export const getCarts = async (dispatch) => {
  try {
    dispatch(cartRequest());
    const cartData = localStorage.getItem("cart");
    const data = cartData ? JSON.parse(cartData) : [];
    dispatch(cartSuccess(data));
  } catch (error) {
    dispatch(cartFail(error.response.data.message));
  }
};

export const removeSingleCart =
  (productId, variationId) => async (dispatch) => {
    try {
      dispatch(cartRequest());
      const cartData = localStorage.getItem("cart");

      const cartArray = JSON.parse(cartData || "[]"); // Provide a default empty array if cartData is null or undefined

      const filteredCartArray = cartArray.reduce((accumulator, item) => {
        if (item.id !== productId || item.variationId !== variationId) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);

      const filteredCartData = JSON.stringify(filteredCartArray);
      localStorage.setItem("cart", filteredCartData);

      const newData = localStorage.getItem("cart");

      const data = newData ? JSON.parse(newData) : [];
      if (data.length == 0) {
        document.body.classList.remove("cart_popup");
      }
      dispatch(cartSuccess(data));
    } catch (error) {
      dispatch(cartFail(error.response.data.message));
    }
  };

export const incrementSingleCart =
  (ProductId, variationId, quantity) => async (dispatch) => {
    try {
      dispatch(cartRequest());

      const cartData = localStorage.getItem("cart");
      const cartArray = JSON.parse(cartData || "[]"); // Default to an empty array if no data is found

      // Step 3: Find and update the quantity of the item
      const updatedCartArray = cartArray.map((item) => {
        if (item.id === ProductId && item.variationId === variationId) {
          // Increment the quantity by 1
          if (quantity === "increment") {
            // Increment the quantity by 1
            return { ...item, quantity: item.quantity + 1 };
          } else if (quantity === "decrement" && item.quantity > 0) {
            // Decrement the quantity by 1 if it's greater than 0
            return { ...item, quantity: item.quantity - 1 };
          }
        }
        return item;
      });

      // Step 4: Save the updated cart data back to local storage
      localStorage.setItem("cart", JSON.stringify(updatedCartArray));
      const newData = localStorage.getItem("cart");
      const data = newData ? JSON.parse(newData) : [];

      dispatch(cartSuccess(data));
    } catch (error) {
      dispatch(cartFail(error.response.data.message));
    }
  };

export const addSingleCart =
  ({ sendData: carts }) =>
  async (dispatch) => {
    try {
      dispatch(cartRequest());
      // Step 1: Retrieve the cart data from local storage
      const cartData = localStorage.getItem("cart");
      const cartArray = JSON.parse(cartData || "[]"); // Default to an empty array if no data is found

      // Step 3: Find if an item with the same product ID and variation ID already exists
      let itemExists = false;
      const updatedCartArray = cartArray.map((item) => {
        if (item.id === carts?.id && item.variationId === carts?.variationId) {
          // If the item exists, increment the quantity by the specified amount
          itemExists = true;
          return { ...item, quantity: item.quantity + carts?.quantity };
        }
        return item;
      });

      // Step 4: If the item does not exist, add it as a new item
      if (!itemExists) {
        updatedCartArray.push(carts);
      }
      // Step 5: Save the updated cart data back to local storage
      localStorage.setItem("cart", JSON.stringify(updatedCartArray));

      const newData = localStorage.getItem("cart");
      const data = newData ? JSON.parse(newData) : [];
      dispatch(cartSuccess(data));
      if (data) {
        toast.success(`${carts?.title} added to your Cart!`, {
          position: "top-right",
          autoClose: 2000, // Auto-close the toast after 3 seconds
        });
      }
    } catch (error) {
      dispatch(cartFail(error.response.data.message));
    }
  };
