import React, { useEffect } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import SingleProductDetails from "../components/SingleProduct/SingleProductDetails";
import Details from "../components/SingleProduct/Details";
import Component from "../components/BestSellers/Component";
import CustomerReviews from "../components/Common/CustomerReviews";
import Footer from "../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProductBySlug } from "../actions/productActions";
import Loader from "../components/Loader";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { getCategory } from "../actions/categoryActions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SingleProduct = () => {
  const { loading, ReviewAverage,product, product_images, category, suggestProducts ,productReviews ,productDesign } =
    useSelector((state) => state.productBySlugState);

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getProductBySlug(slug));
  }, []);

  const handleNavLinkClick = (slug) => {
    dispatch(getCategory(slug));
  };
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      suggestProducts && suggestProducts.length >= 4
        ? 4
        : suggestProducts && suggestProducts.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow:
            suggestProducts && suggestProducts.length >= 3
              ? 3
              : suggestProducts && suggestProducts.length,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow:
            suggestProducts && suggestProducts.length >= 3
              ? 3
              : suggestProducts && suggestProducts.length,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow:
            suggestProducts && suggestProducts.length >= 2
              ? 2
              : suggestProducts && suggestProducts.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            suggestProducts && suggestProducts.length >= 1
              ? 1
              : suggestProducts && suggestProducts.length
        },
      },
    ],
    
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          
          <Header />
          <div className=" container mx-auto">
            <div>
              <div className="flex gap-x-2 items-center text-[13px]">
                <span className="opacity-50 flex items-center">
                  <Link to="/">Home</Link>{" "}
                  <span className="opacity-50">
                    <IoIosArrowForward size={16} />
                  </span>
                </span>
                <span className=" opacity-50 flex items-center">
                  <Link
                    onClick={() =>
                      handleNavLinkClick(category && category?.slug)
                    }
                    to={`/category/${category && category?.slug}`}
                  >
                    {category && category?.name}
                  </Link>{" "}
                  <span className="opacity-50">
                    <IoIosArrowForward size={16} />
                  </span>
                </span>
                <span className="opacity-100 font-medium">
                  {product && product?.title}
                </span>
              </div>
            </div>
            <SingleProductDetails
              product={product && product}
              product_images={product_images && product_images}
              productReviews ={productReviews}
            />
            <Details product={product && product} productDesign={productDesign && productDesign}/>
            {suggestProducts && suggestProducts.length > 0 && (
              <div className="mt-10">
                <div className=" font-semibold text-2xl text-center century_gothic">
                  You May also Like
                </div>

                <div className="mt-8 md:mt-10 px-10 sm:px-14  md:px-28 gap-y-4 relative suggest_slider">
                  <Slider {...carouselSettings}>
                    {suggestProducts.map((products) => (
                      <Component
                        key={products.id}
                        productObject={product}
                        productId={products.id}
                        pro_slug={products.slug}
                        name={products.title}
                        pro_name={products.short_desc}
                        stars={5}
                        img_link={products.product_image}
                        variations={products.variation}
                        wishlistcheck = {products?.wishlist}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            )}

            <CustomerReviews id={product && product?.id} productReviews={productReviews} ReviewAverage={ReviewAverage} />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="text-3xl font-semibold absolute top-1/2 left-5 -translate-y-1/2 cursor-pointer z-10"
    >
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-3xl font-semibold absolute top-1/2 right-5 -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );
};

export default SingleProduct;
