import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: [], // An array to hold the items in the cart
};

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
      cart: [],
    },
    reducers: {
      addToCart: (state, action) => {
        const itemInCart = state.cart.find((item) => item.id === action.payload.id &&  item.variationId === action.payload.variationId);
        if (itemInCart) {
          itemInCart.quantity++;
        } else {
          state.cart.push({ ...action.payload, quantity: 1 });
        }
      },
      incrementQuantity: (state, action) => {
        const item = state.cart.find((item) => item.id === action.payload &&  item.variationId === action.payload.variationId);
        item.quantity++;
      },
      decrementQuantity: (state, action) => {
        const item = state.cart.find((item) => item.id === action.payload &&  item.variationId === action.payload.variationId);
        if (item.quantity === 1) {
          item.quantity = 1
        } else {
          item.quantity--;
        }
      },
      removeItem: (state, action) => {
        const removeItem = state.cart.filter((item) => item.id !== action.payload &&  item.variationId !== action.payload.variationId);
        state.cart = removeItem;
      },
    },
  });

export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
} = cartSlice.actions;

export default cartSlice.reducer;
