import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import validator from "validator";
import axios from "axios";
import { backendImageUrl, backendUrl } from "../config/config";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const SubscribePopup = ({ isOpen, onRequestClose, ModalData }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setFormData({
        email: "",
      });
    }
  }, [isOpen]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, submit the data or perform further actions
      setLoading(true);
      setErrors({});
      axios
        .post(backendUrl + "subscribe-mail", formData)
        .then((res) => {
            setLoading(false);
            if(res?.status === 200){
                onRequestClose();
                toast.success("Your Subscription has been sent!");
            }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong , Please try again !", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        });
    } else {
      // Form has errors, display them
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    // Validate the name
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is not valid";
    }

    return errors;
  };
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <div className=" flex justify-between items-center gap-x-7 gap-y-3 flex-wrap sm:flex-nowrap py-8 px-4 lg:px-6 bg-white relative rounded-[20px]">
        <div className="w-full sm:w-[30%]">
          <img
            src={backendImageUrl + ModalData?.image}
            alt="image"
            className="w-[155px] h-[auto] mx-auto"
          />
        </div>
        <div className="w-full sm:w-[70%]">
          <h3 className=" text-lg sm:text-lg lg:text-xl xl:text-2xl font-medium">
            {ModalData?.title}
          </h3>
          <form className=" mt-4 md:mt-5" onSubmit={handleSubmit}>
            <div className=" flex gap-x-1 items-stretch">
              <input
                type="text"
                placeholder="YOUR EMAIL ADDRESS.."
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-[70%] bg-[#ebeff8] px-2 text-sm placeholder:text-[10px] py-1 rounded-lg"
              />
              {errors?.password && (
                <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                  {errors?.password}
                </p>
              )}
              <button
                className={`capitalize text-white font-semibold py-1 bg-[#ff8a00] px-2 rounded-lg ${
                  loading ? " pointer-events-none cursor-none opacity-60" : ""
                }`}
              >
                Subscribe
              </button>
            </div>
            {errors?.email && (
              <p className="bg-red-500 mt-2 rounded-md text-white py-1 text-xs px-2">
                {errors?.email}
              </p>
            )}
            <div className=" flex items-center gap-x-2 mt-3 text-xs font-medium">
              <input
                type="checkbox"
                className=" h-[11px] accent-[#9aacf8b3] sub_check"
                id="show"
              />
              <label htmlFor="show">Do not show popup anymore</label>
            </div>
            <div className=""></div>
          </form>
        </div>
        <span
          onClick={onRequestClose}
          className="absolute bg-white -top-[2%] sm:-top-[3%] border-red-700 border-2 rounded-full  -right-[7px] sm:-right-[1%]  cursor-pointer"
        >
          <IoClose className="w-[25px] h-[25px] text-red-700" />
        </span>
        {/* <img
          src={close}
          alt="close"
          className=" absolute -top-[7%] sm:-top-[9%] -right-[19px] sm:-right-[4%] w-[55px] h-[55PX] cursor-pointer"
          onClick={onRequestClose}
        /> */}
      </div>
    </Modal>
  );
};

export default SubscribePopup;
