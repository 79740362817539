import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import validator from "validator";
import { backendUrl } from "../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaEye , FaEyeSlash } from "react-icons/fa";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const RegisterPopup = ({
  isOpen,
  onRequestClose,
  currentPage = false,
  setHideLogin,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [eyesOpen, setEyesOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setFormData({
        name: "",
        email: "",
        password: "",
      });
    }
  }, [isOpen]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      // Form is valid, submit the data or perform further actions
      setErrors({});
      axios
        .post(backendUrl + "register", formData)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            localStorage.setItem("auth_Token", res?.data?.token);
            onRequestClose();
            if (currentPage == false) {
              navigate("/user-dashboard");
              localStorage.setItem("userReload", 1);
            } else {
              setHideLogin(true);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 400) {
            // Handle the 400 Bad Request error here
            setErrors({
              name: err?.response?.data?.errors?.name,
              email: err?.response?.data?.errors?.email,
              password: err?.response?.data?.errors?.password,
            });
          }
        });
    } else {
      // Form has errors, display them
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    // Validate the username
    if (validator.isEmpty(data.name)) {
      errors.name = "Username is required";
    }

    // Validate the email
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is not valid";
    }

    // Validate the password
    if (validator.isEmpty(data.password)) {
      errors.password = "Password is a required";
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    return errors;
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <h3 className=" py-2 sm:py-3 md:py-4 px-3 text-xl font-bold text-white bg-[#4f7661] text-center">
        CREATE ACCOUNT
      </h3>
      <div className="py-2 sm:py-2 md:py-8 px-3 sm:px-4 md:px-8 bg-white ">
        {/* <div className=" flex gap-x-5 justify-center items-center register_icons">
          <span className=" text-[#4f7661]">
            <BsTelephoneFill className="text-[18px] sm:text-[19px] md:text-[25px]" />
          </span>
          <span className=" text-[#dc4c39]">
            <BsGoogle className=" text-[18px] sm:text-[19px] md:text-[25px]" />
          </span>
          <span className=" text-[#3a5999]">
            <FaFacebookF className=" text-[18px] sm:text-[19px] md:text-[25px]" />
          </span>
          <span className=" text-[#55acee]">
            <FaTwitter className=" text-[18px] sm:text-[19px] md:text-[25px]" />
          </span>
          <span className=" text-[#007bb6]">
            <BiLogoLinkedin className=" text-[20px] sm:text-[23px] md:text-[28px]" />
          </span>
        </div> */}
        <div className=" text-lg text-center">USE EMAIL FOR REGISTRATION</div>
        <div className="py-2 sm:py-3 md:py-4 w-full sm:w-[90%] md:w-[80%] mx-auto ">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="name"
              className=" bg-[#ebeff8] px-3 py-2 rounded-lg w-full focus-within:outline-0"
              placeholder="USER NAME:"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors?.name && (
              <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                {errors?.name}
              </p>
            )}
            {/* {backendErrors?.errorList?.name &&<p className='bg-red-500 mt-2 rounded-md text-white py-2 px-2'>{backendErrors?.errorList?.name}</p>} */}
            <input
              type="text"
              id="email"
              className=" bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-3 focus-within:outline-0"
              placeholder="EMAIL ID:"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors?.email && (
              <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                {errors?.email}
              </p>
            )}
            {/* {backendErrors?.errorList?.email &&<p className='bg-red-500 mt-2 rounded-md text-white py-2 px-2'>{backendErrors?.errorList?.email}</p>} */}
            <div className="relative mt-3">
              <input
                type={eyesOpen ? "text" : "password"}
                name="password"
                id="password"
                className=" bg-[#ebeff8] px-3 py-2 rounded-lg w-full focus-within:outline-0"
                placeholder="PASSWORD:"
                value={formData.password}
                onChange={handleChange}
              />
              {eyesOpen ? (
                <FaEye
                  className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
                  onClick={() => setEyesOpen(false)}
                />
              ) : (
                <FaEyeSlash
                  className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
                  onClick={() => setEyesOpen(true)}
                />
              )}
            </div>
            {errors?.password && (
              <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                {errors?.password}
              </p>
            )}
            {/* {backendErrors?.errorList?.password &&<p className='bg-red-500 mt-2 rounded-md text-white py-2 px-2'>{backendErrors?.errorList?.password}</p>} */}
            <div className=" mt-2 sm:mt-2 md:mt-3 text-center">
              <button
                type="submit"
                className={`px-8 py-1.5 bg-[#ff8a00] rounded-xl text-white inline-block uppercase font-medium mt-0 sm:mt-0 md:mt-2 ${
                  loading ? " pointer-events-none cursor-none opacity-60" : ""
                }`}
              >
                SIGN UP
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterPopup;
