import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import validator from "validator";
import { backendUrl } from "../../config/config";
import axios from "axios";
import { toast } from "react-toastify";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const ForgetPassword = ({
  isOpen,
  onRequestClose,
  currentPage = false,
  setHideLogin,
}) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setFormData({
        email: "",
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      // Form is valid, submit the data or perform further actions
      setErrors({});
      try {
        const response = await axios.post(backendUrl + `forgotpassword`,{email:formData.email});
        if (response?.data?.status === 1) {
          setLoading(false);
          onRequestClose();
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        } else {
          setLoading(false);
          toast.warning(response?.data?.message, {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong , Please try again", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
    } else {
      setLoading(false);
      // Form has errors, display them
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};
    // Validate the email
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is not valid";
    }
    return errors;
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <h3 className=" py-2 sm:py-3 md:py-4 px-3 text-xl font-bold text-white bg-[#4f7661] text-center">
        FORGOT PASSWORD
      </h3>
      <div className="py-2 sm:py-2 md:py-8 px-3 sm:px-4 md:px-8 bg-white ">
        <div className="py-2 sm:py-3 md:py-4 w-full sm:w-[90%] md:w-[80%] mx-auto ">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              id="email"
              className=" bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-3 focus-within:outline-0"
              placeholder="EMAIL ID:"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors?.email && (
              <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                {errors?.email}
              </p>
            )}
            <div className=" mt-2 sm:mt-2 md:mt-3 text-center">
              <button
                type="submit"
                className={`px-8 py-1.5 bg-[#ff8a00] rounded-xl text-white inline-block uppercase font-medium mt-0 sm:mt-0 md:mt-2 ${
                  loading ? " pointer-events-none cursor-none opacity-60" : ""
                }`}
              >
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ForgetPassword;
