import React from "react";

const Component = ({ name, desc, img_link }) => {
  return (
    <div className="px-6 md:px-8">
      <div className="dd">
        <img
          src={img_link}
          alt="img"
          className="w-[180px] h-[180px] object-contain mx-auto"
        />
      </div>
      <div>
        <div className="text-xl">{name}</div>
        <p className="text-[13px] pt-2">{desc}</p>
      </div>
    </div>
  );
};

export default Component;
