import { createSlice } from "@reduxjs/toolkit";

const getCartSlice = createSlice({
    name: "getCart",
    initialState: {
        loading: false,
        CartData:[],
    },
    reducers: {
        cartRequest(state, action) {
            return {
                loading: true,
            }
        },
        cartSuccess(state, action) {
            return {
                loading: false,
                CartData: action.payload,
            }
        },
        cartFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = getCartSlice;

export const { cartRequest, cartSuccess, cartFail } = actions;

export default reducer;