import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { GrFormAttachment } from "react-icons/gr";
import { IoMdArrowDropdown } from "react-icons/io";
import validator from "validator";
import axios from "axios";
import { backendUrl } from "../../config/config";
import { toast } from "react-toastify";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
    height: "100vh",
    minHeight: "100vh",
  },
};

// Component for displaying the video inside the popup
const ContactUsPopUp = ({ isOpen, onRequestClose, checkout_number }) => {
  const [reason, setreason] = useState("");
  const [reasonToggle, setreasonToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    reason: reason,
    message: "",
    attachment_link: "",
    checkout_number,
  };
  const [formData, setFormData] = useState(initialFormData);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(initialFormData);
    setreasonToggle(false);
    setErrors({});
    setreason("");
  }, [isOpen]);
  const handleChange = (e) => {
    if (e.target.name === "attachment_link") {
      // Handle file input separately
      const file = e.target.files[0];
      // Additional file validation logic if needed
      setFormData({
        ...formData,
        [e.target.name]: file,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validation logic
    const validationErrors = {};

    if (validator.isEmpty(formData.first_name)) {
      validationErrors.first_name = "First name is required";
    }

    if (validator.isEmpty(formData.last_name)) {
      validationErrors.last_name = "Last name is required";
    }

    if (!validator.isEmail(formData.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (validator.isEmpty(formData.contact_number)) {
      validationErrors.contact_number = "Contact Number is required";
    } else if (!validator.isNumeric(formData.contact_number)) {
      // Check if contact_number is not a valid number
      validationErrors.contact_number = "Contact Number must be a valid number";
    }
    if (reason === "") {
      validationErrors.reason = "Reason is required";
    }
    if (validator.isEmpty(formData.message)) {
      validationErrors.message = "Message is required";
    }

    // Add similar validations for other fields (contact_number, reason, message, attachment_link)

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission logic
      setFormData({ ...formData, reason: reason });
      setErrors({});
      const formDatas = new FormData();
      formDatas.append("first_name", formData.first_name);
      formDatas.append("last_name", formData.last_name);
      formDatas.append("email", formData.email);
      formDatas.append("contact_number", formData.contact_number);
      formDatas.append("reason", reason);
      formDatas.append("message", formData.message);
      formDatas.append("attachment_link", formData.attachment_link);
      formDatas.append("checkout_number", formData.checkout_number);
      try {
        const response = await axios.post(
          backendUrl + "my-orders-contact-us",
          formDatas
        );
        // Handle success, redirect, or update UI as needed
        if (response.data.status === 1) {
          toast.success("Mail send successfully!");
        }
        setLoading(false);
        setFormData(initialFormData);
        onRequestClose();
      } catch (error) {
        if (error?.response?.status === 422) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong , Please try again !");
        }
        // Handle error and update UI accordingly
        setLoading(false);
      }
    } else {
      // Update state with validation errors
      setErrors(validationErrors);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <div className="py-2 sm:py-2 md:py-3 px-2 sm:px-2 md:px-3 bg-white rounded-xl w-full relative">
        <div className="text-center font-medium">
          <div className="text-lg sm:text-lg md:text-xl">
            Contact Us For Change
          </div>
          <div className="text-xs sm:text-xs md:text-sm">
            Your satisfaction is our 1st priority
          </div>
        </div>
        <form
          className="mt-3 sm:mt-3 md:mt-5"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className=" text-sm font-medium">Name *</div>
          <div className="flex justify-center align-center gap-x-3 gap-y-3 flex-wrap sm:flex-wrap md:flex-nowrap">
            <div className="w-full sm:w-full md:w-1/2">
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="First Name"
              />
              {errors.first_name && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.first_name}
                </p>
              )}
            </div>
            <div className="w-full sm:w-full md:w-1/2">
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="Last Name"
              />
              {errors.last_name && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.last_name}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center align-center gap-x-3 gap-y-3 flex-wrap sm:flex-wrap md:flex-nowrap pt-2 sm:pt-2 md:pt-3">
            <div className="w-full sm:w-full md:w-1/2 flex flex-col">
              <div className=" text-sm font-medium">Email *</div>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="Yourmailid@xxx.xom"
              />
              {errors.email && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.email}
                </p>
              )}
            </div>
            <div className="w-full sm:w-full md:w-1/2">
              <div className=" text-sm font-medium">Contact Number *</div>
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="1234567890"
              />
              {errors.contact_number && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.contact_number}
                </p>
              )}
            </div>
          </div>
          <div className="w-full pt-2 sm:pt-2 md:pt-3 font-semibold">
            <div className=" text-sm font-medium">Reason *</div>
            <div
              onClick={() => setreasonToggle(!reasonToggle)}
              className={`text-black relative cursor-pointer  rounded-md text-xs px-3 py-2 relative ${
                reasonToggle
                  ? "bg-[#5f8170] rounded-b-none border-2 border-[#b3c4fa] border-b-0"
                  : "bg-[#edf1f0]"
              }`}
            >
              {reason !== "" ? reason : "Select a Reason"}
              <span
                className={`absolute top-1/2 right-2 -translate-y-1/2 ${
                  reasonToggle ? "text-white" : ""
                }`}
              >
                <IoMdArrowDropdown size={20} />
              </span>
            </div>
            {reasonToggle && (
              <ul
                className={`px-3 pt-1  bg-[#ebeff8] text-xs pb-2 rounded-b-md ${
                  reasonToggle ? "border-2 border-[#b3c4fa] border-t-0" : ""
                }`}
              >
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason(
                      "Cancel Order (Can do within 6 hours  of ordering)"
                    );
                  }}
                >
                  Cancel Order (Can do within 6 hours of ordering)
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason(
                      "Change The Shipping Address ( Can do within 6 hours of ordering )"
                    );
                  }}
                >
                  Change The Shipping Address ( Can do within 6 hours of
                  ordering )
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("About Order / Shipping");
                  }}
                >
                  About Order / Shipping
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Product Related");
                  }}
                >
                  Product Related
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Others");
                  }}
                >
                  Others
                </li>
              </ul>
            )}
            {errors.reason && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.reason}
              </p>
            )}
          </div>
          <div className="w-full pt-2 sm:pt-2 md:pt-3">
            <div className=" text-sm font-medium">Message *</div>
            <textarea
              cols={20}
              rows={3}
              name="message"
              onChange={handleChange}
              className="bg-[#edf1f0] resize-none text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            >
              {formData.message}
            </textarea>
            {errors.message && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.message}
              </p>
            )}
          </div>
          <div className="pt-2 sm:pt-2 md:pt-3">
            <div className=" text-sm font-medium">
              Attachment File (Optional)
            </div>
            <div className="relative">
              <GrFormAttachment
                size={25}
                className="absolute left-1 top-1/2 -translate-y-1/2"
              />
              <input
                type="file"
                name="attachment_link"
                onChange={handleChange}
                className="bg-[#edf1f0] text-sm rounded-md pl-7 pr-2 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="You can upload the files on your drive and paste the link here"
              />
            </div>
            {errors.attachment_link && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.attachment_link}
              </p>
            )}
          </div>
          <div className="flex justify-center align-center pt-2">
            <button
              className={`px-3 sm:px-3 md:px-5 lg:px-6 py-2 text-center text-white font-medium rounded-xl bg-blue-700 ${
                loading ? " opacity-50 cursor-none pointer-events-none" : ""
              }`}
            >
              Submit
            </button>
          </div>
        </form>
        <span
          onClick={onRequestClose}
          className="absolute -top-2 -right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
        >
          <FaTimes size={18} />
        </span>
      </div>
    </Modal>
  );
};

export default ContactUsPopUp;
