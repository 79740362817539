import React, { useEffect, useState } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import ProductDesignCarousal from "./ProductDesignCarousal";

const Details = ({ product, productDesign }) => {
  const [horizontalOpen, setHorizontalOpen] = useState("star_goodness");
  const [verticalOpen, setVerticalOpen] = useState({
    name: "",
    value: false,
  });
  // useEffect(() => {
  //   // if (product && product?.star_goodness.trim() !== "") {
  //   //   setHorizontalOpen("star_goodness");
  //   // } else if (product && product?.why_us.trim() !== "") {
  //   //   setHorizontalOpen("why_us");
  //   // } else {
  //   //   setHorizontalOpen("how_to_use");
  //   // }

  //   // if (product && product?.product_details.trim() !== "") {
  //   //   setVerticalOpen("product_details");
  //   // } else if (product && product?.product_details.trim() !== "") {
  //   //   setVerticalOpen("disclaimer");
  //   // }
  // }, []);

  return (
    <div className="mt-10 bg-[rgb(231,231,231)] rounded-md py-6 text-[#4a594b] arial_font">
      {productDesign && (
        <>
          <div className="px-10 sm:px-24 md:px-40">
            <div className="flex flex-wrap md:flex-nowrap text-center gap-y-4 gap-x-10  text-2xl font-semibold justify-center">
              <span
                className={`${
                  horizontalOpen === "star_goodness" ? "" : "opacity-70"
                } cursor-pointer`}
                onClick={() => setHorizontalOpen("star_goodness")}
              >
                Star Goodness
              </span>
              <span
                className={`${
                  horizontalOpen === "why_us" ? "" : "opacity-70"
                } cursor-pointer`}
                onClick={() => setHorizontalOpen("why_us")}
              >
                Why Us
              </span>
              <span
                className={`${
                  horizontalOpen === "how_to_use" ? "" : "opacity-70"
                } cursor-pointer`}
                onClick={() => setHorizontalOpen("how_to_use")}
              >
                How To Use
              </span>
            </div>
            {productDesign && productDesign?.star_goodness_status === 1 ? (
              <>
                {horizontalOpen === "star_goodness" && (
                  <div
                    className={`mt-6 text-lg font-medium ${
                      horizontalOpen === "star_goodness" ? "block" : "hidden"
                    }`}
                  >
                    {productDesign?.sg_text}
                  </div>
                )}
              </>
            ) : (
              <>
                {horizontalOpen === "star_goodness" && (
                  <div className={`mt-6 text-lg font-medium`}>
                    <ProductDesignCarousal
                      carousalArray={productDesign?.sg_carousal}
                    />
                  </div>
                )}
              </>
            )}
            {productDesign && productDesign?.why_us_status === 1 ? (
              <>
                {horizontalOpen === "why_us" && (
                  <div className={`mt-6 text-lg font-medium`}>
                    {productDesign?.why_us_text}
                  </div>
                )}
              </>
            ) : (
              <>
                {horizontalOpen === "why_us" && (
                  <div className={`mt-6 text-lg font-medium`}>
                    <ProductDesignCarousal
                      carousalArray={productDesign?.why_us_carousal}
                    />
                  </div>
                )}
              </>
            )}
            {productDesign && productDesign?.how_status === 1 ? (
              <>
                {horizontalOpen === "how_to_use" && (
                  <div className={`mt-6 text-lg font-medium`}>
                    {productDesign?.how_text}
                  </div>
                )}
              </>
            ) : (
              <>
                {horizontalOpen === "how_to_use" && (
                  <div className={`mt-6 text-lg font-medium`}>
                    <ProductDesignCarousal
                      carousalArray={productDesign?.how_carousal}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <div className="px-10 sm:px-24 md:px-40 mt-6 text-2xl flex flex-col font-semibold gap-y-3">
        {product && product?.product_details && product?.product_details.trim() !== "" && (
          <div>
          <span
            className={`flex items-center gap-x-2 max-w-fit min-w-[210px] justify-between ${
              verticalOpen === "product_details" ? "" : "opacity-70"
            } cursor-pointer`}
            onClick={() =>
              setVerticalOpen({
                name: "product_details",
                value: !verticalOpen.value,
              })
            }
          >
            Product Details{" "}
            <span
              className={`${
                verticalOpen.name === "product_details" && verticalOpen.value
                  ? "rotate-90"
                  : ""
              }`}
            >
              <MdOutlineArrowForwardIos />
            </span>
          </span>
          </div>
        )}
        {product && product?.product_details &&product?.product_details.trim() !== "" && (
          <div
            className={`mt-2 text-lg font-medium pl-10 ${
              verticalOpen.name === "product_details" && verticalOpen.value
                ? "block"
                : "hidden"
            }`}
            dangerouslySetInnerHTML={{
              __html: product && product?.product_details,
            }}
          ></div>
        )}
        {product && product?.disclaimer && product?.disclaimer.trim() !== "" && (
          <div>
          <span
            className={`flex items-center gap-x-2 max-w-fit min-w-[210px] justify-between ${
              verticalOpen === "disclaimer" ? "" : "opacity-70"
            } cursor-pointer`}
            onClick={() =>
              setVerticalOpen({
                name: "disclaimer",
                value: !verticalOpen.value,
              })
            }
          >
            Disclaimer{" "}
            <span
              className={`${
                verticalOpen.name === "disclaimer" && verticalOpen.value
                  ? "rotate-90"
                  : ""
              }`}
            >
              <MdOutlineArrowForwardIos />
            </span>
          </span>
          </div>
        )}
        {product && product?.disclaimer &&product?.disclaimer.trim() !== "" && (
          <div
            className={`mt-2 text-lg pl-10 font-medium ${
              verticalOpen.name === "disclaimer" && verticalOpen.value
                ? "block"
                : "hidden"
            }`}
            dangerouslySetInnerHTML={{
              __html: product && product?.disclaimer,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default Details;
