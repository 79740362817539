import { createSlice } from "@reduxjs/toolkit";

const subscribeModalSlice = createSlice({
    name: "getModal",
    initialState: {
        loading: false,
        modal:null,
    },
    reducers: {
        subscribeModalSliceRequest(state, action) {
            return {
                loading: true,
            }
        },
        subscribeModalSliceSuccess(state, action) {
            return {
                loading: false,
                modal: action.payload.modal,
            }
        },
        subscribeModalSliceFail(state, action) {
            return {
                loading: false,
                error: action.payload
            }
        }
    }
})

const { actions, reducer } = subscribeModalSlice;

export const { subscribeModalSliceRequest, subscribeModalSliceSuccess, subscribeModalSliceFail } = actions;

export default reducer;