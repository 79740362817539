import { createSlice } from "@reduxjs/toolkit";

const Wishlist = createSlice({
  name: "Wishlist",
  initialState: {
    loading: false,
    totalWishlist: 0,
  },
  reducers: {
    WishlistRequest(state, action) {
      return {
        loading: true,
      };
    },
    WishlistSuccess(state, action) {
      return {
        loading: false,
        totalWishlist: action.payload.totalWishlist,
      };
    },
    WishlistFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = Wishlist;

export const { WishlistRequest, WishlistSuccess, WishlistFail } = actions;

export default reducer;
