import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import validator from "validator";
import { backendUrl } from "../../config/config";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getUserAddress } from "../../actions/userDashboardActions";
import Indian_states_cities_list from "indian-states-cities-list";
import { IoIosArrowDown } from "react-icons/io";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const UpdateShippingAddressPopup = ({
  isOpen,
  onRequestClose,
  shippingData,
}) => {
  const [clickLoad, setClockLoad] = useState(false);

  const [formData, setFormData] = useState({
    addressname: shippingData?.addressname,
    firstname: shippingData?.first_name,
    lastname: shippingData?.last_name,
    address: shippingData?.address,
    apartment: shippingData?.apartment,
    city: shippingData?.city,
    pincode: shippingData?.pincode,
    state: shippingData?.state,
    country: shippingData?.country,
    phonenumber: shippingData?.phone_number,
  });
  useEffect(() => {
    setFormData({
      addressname: shippingData?.addressname,
      firstname: shippingData?.first_name,
      lastname: shippingData?.last_name,
      address: shippingData?.address,
      apartment: shippingData?.apartment,
      city: shippingData?.city,
      pincode: shippingData?.pincode,
      state: shippingData?.state,
      country: shippingData?.country,
      phonenumber: shippingData?.phone_number,
    });
  }, [isOpen]);

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const validateForm = () => {
    let newErrors = {};
    setClockLoad(true);
    // Example validation, you can customize based on your requirements
    if (validator.isEmpty(formData.firstname)) {
      newErrors.firstname = "First name is required";
    }
    if (validator.isEmpty(formData.addressname)) {
      newErrors.addressname = "First name is required";
    }

    // if (validator.isEmpty(formData.lastname)) {
    //   newErrors.lastname = "Last name is required";
    // }

    if (validator.isEmpty(formData.address)) {
      newErrors.address = "Address is required";
    }
    if (validator.isEmpty(formData.apartment)) {
      newErrors.apartment = "Apartment is required";
    }
    if (validator.isEmpty(formData.city)) {
      newErrors.city = "City is required";
    }

    if (validator.isEmpty(formData.pincode)) {
      newErrors.pincode = "Pin code is required";
    } else if (!validator.isNumeric(formData.pincode)) {
      newErrors.pincode = "Pin code should be numeric";
    }

    if (validator.isEmpty(formData.state)) {
      newErrors.state = "State is required";
    }

    if (validator.isEmpty(formData.country)) {
      newErrors.country = "Country is required";
    }

    if (validator.isEmpty(formData.phonenumber)) {
      newErrors.phonenumber = "Phone number is required";
    } else if (
      !validator.isMobilePhone(formData.phonenumber, "any", {
        strictMode: false,
      })
    ) {
      newErrors.phonenumber = "Invalid phone number";
    }
    // Add more validations for other fields...

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, proceed with form submission or other actions
      setErrors({});
      try {
        let token = localStorage.getItem("auth_Token");
        // Perform the API request here
        const response = await axios.post(
          backendUrl + `update-address/${shippingData?.id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === "1") {
          onRequestClose();
          toast.success("Address created Succesful", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
          dispatch(getUserAddress);
        }
        if (response.data.status === "0") {
          toast.warning(response.data.message, {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
        setClockLoad(false);
      } catch (error) {
        // Handle API request errors (e.g., show error message)
        toast.error("Something went wrong , Please try again !", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
        setClockLoad(false);
      }
    } else {
      setClockLoad(false);
      // Form is invalid, handle errors
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <form onSubmit={handleSubmit} className="relative">
        <div  className=" px-1 theme_bg_green min-w-[40%] rounded-t-2xl text-center py-6 flex flex-col gap-y-2">
          <input
            type="text"
            className="py-1 px-4 sm:px-4 w-[80%] mx-auto md:px-6 bg-gray-200 text-gray-500 rounded-xl font-medium text-center"
            placeholder=" Add Name"
            name="addressname"
            value={formData.addressname}
            onChange={handleChange}
          />
          {errors.addressname && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 w-[80%] mx-auto">
              {errors.addressname}
            </div>
          )}
        </div>
        <div className="px-2 bg-white py-2 rounded-b-2xl">
          <div
            className="flex flex-col gap-y-2 sm:gap-y-2 md:gap-y-3"
          >
            <div className="flex justify-center align-center gap-x-3 flex-wrap sm:flex-wrap md:flex-nowrap gap-y-2">
              <div className="w-full sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <input
                  type="text"
                  className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                  placeholder="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                />
                {errors.firstname && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.firstname}
                  </div>
                )}
              </div>
              <div className="w-full sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <input
                  type="text"
                  className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                  placeholder="Last Name (Optional)"
                  value={formData.lastname}
                  name="lastname"
                  onChange={handleChange}
                />
                {errors.lastname && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.lastname}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-1 justify-center align-center">
              <input
                type="text"
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {errors.address && (
                <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                  {errors.address}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-y-1 justify-center align-center">
              <input
                type="text"
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="Apartment ,Suite,Ect."
                value={formData.apartment}
                name="apartment"
                onChange={handleChange}
              />
              {errors.apartment && (
                <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                  {errors.apartment}
                </div>
              )}
            </div>
            <div className="flex gap-y-2 justify-center align-center gap-x-3 flex-wrap sm:flex-wrap md:flex-nowrap">
              <div className="w-full flex flex-col gap-y-1 sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <input
                  type="text"
                  className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                  placeholder="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                {errors.city && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.city}
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col gap-y-1 sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <input
                  type="text"
                  className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                  placeholder="Pin Code"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
                {errors.pincode && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.pincode}
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-y-2 justify-center align-center gap-x-3 flex-wrap sm:flex-wrap md:flex-nowrap">
              <div className=" w-full flex flex-col gap-y-1 sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <div className="relative">
                  <select
                    className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                    value={formData.state}
                    onChange={handleChange}
                    name="state"
                  >
                    <option value="">Select your State </option>
                    {Indian_states_cities_list &&
                      Indian_states_cities_list.STATES_OBJECT.map(
                        (state, index) => (
                          <option
                            key={index}
                            value={state.value}
                            selected={formData.state === state.value}
                          >
                            {state.value}
                          </option>
                        )
                      )}
                  </select>
                  <span className="absolute top-1/2 right-2 -translate-y-1/2 z-10 pointer-events-none">
                    <IoIosArrowDown />
                  </span>
                </div>
                {errors.state && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.state}
                  </div>
                )}
              </div>
              <div className="w-full flex flex-col gap-y-1 sm:w-full md:w-1/2 flex gap-y-1 flex-col">
                <div className="relative">
                  <select
                    className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                    value={formData.country}
                    onChange={handleChange}
                    name="country"
                  >
                    <option value="">Select your Country</option>
                    <option value="india">India</option>
                  </select>
                  <span className="absolute top-1/2 right-2 -translate-y-1/2 z-10 pointer-events-none">
                    <IoIosArrowDown />
                  </span>
                </div>{" "}
                {errors.country && (
                  <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                    {errors.country}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-1 justify-center align-center">
              <input
                type="text"
                className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
                placeholder="Phone Number"
                value={formData.phonenumber}
                onChange={handleChange}
                name="phonenumber"
              />
              {errors.phonenumber && (
                <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                  {errors.phonenumber}
                </div>
              )}
            </div>
            <div className="flex justify-center align-center">
              <button
                className={`px-3 sm:px-3 md:px-5 lg:px-6 py-2 text-center text-white font-medium rounded-xl bg-orange-400 ${
                  clickLoad ? " opacity-50 pointer-events-none	" : ""
                }`}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
      <span
        onClick={onRequestClose}
        className="absolute -top-2 -right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
      >
        <FaTimes size={18} />
      </span>
    </Modal>
  );
};

export default UpdateShippingAddressPopup;
