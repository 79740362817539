import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Component from "./Component";
import { backendUrl } from "../../config/config";
import axios from "axios";

const BestSellers = () => {
  const [reorderedValues, setReorderedValues] = useState([]);
  const [resultIndex, setResultindex] = useState(0);
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const [imageNumbers, setImageNumbers] = useState(4);
  const [tabImage, setTabImage] = useState(2);
  const [mobileImage, setMobileImage] = useState(1);
  const order = ['bestseller', 'newarrival', 'sale'];

  const getSellers = async () => {
    try {
      const response = await axios.get(backendUrl + "get_product_seller");
      setReorderedValues(reorderValues(Object.values(response?.data?.sellerName), order));
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  useEffect(() => {
    getSellers();
    if (spanRefs.current && spanRefs.current[0]) {
      spanRefs.current[0].click(); // Trigger click on the first span
    }
  }, []);
  useEffect(() => {
    if (spanRefs.current && spanRefs.current[0]) {
      spanRefs.current[0].click(); // Trigger click on the first span
    }
    
  }, [reorderedValues]);


   // Function to reorder the values based on the order
   const reorderValues = (originalValues, orderArray) => {
     const reorderedValues = orderArray.filter(value => originalValues.includes(value));
     return reorderedValues;
   };
 
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: imageNumbers >= 4 ? 4 : imageNumbers,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: tabImage >= 3 ? 3 : tabImage,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: tabImage >= 3 ? 3 : tabImage,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: tabImage >= 2 ? 2 : tabImage,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: mobileImage >= 1 ? 1 : mobileImage,
        },
      },
    ],
  };
  const getSellerProducts = async (index, name) => {
    setResultindex(index);
    setLoader(true);
    let token = localStorage.getItem("auth_Token");
    try {
      const response = await axios.get(
        backendUrl + `get_seller_by_name/${name}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProducts(response?.data?.sellerProducts);
      setImageNumbers(response?.data?.sellerProducts.length);
      setTabImage(response?.data?.sellerProducts.length);
      setMobileImage(response?.data?.sellerProducts.length);
      setLoader(false);
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const spanRefs = useRef([]);

  return (
    <div className="container mx-auto py-4 px-8 sm:px-16 md:px-20 lg:px-28 max-w-[1300px]">
      <div className="py-2 text-center">
        <div className="text-lg sm:text-xl md:text-2xl font-semibold flex justify-center gap-x-4 sm:gap-x-8 md:gap-x-14 flex-wrap gap-y-2">
          {reorderedValues &&
            reorderedValues.length > 0 &&
            reorderedValues.map((result, index) => (
              <span
                ref={(el) => (spanRefs.current[index] = el)}
                key={index}
                onClick={() => {
                  if (!loader) {
                    getSellerProducts(index, result);
                  }
                }}
                className={`cursor-pointer ${
                  index === resultIndex ? "opacity-100" : "opacity-50"
                }`}
              >
                {result === "bestseller" && "Best Seller"}
                {result === "sale" && "Sale"}
                {result === "newarrival" && "New Arrivals"}
              </span>
            ))}
        </div>
      </div>
      <div className="py-3 mt-4">
        <Slider {...carouselSettings}>
          {products &&
            products.length > 0 &&
            products.map((product) => (
              <Component
                key={product?.id}
                productObject={product}
                productId={product?.id}
                pro_slug={product?.slug}
                name={product?.title}
                pro_name={product?.short_desc}
                stars={5}
                img_link={product?.product_image}
                variations={product?.variation}
                wishlistcheck = {product?.wishlist}
                // getvariation = {false}
              />
            ))}
        </Slider>
      </div>
    </div>
  );
};

// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="text-3xl font-semibold absolute top-1/2  -left-8 md:-left-14 -translate-y-1/2 cursor-pointer z-10"
    >
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-3xl font-semibold absolute top-1/2 -right-8 md:-right-14 -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );
};

export default BestSellers;
