import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Component from './Component';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import img1 from "../../assets/images/home/whyus/img1.png";
import img2 from "../../assets/images/home/whyus/img2.png";
import img3 from "../../assets/images/home/whyus/img3.png";
import img4 from "../../assets/images/home/whyus/img4.png";
import img5 from "../../assets/images/home/whyus/img5.png";
import img6 from "../../assets/images/home/whyus/img6.png";
import img7 from "../../assets/images/home/whyus/img7.png";
import img8 from "../../assets/images/home/whyus/img8.png";
import img9 from "../../assets/images/home/whyus/img9.png";
import img10 from "../../assets/images/home/whyus/img10.png";
import img11 from "../../assets/images/home/whyus/img11.png";
import img12 from "../../assets/images/home/whyus/img12.png";

const WhyChoose = () => {
  
  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div className='container mx-auto py-4 px-10 sm:px-16 md:px-20 lg:px-28'>
      <div className='py-2 text-center'>
        <div className=' text-2xl font-semibold'>Why Choose THE HERB KEY ?</div>
      </div>
      <div className='py-3'>
        <Slider {...carouselSettings}>
          {/* <Component name="Guaranteed Pure" img={img1} desc="The best ingredients are carefully handpicked. It's a promise that every drop you touch is subjected to strict purity check." />
          <Component name="Completely Cruelty-Free" img={img2} desc="Not tested on animals in any form. Home-tested for result and perfection." />
          <Component name="Ingredient Sourcing" img={img3} desc="Collected from natural sourcing for the purest far and beyond. it's hard. But yes , we sourced from the best and the products speak to it" /> */}
          <Component name="Purity Guaranteed" img={img4} desc="We use only the best ingredients carefully handpicked directly from the farm with strict quality checks to ensure only the finest products for our customers." />
          <Component name="Cruelty-Free" img={img5} desc="The Herb key is a humane and cruelty free brand. We practice a zero-animal testing policy. Each product is home-tested for best results." />
          <Component name="Ethical sourcing" img={img6} desc="All our ingredients are procured from farmers to whom we pay a fair price and ensure all ingredients are honorably sourced." />
          <Component name="Home Grown" img={img7} desc="All our products are homemade giving you the feel of traditional methods followed by our grandmothers in their kitchen with the love and care." />
          <Component name="Safe Ingredients" img={img8} desc="We use only safe ingredients from well-known sources to ensure purity. This reduces any reactions caused due to the harshness of the products." />
          <Component name="Handmade" img={img9} desc="Each product is carefully handcrafted with love. We follow traditional recipes and methods, using only the best natural ingredients." />
          <Component name="No Synthetic Fragrances" img={img10} desc="We at The Herb Key do not use any artificial or synthetic fragrances and rely on natural aroma and essential oils for the scent." />
          <Component name="No Artificial Color" img={img11} desc="Our products contain only natural colors made from natural ingredients like seeds, roots, vegetables and clay, making them safer for your skin." />
          <Component name="Palm Oil Free" img={img12} desc="Palm oils are known to cause more acne and clog the skin. All our products are palm oil free making them more beneficial for your skin." />
        </Slider>
      </div>
    </div>
  )
}
// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className='text-3xl font-semibold absolute top-1/2 -left-10 -translate-y-1/2 cursor-pointer z-10'>
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="text-3xl font-semibold absolute top-1/2 -right-10 -translate-y-1/2 cursor-pointer z-10" onClick={onClick}>
      <IoIosArrowForward />
    </div>
  );
};


export default WhyChoose