import React, { useEffect, useState } from "react";
import { BiHomeAlt2 } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { MdArrowDropDown } from "react-icons/md";
import { PiUser, PiShoppingBagOpenDuotone } from "react-icons/pi";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { getMenus } from "../actions/menusActions";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../actions/categoryActions";
import { getProductBySlug } from "../actions/productActions";
import {
  getCarts,
  incrementSingleCart,
  removeSingleCart,
} from "../actions/CartActions";
import CartPopup from "./CartPopup";
import { getProductsBySubcategorySlug } from "../actions/subcategoryProductsActions";
import Loginpopup from "./UserLogin/Loginpopup";
import RegisterPopup from "./UserLogin/RegisterPopup";
import { CheckUserLogin } from "../utils/utils";
import { FaMinus, FaPlus, FaTimes, FaWhatsapp } from "react-icons/fa";
import TopHeader from "./TopHeader";
import {
  getSubscribeModal,
  getWishListDetails,
} from "../actions/userDashboardActions";
import { AiFillHeart } from "react-icons/ai";
import ForgetPassword from "./UserLogin/ForgetPassword";
import axios from "axios";
import { backendUrl } from "../config/config";

const Header = ({ disabledCart = false }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [burgerControl, setBurgerControl] = useState(false);
  const [openMenus, setOpenMenus] = useState({});
  const [savedAmount, setSavedAmount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [registerModelIsOpen, seRegisternModalIsOpen] = useState(false);
  const [forgetModelIsOpen, setForgetModelIsOpen] = useState(false);
  const [searchBox, setSearchBox] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { menus, topheader } = useSelector((state) => state.menusState);
  const { totalWishlist } = useSelector((state) => state.WishlistState);

  const { CartData, loading: cartLoading } = useSelector(
    (state) => state.getCartState
  );

  const removeCart = (productId, VariationId) => {
    dispatch(removeSingleCart(productId, VariationId));
  };
  const increaseCart = async (productId, VariationId, quantity, count) => {
    dispatch(incrementSingleCart(productId, VariationId, quantity));
  };

  useEffect(() => {
    dispatch(getMenus);
    dispatch(getCarts);
    dispatch(getWishListDetails);
  }, []);

  useEffect(() => {
    if (CartData) {
      let totalPrice = 0; // Initialize the totalPrice variable

      CartData.forEach((cart) => {
        const price = cart.quantity * cart.variation.sale_price;
        totalPrice += price; // Accumulate the prices
      });
      setTotalAmount(totalPrice);

      let savedAmounts = 0; // Initialize the totalPrice variable
      let Totquantity = 0; // Initialize the totalPrice variable
      CartData.forEach((cart) => {
        const singleQuantity = cart.quantity;
        const price =
          cart.quantity *
          (cart?.variation?.mrp_price - cart?.variation?.sale_price);
        savedAmounts += price; // Accumulate the prices
        Totquantity += singleQuantity;
      });
      setSavedAmount(savedAmounts);
      setTotalQuantity(Totquantity);
    }
    if (CartData.length == 0) {
      setModalIsOpen(false);
    }
  }, [CartData]);

  const getProducts = (id) => {
    dispatch(getProductBySlug(id));
  };

  const openModal = () => {
    dispatch(getCarts);
    setModalIsOpen(true);
    if (CartData.length > 0) {
      document.body.classList.add("cart_popup");
    }
  };
  const LoginOpenModel = async (navigateLink) => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");

    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === false) {
      document.body.classList.add("user_login");
      setLoginModalIsOpen(true);
    } else {
      navigate(navigateLink);
    }
  };

  const closeLoginModal = () => {
    document.body.classList.remove("user_login");
    setLoginModalIsOpen(false);
  };

  const RegisterOpenModel = () => {
    document.body.classList.remove("user_login");
    document.body.classList.add("user_register");
    seRegisternModalIsOpen(true);
    closeLoginModal(false);
  };
  const ForgetOpenModel = () => {
    document.body.classList.remove("user_login");
    document.body.classList.add("user_register");
    setForgetModelIsOpen(true);
    closeLoginModal(false);
  };

  const RegisterCloseModal = () => {
    document.body.classList.remove("user_register");
    seRegisternModalIsOpen(false);
  };
  const ForgetCloseModal = () => {
    document.body.classList.remove("user_register");
    setForgetModelIsOpen(false);
  };
  const closeModal = () => {
    document.body.classList.remove("cart_popup");
    setModalIsOpen(false);
  };
  const getSubcategory = (slug) => {
    setOpenMenus({});
    document.body.classList.remove("body_dis_overflow");
    setBurgerControl(false);
    dispatch(getProductsBySubcategorySlug(slug));
  };
  function openNav(index) {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [index]: !prevOpenMenus[index], // Toggle the open/close state for the specific menu item
    }));
  }
  function openMobileNav() {
    setOpenMenus({});
    document.body.classList.add("body_dis_overflow");
    setBurgerControl(true);
  }
  function closeMobileNav() {
    document.body.classList.remove("body_dis_overflow");
    setBurgerControl(false);
  }
  const handleNavLinkClick = (slug) => {
    setOpenMenus({});
    document.body.classList.remove("body_dis_overflow");
    setBurgerControl(false);
    dispatch(getCategory(slug));
  };
  const Productsearch = async (value) => {
    const searchInput = value.trim();
    setSearchData([]);
    setSearchLoading(true);
    if (searchInput.length >= 3) {
      console.log(searchInput);
      setSearchBox(true);
      const response = await axios.post(backendUrl + "search-products", {
        searchInput,
      });
      if (response.data.products.length > 0) {
        setSearchData(response.data.products);
      } else {
        setSearchLoading(false);
        setSearchData([]);
      }
    } else {
      setSearchBox(false);
    }
  };

  const ProductPage = (slug) => {
    navigate("/products/" + slug);
    window.location.reload();
  };
  return (
    <>
      {topheader && <TopHeader topheader={topheader} />}
      <div
        className={`block lg:hidden fixed top-0 left-0 w-full h-full bg-white z-50 transition-all delay-100  ${
          !burgerControl && "-translate-x-full"
        }`}
      >
        <div className="flex justify-end items-center">
          <span
            className="py-2 px-3 cursor-pointer"
            onClick={() => closeMobileNav()}
          >
            <FaTimes size={20} />
          </span>
        </div>

        <div className="px-3 py-2 flex flex-col gap-y-4 pt-4">
          {menus &&
            menus.map((menu) => (
              <div className=" border rounded-md" key={menu.id}>
                <div className="flex justify-between items-center text-black font-medium px-3 py-1">
                  <Link
                    onClick={() => handleNavLinkClick(menu.slug)}
                    to={`/category/${menu.slug}`}
                    className="w-full hover:text-orange-400"
                  >
                    {menu.name}
                  </Link>
                  <span
                    className=" cursor-pointer"
                    onClick={() => openNav(menu.id)}
                  >
                    {openMenus[menu.id] ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>

                {openMenus[menu.id] &&
                  menu.subcategory &&
                  menu.subcategory.map(
                    (sub_cat) =>
                      sub_cat.products && (
                        <div key={sub_cat.id}>
                          <Link
                            className="block py-1.5 px-3 font-medium hover:text-orange-400"
                            to={`/sub-category/${sub_cat.slug}`}
                            onClick={() => getSubcategory(sub_cat.slug)}
                          >
                            {sub_cat.name}
                          </Link>
                        </div>
                      )
                  )}
              </div>
            ))}
        </div>
      </div>
      <div className=" px-6 xl:px-16 ">
        <div className="hidden lg:flex py-3 sm:py-4 md:py-6  items-center justify-between">
          <div className="flex w-[20%] gap-x-5 md:gap-x-5 xl:gap-x-10">
            <div className="text-[18px] md:text-[20px] lg:text-[23px]">
              <Link
                to="/"
                className=" text-white rounded-full relative w-[25px] h-[25px] md:h-[30px] md:w-[30px] lg:w-[35px] lg:h-[35px] theme_bg_green inline-block"
              >
                <BiHomeAlt2 className="center_abs_object" />
              </Link>
            </div>
            <div className="flex relative">
              <input
                type="text"
                className="bg-[#edf1f0] rounded-md pr-2 pl-10 w-full focus-visible:outline-0"
                placeholder="Search"
                onChange={(e) => Productsearch(e.target.value)}
              />
              <span className="text-[14px] md:text-[16px] lg:text-[18px] absolute left-3 top-1/2 -translate-y-1/2">
                <BsSearch />
              </span>
              {searchBox && (
                <ul className="absolute min-w-full text-[14px] font-semibold bg-white py-1 px-2 text-base top-full rounded-b-md z-20 shadow-lg">
                  {searchData.length > 0 ? (
                    <>
                      {searchData.map((search) => (
                        <li
                          className="mt-1"
                          onClick={() => ProductPage(search?.slug)}
                        >
                          <Link
                            to={"/products/" + search?.slug}
                            className="block"
                          >
                            {search?.title}
                          </Link>
                        </li>
                      ))}
                    </>
                  ) : (
                    <>
                      <li className="mt-1">
                        {searchLoading
                          ? "Loading ..."
                          : "Product does not Exist..."}
                      </li>
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className="w-[50%] text-black justify-center">
            <ul className="flex justify-between text-[13px] font-semibold">
              {menus &&
                menus.map((menu, index) => (
                  <React.Fragment key={menu.id}>
                    <li className="group header_nav_li relative uppercase">
                      <Link
                        onClick={() => handleNavLinkClick(menu.slug)}
                        to={`/category/${menu.slug}`}
                      >
                        {menu.name}
                      </Link>
                      <MdArrowDropDown className="header_nav_drop_down cursor-pointer" />
                      {menu.subcategory && (
                        <ul className="absolute hidden  group-hover:flex group-hover:flex-col  z-10  border-[0.1px] border-[#d8e1dc ] rounded-md pb-3 pt-3  text-[14px] bg-white -translate-x-1/2 left-1/2 top-0 translate-y-[50px] capitalize">
                          {menu.subcategory &&
                            menu.subcategory.map(
                              (sub_cat) =>
                                sub_cat.products && (
                                  <li
                                    key={sub_cat.id}
                                    className="min-w-[155px] font-normal header_sub relative px-2 "
                                  >
                                    <Link
                                      className="py-[1px]  justify-between flex items-center header_insub gap-x-[3px]"
                                      to={`/sub-category/${sub_cat.slug}`}
                                      onClick={() =>
                                        getSubcategory(sub_cat.slug)
                                      }
                                    >
                                      <span className="text-nowrap">
                                        {sub_cat.name}
                                      </span>
                                      <MdArrowDropDown
                                        className=" -rotate-90 flex-shrink-0"
                                        size={19}
                                      />
                                    </Link>
                                    {sub_cat.products && (
                                      <div className="hidden prod_nav border-[#d8e1dc ] border-[0.1px] rounded-md">
                                        {sub_cat.products.map(
                                          (products, indexes) => (
                                            <Link
                                              key={indexes}
                                              to={`/products/${products.slug}`}
                                              onClick={() =>
                                                getProducts(products.slug)
                                              }
                                              className=" block pt-[1px] hover:text-[#ffa500] "
                                            >
                                              {products.title}
                                            </Link>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </li>
                                )
                            )}
                        </ul>
                      )}
                    </li>
                    {menus.length / (index + 1) == 2 && (
                      <li className="header_nav_li flex-shrink-0">
                        <Link to="/">
                          <img
                            src={logo}
                            alt="logo"
                            className="mx-auto w-[auto] h-[50px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
                          />
                        </Link>
                      </li>
                    )}
                  </React.Fragment>
                ))}
            </ul>
          </div>
          <div className="flex w-[20%] gap-x-2 text-black items-center text-[16px] md:text-[18px] lg:text-[21px] justify-evenly">
            <div
              className=" cursor-pointer"
              onClick={() => LoginOpenModel("/user-dashboard")}
            >
              <PiUser />
            </div>
            <div
              className="relative cursor-pointer"
              onClick={() =>
                LoginOpenModel("/user-dashboard?tbOption=whislists")
              }
            >
              <AiFillHeart className={`text-[#808080a6]`} />{" "}
              <span className="head_icon_number">{totalWishlist}</span>
            </div>
            {!disabledCart && (
              <div className="text-[12px] lg:text-[14px] font-semibold flex items-center">
                ₹ {!cartLoading && totalAmount}{" "}
                <span
                  className=" relative cursor-pointer pl-2"
                  onClick={openModal}
                >
                  <PiShoppingBagOpenDuotone className="text-[22px]" />{" "}
                  <span className="head_icon_number">{totalQuantity}</span>
                </span>
              </div>
            )}
            <div>
              <a href="https://wa.me/+919500088250" target="_blank">
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
        <div className="flex lg:hidden py-2 md:py-3   items-center justify-between">
          <Link to={`/`}>
            <img
              src={logo}
              alt="logo"
              className="w-[auto] h-[50px] lg:h-[60px]"
            />
          </Link>
          <div className=" flex items-center justify-center gap-x-[25px] text-[20px]">
            <div
              className=" cursor-pointer"
              onClick={() => LoginOpenModel("/user-dashboard")}
            >
              <PiUser />
            </div>
            {!disabledCart && (
              <div className="text-[14px]  font-semibold flex items-center">
                ₹ {!cartLoading && totalAmount}{" "}
                <span
                  className=" relative cursor-pointer pl-2"
                  onClick={openModal}
                >
                  <PiShoppingBagOpenDuotone className="text-[22px]" />{" "}
                  <span className="head_icon_number">{totalQuantity}</span>
                </span>
              </div>
            )}
            <div
              className="relative cursor-pointer"
              onClick={() =>
                LoginOpenModel("/user-dashboard?tbOption=whislists")
              }
            >
              <AiFillHeart className={`text-[#808080a6]`} />{" "}
              <span className="head_icon_number">{totalWishlist}</span>
            </div>
            <span className=" cursor-pointer" onClick={() => openMobileNav()}>
              <RxHamburgerMenu size={22} />
            </span>
          </div>
        </div>
        {!disabledCart &&
          !cartLoading &&
          CartData.length > 0 &&
          modalIsOpen && (
            <CartPopup
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              carts={CartData}
              removeCart={removeCart}
              increaseCart={increaseCart}
              totalAmount={totalAmount}
              savedAmount={savedAmount}
            />
          )}
        <Loginpopup
          isOpen={loginModalIsOpen}
          onRequestClose={closeLoginModal}
          onOpenRegister={RegisterOpenModel}
          onOpenForget={ForgetOpenModel}
        />
        <RegisterPopup
          isOpen={registerModelIsOpen}
          onRequestClose={RegisterCloseModal}
        />
        <ForgetPassword
          isOpen={forgetModelIsOpen}
          onRequestClose={ForgetCloseModal}
        />
      </div>
    </>
  );
};

export default Header;
