import React, { useEffect } from "react";
import WhislistComponents from "./WhislistComponents";
import { backendUrl } from "../../config/config";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";

const Whislist = () => {
  const [wishlist, setWishlist] = useState([]);
  const test = async () => {
    try {
      let token = localStorage.getItem("auth_Token");
      // Perform the API request here
      const response = await axios.get(backendUrl + "getMyWishlist", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWishlist(response.data.products);
    } catch (error) {
      // Handle API request errors (e.g., show error message)
      if (error.response.status === 401) {
        toast.error("Please Login for adding in Wishlist", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      } else {
        toast.error("Something went wrong , Please try again !", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
      }
    }
  };
  useEffect(() => {
    test();
  }, []);

  return (
    <>
      {wishlist.length >0 && (
        <div className="max-w-[1050px] mx-auto mt-5 sm:mt-6 md:mt-7 overflow-x-auto">
          <div className=" flex flex-col gap-y-2 sm:gap-y-2 md:gap-y-3 lg:gap-y-4 min-w-[1024px] pb-3">
            {wishlist.map((product, index) => (
              <WhislistComponents product={product} key={index} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Whislist;
