import React, { useEffect } from "react";
import Component from "./Component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getBanners } from "../../actions/productActions";
import Loader from "../Loader";

const Banner = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true ,
    autoplaySpeed: 10000,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const { loading, pcBanner, tabBanner, mobileBanner } = useSelector(
    (state) => state.bannerState
  );
  useEffect(() => {
    dispatch(getBanners);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="baner_slides hidden lg:block landscape_banner">
            <Slider {...carouselSettings} className="hidden">
              {pcBanner &&
                pcBanner.map((pcBanners) => (
                  <Component key={pcBanners?.id} bannerData={pcBanners} />
                ))}
            </Slider>
          </div>
          <div className="baner_slides hidden sm:hidden md:block lg:hidden portrait_banners">
            <Slider {...carouselSettings} className="hidden">
              {tabBanner &&
                tabBanner.map((tabBanners) =>(
                  <Component key={tabBanners?.id} bannerData={tabBanners} />
                  ))}
            </Slider>
          </div>
          <div className="baner_slides block md:hidden portrait_banners">
            <Slider {...carouselSettings} className="hidden">
              {mobileBanner &&
                mobileBanner.map((mobileBanners) => ( 
                  <Component key={mobileBanners?.id} bannerData={mobileBanners} />
                  ))}
            </Slider>
          </div>
        </React.Fragment>
                  
      )}
    </>
  );
};

export default Banner;
