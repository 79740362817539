import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Steps from "../components/Checkout/Steps";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import ShippingProducts from "../components/Checkout/ShippingProducts";
import { getCarts } from "../actions/CartActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { CheckUserLogin } from "../utils/utils";
import TopHeader from "../components/TopHeader";
import { getCouponValue } from "../actions/userDashboardActions";

const CheckoutShipping = () => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [productTotal, setProductTotal] = useState(0);
  const [optionAmount, setOptionAmount] = useState(0);
  const [checkoutData, setCheckoutData] = useState({});
  // const [shippingAmount, setShippingAmount] = useState(2000);
  const { CartData, loading: cartLoading } = useSelector(
    (state) => state.getCartState
  );
  const { couponValue, loading: couponLoading } = useSelector(
    (state) => state.CouponCodeState
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(""); // To track the selected radio button
  const [errors, setErrors] = useState({});

  const handleRadioChange = (e) => {
    if (e.target.value === "amazon") {
      setSelectedOption("amazon");
      setOptionAmount(250);
    } else if (e.target.value === "indianPost") {
      setSelectedOption("indianPost");
      setOptionAmount(150);
    } else if (e.target.value === "freeShip" && productTotal > 1499) {
      setSelectedOption("freeShip");
      setOptionAmount(0);
    } else {
      setSelectedOption("amazon");
      setOptionAmount(250);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const newErrors = {};

    if (validator.isEmpty(selectedOption)) {
      newErrors.radioGroup = "Please select an option";
    }

    // Other form field validations

    setErrors(newErrors);

    // If there are no errors, you can proceed with further operations
    if (Object.keys(newErrors).length === 0) {
      // Perform further operations
      setErrors({});
      localStorage.setItem("shippingMethod", selectedOption);
      navigate("/payment");
    }
  };

  const checkuser = async () => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");

    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === false) {
      navigate("/");
    }
  };

  useEffect(() => {
    checkuser();
    dispatch(getCarts);

    const checkcart = JSON.parse(localStorage.getItem("cart"));
    if (checkcart === null) {
      navigate("/");
    } else {
      if (checkcart.length <= 0) {
        navigate("/");
      }
    }
    // Check if data exists in local storage
    const checkoutDatas = JSON.parse(localStorage.getItem("checkoutData"));

    if (!checkoutDatas) {
      // Data does not exist, so navigate to a different page
      navigate("/checkout");
    } else {
      // Data exists, you can perform further operations here
      // For example, you can use checkoutData for further processing
      setCheckoutData(checkoutDatas);
    }
    const shipMethod = localStorage.getItem("shippingMethod");
  }, []);
  useEffect(() => {
    if (CartData) {
      let totalPrice = 0; // Initialize the totalPrice variable

      CartData.forEach((cart) => {
        const price = cart.quantity * cart.variation.sale_price;
        totalPrice += price; // Accumulate the prices
      });
      dispatch(getCouponValue(totalPrice));
      setProductTotal(totalPrice);
      if (totalPrice > 1499) {
        setSelectedOption("freeShip");
        setTotalAmount(totalPrice);
      } else {
        setSelectedOption("amazon");
        setTotalAmount(totalPrice);
      }
    }
  }, [CartData]);

  return (
    <Fragment>
      
      <Header disabledCart={true} />
      <div className="container mx-auto py-4 px-2 md:px-3">
        <Steps linkname="shipping" />
        {couponLoading ? <Loader/> :
        <div className=" mt-3 sm:mt-4 md:mt-5 px-3 sm:px-4 md:px-5">
          <div className=" flex flex-wrap sm:flex-wrap md:flex-nowrap items-start justify-between">
            <div className="w-full sm:w-full md:w-1/2">
              <div className=" border-2 rounded-lg py-2 px-3 text-xs">
                <div className="pb-2 border-b-2 flex items-center justify-between">
                  <span className=" flex w-[70%]">
                    <span className="w-[15%]">Contact </span>
                    <span className=" ml-4 w-[80%]">
                      : {checkoutData?.email}
                    </span>
                  </span>
                  <Link
                    to="/checkout"
                    className=" opacity-40 font-medium cursor-pointer"
                  >
                    Change
                  </Link>
                </div>
                <div className=" flex items-center justify-between pt-2 pb-3">
                  <span className=" flex w-[70%]">
                    <span className=" w-[15%]">Ship to </span>
                    <span className=" ml-4 w-[80%]">
                      : {checkoutData?.address},
                      {checkoutData?.apartment != "" &&
                        checkoutData?.apartment + ","}
                      {checkoutData?.city},{checkoutData?.state},
                      {checkoutData?.country}.
                    </span>
                  </span>
                  <Link
                    to="/checkout"
                    className=" opacity-40 font-medium cursor-pointer"
                  >
                    Change
                  </Link>
                </div>
                {checkoutData?.orderNotes != "" && (
                  <div className=" flex items-center justify-between pt-2">
                    <span className=" flex w-[70%]">
                      <span className=" w-[15%]">Note</span>
                      <span className=" ml-4 w-[80%]">
                        : {checkoutData?.orderNotes}
                      </span>
                    </span>
                    <Link
                      to="/checkout"
                      className=" opacity-40 font-medium cursor-pointer"
                    >
                      Change
                    </Link>
                  </div>
                )}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mt-3 sm:mt-4 md:mt-5">
                  <span className=" text-lg">Shipping Method</span>
                  <div className=" border rounded-lg py-2 px-3 text-xs mt-4 ship_method">
                    <div className={`pb-2 border-b-2 flex items-center justify-between ${productTotal > 1499 && " opacity-50"}`}>
                      <span className=" flex w-[70%] items-center">
                        <span className="w-[10%]">
                          <input
                            type="radio"
                            name="shippingMethod"
                            id="shipping_method1"
                            value="amazon"
                            disabled={productTotal > 1499}
                            checked={selectedOption === "amazon"}
                            onChange={handleRadioChange}
                          />{" "}
                        </span>
                        <label
                          htmlFor="shipping_method1"
                          className=" ml-4 w-[80%]"
                        >
                          Amazon (Faster & Damage guaranteed(less damage if))
                        </label>
                      </span>
                      <span className="font-medium">₹ 250</span>
                    </div>
                    <div className={`pb-2 border-b-2 flex items-center justify-between pt-2 ${productTotal > 1499 && "opacity-50"}`}>
                      <span className=" flex w-[70%] items-start">
                        <span className="w-[10%]">
                          <input
                            type="radio"
                            name="shippingMethod"
                            id="shipping_method2"
                            value="indianPost"
                            disabled={productTotal > 1499}
                            checked={selectedOption === "indianPost"}
                            onChange={handleRadioChange}
                          />{" "}
                        </span>
                        <label
                          htmlFor="shipping_method2"
                          className=" ml-4 w-[80%]"
                        >
                          Indian Post (Damage not guaranteed)
                        </label>
                      </span>
                      <span className="font-medium">₹ 150</span>
                    </div>
                    <div className={`pb-2 border-b-2 flex items-center justify-between pt-2 ${productTotal <= 1499 && "opacity-50"}`}>
                      <span className=" flex w-[70%] items-start">
                        <span className="w-[10%]">
                          <input
                            type="radio"
                            name="shippingMethod"
                            id="shipping_method3"
                            disabled={productTotal <= 1499}
                            onChange={handleRadioChange}
                            value="freeShip"
                            checked={selectedOption === "freeShip"}
                          />{" "}
                        </span>
                        <label
                          htmlFor="shipping_method3"
                          className=" ml-4 w-[80%]"
                        >
                          Free Shipping(Via Amazon)
                        </label>
                      </span>
                      <span className="font-medium">Above 1499 Rs</span>
                    </div>
                  </div>
                </div>
                {errors.radioGroup && (
                  <p className="mt-2 px-2 py-1 bg-red-400 text-white rounded">
                    {errors.radioGroup}
                  </p>
                )}
                <div className=" mt-3 sm:mt-4 md:mt-5">
                  <div className=" flex items-center justify-between border-b pb-3 border-b-[#b5b5b5] flex-wrap sm:flex-nowrap gap-y-2">
                    <Link
                      to="/checkout"
                      className=" opacity-40 font-medium text-[13px] flex items-center w-full sm:w-1/2"
                    >
                      <span>
                        <MdOutlineKeyboardArrowLeft size={18} />
                      </span>
                      <span>Return to Information</span>{" "}
                    </Link>
                    <button
                      typpe="submit"
                      className=" text-white font-medium text-base sm:text-base md:text-lg bg-[#ff8a00] px-4 py-1.5 rounded-2xl text-center w-full sm:w-1/2"
                    >
                      Continue to Payment
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="w-full sm:w-full md:w-[45%] lg:w-[35%]">
              <div className="border-b-2">
                {cartLoading ? (
                  <Loader />
                ) : (
                  CartData &&
                  CartData.map((cart) => (
                    <ShippingProducts key={cart.id} cart={cart} />
                  ))
                )}
              </div>
              <div className=" pb-4 pt-3">
                <div className=" border-b-2 pb-3">
                  <div className=" flex justify-between items-center">
                    <span className=" w-1/2">Shipping</span>
                    <span className=" w-1/2 text-right">₹ {optionAmount}</span>
                  </div>
                  <div className=" text-xs mt-3">
                    Enjoy free shipping for orders of INR 1499/- above
                  </div>
                </div>
                {couponValue && (
                  <div className="pt-4">
                    <div className=" flex items-center justify-between gap-x-2 text-lg">
                      <span>Discount</span>
                      <span className=" w-1/2 text-right">₹ {couponValue}</span>
                    </div>
                  </div>
                )}
                <div className=" flex items-center justify-between gap-x-2 text-lg mt-3">
                  <span className=" w-1/2 flex flex-col">
                    <span>
                      GrandTotal <span className=" ml-4">:</span>
                    </span>
                    <span className=" text-xs">Including Taxes</span>
                  </span>
                  <span className=" w-1/2 text-right">
                    ₹{" "}
                    {couponValue != null
                      ? totalAmount + optionAmount - couponValue
                      : totalAmount + optionAmount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
       
      </div>
    </Fragment>
  );
};

export default CheckoutShipping;
