import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import validator from "validator";
import axios from "axios";
import { backendUrl } from "../../config/config";
import { useNavigate } from "react-router-dom";
import { FaEye , FaEyeSlash } from "react-icons/fa";

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    right: "2%",
    left: "auto",
    right: "auto",
    bottom: "auto",
    borderRadius: "20px",
  },
};

// Component for displaying the video inside the popup
const Loginpopup = ({
  isOpen,
  onRequestClose,
  onOpenRegister,
  onOpenForget,
  currentPage = false,
  setHideLogin,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [eyesOpen, setEyesOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setFormData({
        name: "",
        password: "",
      });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, submit the data or perform further actions
      setLoading(true);
      setErrors({});
      axios
        .post(backendUrl + "login", formData)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            localStorage.setItem("auth_Token", res?.data?.token);
            onRequestClose();
            if (currentPage == false) {
              navigate("/user-dashboard");
              localStorage.setItem("userReload", 1);
            } else {
              setHideLogin(true);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.status === 400) {
            // Handle the 400 Bad Request error here
            setErrors({
              name: err?.response?.data?.errors?.name,
              password: err?.response?.data?.errors?.password,
              message: err?.response?.data?.message,
            });
          }
        });
    } else {
      // Form has errors, display them
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    // Validate the name
    if (validator.isEmpty(data.name)) {
      errors.name = "Username is required";
    }

    // Validate the password
    if (validator.isEmpty(data.password)) {
      errors.password = "Password is required";
    } else if (data.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    return errors;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={false}
      appElement={document.getElementById("app")}
    >
      <div className=" flex justify-between items-stretch flex-wrap sm:flex-nowrap">
        <div className=" bg-[#4f7661] text-white px-4 py-5 flex flex-col justify-center align-middle text-center w-full sm:w-[50%]">
          <div>
            <h4 className=" font-semibold text-lg">WELCOME TO LOGIN</h4>
            <p className="font-light">Nice to See you here :)</p>
            <p className="pt-3 font-light text-[15px]">
              To keep connected with us please login with your email id
            </p>
            <p className="pt-5  text-[17px]">Don't have an account?</p>
            <span
              onClick={onOpenRegister}
              className=" cursor-pointer px-5 py-1.5 bg-[#fff] rounded-xl text-[#4f7661] inline-block uppercase font-medium mt-3"
            >
              Sign up
            </span>
          </div>
        </div>
        <div className="px-5 py-8 w-full sm:w-[70%] bg-white">
          <div className=" flex justify-between">
            <div className=" text-[#4f7661] font-semibold text-lg">LOGIN</div>
          </div>
          {errors.message && (
            <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
              {errors.message}
            </p>
          )}
          <div className="pt-4">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                id="name"
                className=" bg-[#ebeff8] px-3 py-1.5 text-[15px] rounded-lg w-full focus-within:outline-0"
                placeholder="USER NAME:"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                  {errors.name}
                </p>
              )}
              <div className="relative mt-3">
                <input
                  type={eyesOpen ? "text":"password"}
                  name="password"
                  id="password"
                  className=" bg-[#ebeff8] px-3 py-1.5 text-[15px] rounded-lg w-full  focus-within:outline-0"
                  placeholder="PASSWORD:"
                  value={formData.password}
                  onChange={handleChange}
                />
                {eyesOpen ? (
                  <FaEye
                    className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
                    onClick={()=>setEyesOpen(false)}
                  />
                ) : (
                  <FaEyeSlash 
                    className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer text-[17px]"
                    onClick={()=>setEyesOpen(true)}
                  />
                )}
              </div>
              {errors.password && (
                <p className="bg-red-500 mt-2 rounded-md text-white py-2 px-2">
                  {errors.password}
                </p>
              )}
              <div className=" flex justify-end mt-4 text-[#4f7661] text-[15px]">
                <div className=" cursor-pointer" onClick={onOpenForget}>
                  Forgot Password?
                </div>
              </div>
              <div className=" mt-3 text-center">
                <button
                  type="submit"
                  className={`px-8 py-1.5 bg-[#ff8a00] rounded-xl text-white inline-block uppercase font-medium mt-3 ${
                    loading ? " pointer-events-none cursor-none opacity-60" : ""
                  }`}
                >
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Loginpopup;
