import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';


// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
    overlay: {
        backgroundColor: 'rgb(154 172 248 / 70%)',
    },
    content: {
        top: '0',
        right: '2%',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        width: '400px', // Customize the max width of the modal
        borderRadius: "20px",
    },
};




// Component for displaying the video inside the popup
const OutofStockPopup = ({ isOpen, onRequestClose, }) => {

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}
            ariaHideApp={false}
            appElement={document.getElementById('app')}
        >
        <div className=' bg-white rounded-[20px] shadow-3xl stock_shadow'>
            <div className='bg-[#9bb2f8] text-white uppercase text-center text-md font-semibold py-3 rounded-t-[20px]'>
                    Notify me when available
            </div>
            <form className='px-8 py-6'>
                <input type='text' className='bg-[#ebeff8] rounded-xl py-2 px-2 ps-4 text-sm placeholder:text-[#9da0a5] placeholder:text-sm w-full' placeholder='NAME'/>
                <input type='text' className='bg-[#ebeff8] rounded-xl mt-5 py-2 px-2 ps-4 text-sm placeholder:text-[#9da0a5] placeholder:text-sm w-full' placeholder='EMAIL'/>
                <div className=' text-center mt-5'>
                <button className='px-8 py-1.5 bg-[#ff8a00] rounded-xl text-white inline-block uppercase font-medium mt-3 mx-auto'>Submit</button>

                </div>
            </form>
        </div>
         
        </Modal >
    );
};


export default OutofStockPopup