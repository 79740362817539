import axios from "axios"
import { variationTypeChangeRequest, variationTypeChangeSuccess, variationTypeChangeFail } from "../slices/variationTypeChange";
import { backendUrl } from "../config/config";

export const getVariationType = id => async (dispatch) => {
    try {
        dispatch(variationTypeChangeRequest())
        const { data } = await axios.get(backendUrl + `get_variationtypebyproductVariationId/${id}`)
        dispatch(variationTypeChangeSuccess(data))
    } catch (error) {
        dispatch(variationTypeChangeFail(error.response.data.message));
    }
}