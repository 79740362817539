import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import validator from "validator";
import axios from "axios";
import { backendUrl } from "../../config/config";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

// Styling for the modal overlay. You can customize this based on your needs.
const customStyles = {
  overlay: {
    backgroundColor: "rgb(154 172 248 / 70%)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    marginTop: "20px",
    transform: "translate(-50%, -50%)",
    width: "500px", // Customize the max width of the modal
    maxWidth: "500px",
    height: "100vh",
    minHeight: "100vh",
    borderRadius: "20px",
    backgroundColor:"transparent",
    // display:"flex",
    // alignItems:"center"
  },
};

// Component for displaying the video inside the popup
const CustomerReviewPopup = ({ id, isOpen, onRequestClose,OpenSuccess }) => {
  // Replace the YouTube video URL with the URL of the video you want to show.

  const [rating, setRating] = useState(0); // Set the default rating to 1
  const [loading, setLoading] = useState(false); // Set the default rating to 1

  useEffect(() => {
    if (isOpen) {
      setErrors({});
      setRating(0);
      setFormData({
        rating: "",
        review: "",
        name: "",
        email: "",
        place: "",
      });
    }
  }, [isOpen]);

  const [formData, setFormData] = useState({
    rating: "",
    review: "",
    name: "",
    email: "",
    place: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    setLoading(true);
    if (Object.keys(validationErrors).length === 0) {
      // Submit the form or perform other actions
      // setErrors({});
      const sendFormData = { ...formData, product_id: id };
      try {
        // Perform the API request here
        const response = await axios.post(
          backendUrl + "customer-product-review",
          sendFormData
        );
        if (response.data.status === "1") {
          // toast.success("Your Review submitted", {
          //   position: "top-right",
          //   autoClose: 3000, // Auto-close the toast after 3 seconds
          // });
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Your review/feedback has been submitted.',
          //   text: 'Once it is verified , the review will appear on the product page.',
          // });
          setErrors({});
          onRequestClose();
          OpenSuccess(true);
          document.body.classList.add("out_popup");
        }
         setLoading(false);
      } catch (error) {
        toast.error("Something went wrong , Please try again !", {
          position: "top-right",
          autoClose: 3000, // Auto-close the toast after 3 seconds
        });
         setLoading(false);
      }
    }else{
      setLoading(false);
    }
  };
  const handleRatingChange = (value) => {
    setRating(value);
    setFormData({ ...formData, ["rating"]: value });
  };
  const validateForm = (data) => {
    const errors = {};

    // Validate rating
    if (!validator.isInt(data.rating.toString(), { min: 1, max: 5 })) {
      errors.rating = "Rating is Required";
    }

    // Validate review
    if (validator.isEmpty(data.review)) {
      errors.review = "Review is required";
    }

    // Validate name
    if (validator.isEmpty(data.name)) {
      errors.name = "Name is required";
    }

    // Validate email
    if (!validator.isEmail(data.email)) {
      errors.email = "Invalid email address";
    }

    // Validate place
    if (validator.isEmpty(data.place)) {
      errors.place = "Place is required";
    }

    return errors;
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className="px-4 md:px-6 pt-4 pb-6 bg-white rounded-[30px] relative">
        <div className="font-semibold">
          Your reviews for our betterment, 
        </div>
        <div className="font-semibold">so we love to hear from you</div>
        <form className=" font-medium" onSubmit={handleSubmit}>
          <div className="mt-4 text-sm">
            Your email adress will not be published
          </div>
          <div className="mt-2 text-sm">Required fields are marked •</div>
          <div className="mt-5 text-sm">Your Rating *</div>
          {/* rating */}
          <div class="rating-stars fg-rating">
            {/* <input type="radio" name="rating" id="rs0" checked  />
            <label for="rs0"></label> */}
            {[1, 2, 3, 4, 5].map((value) => (
              <React.Fragment key={value}>
                <input
                  type="radio"
                  name="rating"
                  className={`rating_stars`}
                  id={`rs${value}`}
                  checked={rating === value}
                  onChange={() => {
                    handleRatingChange(value);
                  }}
                  value={formData.rating}
                />
                <label
                  htmlFor={`rs${value}`}
                  className={`${rating === 0 ? "empty_stars" : ""}`}
                ></label>
              </React.Fragment>
            ))}
            {errors.rating && (
              <p className="bg-red-500 mt-1 px-2 py-[2px] text-white text-md rounded-sm">
                {errors.rating}
              </p>
            )}
            {/* <span className="rating-counter">{rating}</span> */}
            {/* <input
              type="radio"
              class="rating_stars"
              name="rating"
              value="1"
              id="rs1"
            />
            <label for="rs1"></label>
            <input
              type="radio"
              class="rating_stars"
              name="rating"
              value="2"
              id="rs2"
            />
            <label for="rs2"></label>
            <input
              type="radio"
              class="rating_stars"
              name="rating"
              value="3"
              id="rs3"
            />
            <label for="rs3"></label>
            <input
              type="radio"
              class="rating_stars"
              name="rating"
              value="4"
              id="rs4"
            />
            <label for="rs4"></label>
            <input
              type="radio"
              class="rating_stars"
              name="rating"
              value="5"
              id="rs5"
              checked={true}
            />
            <label for="rs5"></label> */}
            <span class="rating-counter"></span>
          </div>
          {/* rating */}
          <div className="mt-5 text-sm">Your Review *</div>
          <textarea
            name="review"
            id=""
            cols="30"
            rows="3"
            className="bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-1 focus-visible:outline-0"
            value={formData.review}
            onChange={handleChange}
          ></textarea>
          {errors.review && (
            <p className="bg-red-500 mt-1 px-2 py-[2px] text-white text-md rounded-sm">
              {errors.review}
            </p>
          )}
          <div className="mt-2 text-sm">Name *</div>
          <input
            type="text"
            className="bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-1 focus-visible:outline-0"
            placeholder="NAME"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && (
            <p className="bg-red-500 mt-1 px-2 py-[2px] text-white text-md rounded-sm">
              {errors.name}
            </p>
          )}

          <div className="mt-2 text-sm">Country- City *</div>
          <input
            type="text"
            className="bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-1 focus-visible:outline-0"
            placeholder="Counrty- City"
            name="place"
            value={formData.place}
            onChange={handleChange}
          />
          {errors.place && (
            <p className="bg-red-500 mt-1 px-2 py-[2px] text-white text-md rounded-sm">
              {errors.place}
            </p>
          )}
          <div className="mt-2 text-sm">Email *</div>
          <input
            type="text"
            className="bg-[#ebeff8] px-3 py-2 rounded-lg w-full mt-1 focus-visible:outline-0"
            placeholder="email@web.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className="bg-red-500 mt-1 px-2 py-[2px] text-white text-md rounded-sm">
              {errors.email}
            </p>
          )}
          <div className="flex items-center mt-2 gap-x-3">
            <input
              type="checkbox"
              name="save_name"
              id="save_name"
              className="mb-4"
            />
            <label htmlFor="save_name" className=" cursor-pointer">
              save my name, email and website in this browser for the next time
              I connect.
            </label>
          </div>
          <div className="mt-3 flex">
            <button className={`text-black font-semibold text-lg bg-[#5e82f4] rounded-xl px-5 py-1 ${loading ?"pointer-events-none	opacity-50":""}`}>
              Submit
            </button>
          </div>
        </form>
        <span onClick={onRequestClose}
                className="absolute -top-1 -right-1 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
            >
                <FaTimes size={20} />
            </span>
      </div>
    </Modal>
  );
};

export default CustomerReviewPopup;
