import { createSlice } from "@reduxjs/toolkit";

const Testimonials = createSlice({
  name: "Testimonials",
  initialState: {
    loading: false,
    testimonials: [],
  },
  reducers: {
    TestimonialsRequest(state, action) {
      return {
        loading: true,
      };
    },
    TestimonialsSuccess(state, action) {
      return {
        loading: false,
        testimonials: action.payload.testimonials,
      };
    },
    TestimonialsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = Testimonials;

export const {
  TestimonialsRequest,
  TestimonialsSuccess,
  TestimonialsFail
} = actions;

export default reducer;
