import { createSlice } from "@reduxjs/toolkit";

const CouponCode = createSlice({
  name: "CouponCode",
  initialState: {
    loading: false,
    couponValue: null,
    couponType: null,
  },
  reducers: {
    CouponCodeRequest(state, action) {
      return {
        loading: true,
      };
    },
    CouponCodeSuccess(state, action) {
      return {
        loading: false,
        couponValue: action.payload.couponValue,
        couponType:action.payload.couponType,
      };
    },
    CouponCodeFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    CouponCodeInitial(state ,action){
      return {
        loading: false,
        couponValue: null,
        couponType: null,
      };
    }
  },
});

const { actions, reducer } = CouponCode;

export const { CouponCodeRequest, CouponCodeSuccess, CouponCodeFail , CouponCodeInitial } = actions;

export default reducer;
