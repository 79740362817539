import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import ShippingAddressPopup from "./ShippingAddressPopup";
import { useDispatch, useSelector } from "react-redux";
import { getUserAddress } from "../../actions/userDashboardActions";
import Loader from "../Loader";
import UpdateShippingAddressPopup from "./UpdateShippingAddressPopup";
const ShippingAddress = () => {
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [updatepopUpIsOpen, setUpdatePopUpIsOpen] = useState(false);
  const [popupId, setPopUpId] = useState(null);
  const { loading, addressData } = useSelector((state) => state.AddressState);
  const popUpOpenModel = () => {
    document.body.classList.add("ship_address");
    setPopUpIsOpen(true);
  };
  const popUpCloseModel = () => {
    document.body.classList.remove("ship_address");
    setPopUpIsOpen(false);
  };
  const updatePopUpOpenModel = (id) => {
    setPopUpId(id);
    document.body.classList.add("ship_address");
    setUpdatePopUpIsOpen(true);
  };
  const updatePopUpCloseModel = () => {
    document.body.classList.remove("ship_address");
    setUpdatePopUpIsOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAddress);
  }, []);

  return (
    <>
      <div className="mt-3 sm:mt-4">
        <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
          <div
            className="py-4 px-5  border-2 theme_green font-medium flex flex-col gap-x-3 rounded-xl justify-center items-center text-xl cursor-pointer"
            onClick={popUpOpenModel}
          >
            <div className=" text-center">Add Address</div>
            <div className=" text-center">
              <BiPlus className="text-lg sm:text-lg md:text-xl" />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {addressData &&
                addressData.map((address_data) => (
                  <div
                    key={address_data?.id}
                    className="pb-4 border border-2 theme_green font-medium rounded-xl justify-center items-center"
                  >
                    <div className="text-white w-full theme_bg_green flex justify-between px-2 rounded-t-xl py-1.5">
                      <span className=" text-[16px] font-semibold flex-1 text-center">
                        {address_data?.address_type === "default_address" &&
                          "Default Address"}
                        {address_data?.address_type === "office_address" &&
                          "Office Address"}
                        {address_data?.address_type === "home_address" &&
                          "Home Address"}
                      </span>
                      <span
                        onClick={() => updatePopUpOpenModel(address_data?.id)}
                        className=" cursor-pointer text-sm font-normal"
                      >
                        Edit
                      </span>
                    </div>
                    <div className="pt-2 text-xs text-black px-3">
                      <p>
                        {address_data?.first_name} {address_data?.last_name}
                      </p>
                      <p className="pt-[2px]">
                        {address_data?.address} ,{address_data?.city}-
                        {address_data?.pincode}
                      </p>
                      <p className="pt-[2px] capitalize">
                        {address_data?.state},{address_data?.country}
                      </p>
                      <p className="pt-[2px] capitalize">
                        {address_data?.phone_number}
                      </p>
                    </div>
                    {popupId === address_data?.id && (
                      <UpdateShippingAddressPopup
                        isOpen={updatepopUpIsOpen}
                        onRequestClose={updatePopUpCloseModel}
                        shippingData ={address_data}
                      />
                    )}
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <ShippingAddressPopup
        isOpen={popUpIsOpen}
        onRequestClose={popUpCloseModel}
      />
    </>
  );
};

export default ShippingAddress;
