import axios from "axios"
import { menusFail, menusRequest, menusSuccess } from "../slices/menusSlice"
import {backendUrl} from "../config/config";

export const getMenus = async (dispatch) => {
    try {
        dispatch(menusRequest())
        const { data } = await axios.get(backendUrl + 'menu')
        dispatch(menusSuccess(data))
    } catch (error) {
        dispatch(menusFail(error.response.data.message));
    }
}