import { createSlice } from "@reduxjs/toolkit";

const productsByConcernCategorySlice = createSlice({
  name: "concernProducts",
  initialState: {
    loading: false,
    products: [],
    subCategory: [],
  },
  reducers: {
    subConcernCategoryproductsRequest(state, action) {
      return {
        loading: true,
      };
    },
    subConcernCategoryproductsSuccess(state, action) {
      return {
        loading: false,
        products: action.payload.products,
      };
    },
    subConcernCategoryproductsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = productsByConcernCategorySlice;

export const {
  subConcernCategoryproductsRequest,
  subConcernCategoryproductsSuccess,
  subConcernCategoryproductsFail,
} = actions;

export default reducer;
