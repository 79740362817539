import React, { useState, useEffect } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import validator from "validator";
import { useNavigate } from "react-router";
import { backendUrl } from "../config/config";
import axios from "axios";
import { CheckUserLogin } from "../utils/utils";
import Steps from "../components/Checkout/Steps";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getCouponValue } from "../actions/userDashboardActions";
import Loader from "../components/Loader";
import FullpageLoader from "../components/FullpageLoader";
import { toast } from "react-toastify";
import { getCarts, removeSingleCart } from "../actions/CartActions";
import { FaTimes } from "react-icons/fa";

const Payment = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [errors, setErrors] = useState({ radio: "" });
  const [loading, setLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [shippingAmount, setShippingAmount] = useState(250);
  const [totalSalePrice, setTotalSalePrice] = useState(0);
  const [finalCartData, setFinalCartData] = useState([]);
  const [unavailabeProducts, setUnavailableProducts] = useState([]);
  // const handleOptionChange = (e) => {
  //   setSelectedOption(e.target.value);
  // };
  const { couponValue, loading: couponLoading } = useSelector(
    (state) => state.CouponCodeState
  );
  const { CartData, loading: cartLoading } = useSelector(
    (state) => state.getCartState
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newErrors = {};

    // Validate the radio button
    if (!selectedOption) {
      setLoading(false);
      newErrors.radio = "Please select Payment Method";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      let isUserLoggedIn = true;
      let token = localStorage.getItem("auth_Token");
      if (token && token != null) {
        const checkUSer = await CheckUserLogin(token);
        if (!checkUSer) {
          isUserLoggedIn = false;
        }
      } else {
        isUserLoggedIn = false;
      }
      if (isUserLoggedIn === false) {
        navigate("/");
      } else {
        const checkoutDatas = JSON.parse(localStorage.getItem("checkoutData"));
        const shippingMethod = localStorage.getItem("shippingMethod");
        const ShippingAddress = localStorage.getItem("ShippingAddress");
        const cartData = JSON.parse(localStorage.getItem("cart"));
        const sendData = {
          cartData: cartData,
          checkoutData: checkoutDatas,
          shippingMethod: shippingMethod,
          ShippingAddress: ShippingAddress,
          couponValue: couponValue,
        };
        try {
          const response = await axios.post(
            backendUrl + "checkout-products",
            sendData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Handle the success response from the backend here
          if (response.data.status === 1) {
            createOrder(response.data.checkoutId);
          } else if (response.data.status === 2) {
            dispatch(getCarts);
            setUnavailableProducts(response?.data?.unavailabeProducts);
            const shippingMethod = localStorage.getItem("shippingMethod");
            AmountWork(cartData, shippingMethod);
            // const updatedCartData = getUnavaileProducts(cartData);d
            toast.error("some products are not available now!");
          }

          setLoading(false);
        } catch (error) {
          // Handle any errors that occur during the request
          console.error("Error sending data:", error);
          setLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    if (unavailabeProducts.length > 0) {
      // Call getUnavaileProducts when unavailableProducts or cartData changes
      const updatedCartData = getUnavaileProducts(
        checkcart,
        unavailabeProducts
      );
      setFinalCartData(updatedCartData);
      // Do something with updatedCartData, if needed
    }
  }, [unavailabeProducts]);
  const removeCart = async (productId, VariationId) => {
    await dispatch(removeSingleCart(productId, VariationId));
    await dispatch(getCarts);
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    const updatedCartData = getUnavaileProducts(checkcart);
    setFinalCartData(updatedCartData);
    const shippingMethod = localStorage.getItem("shippingMethod");
    AmountWork(checkcart, shippingMethod);
    if (checkcart === null) {
      navigate("/");
    } else {
      if (checkcart.length <= 0) {
        navigate("/");
      }
    }
  };
  const getUnavaileProducts = (checkcart) => {
    const updatedCartData = checkcart.map((cartItem) => {
      const matchingUnavailableProduct = unavailabeProducts.find(
        (unavailableProduct) => unavailableProduct.id === cartItem.variationId
      );

      if (matchingUnavailableProduct) {
        // If a match is found, add the "unavailable: true" property
        return {
          ...cartItem,
          unavailable: true,
        };
      }

      // If no match is found, return the original cart item
      return cartItem;
    });
    return updatedCartData;
  };
  const checkuser = async () => {
    let isUserLoggedIn = true;
    let token = localStorage.getItem("auth_Token");

    if (token && token != null) {
      const checkUSer = await CheckUserLogin(token);
      if (!checkUSer) {
        isUserLoggedIn = false;
      }
    } else {
      isUserLoggedIn = false;
    }
    if (isUserLoggedIn === false) {
      navigate("/");
    }
  };
  useEffect(() => {
    setSelectedOption("cod");
    dispatch(getCarts);
    checkuser();
    const checkcart = JSON.parse(localStorage.getItem("cart"));
    if (checkcart === null) {
      navigate("/");
    } else {
      if (checkcart.length <= 0) {
        navigate("/");
      }
    }
    // Check if data exists in local storage
    const checkoutDatas = JSON.parse(localStorage.getItem("checkoutData"));

    if (!checkoutDatas) {
      // Data does not exist, so navigate to a different page
      navigate("/checkout");
    }
    const shippingMethod = localStorage.getItem("shippingMethod");

    if (!shippingMethod) {
      // Data does not exist, so navigate to a different page
      navigate("/shipping");
    }

    // Retrieve data from localStorage
    const cartData = JSON.parse(localStorage.getItem("cart"));
    AmountWork(cartData, shippingMethod);

  }, []);
  const AmountWork = (cartData, shippingMethod) => {

    if (cartData) {
      // Set cart items from localStorage
      setCartItems(cartData);

      // Calculate total sale price
      const totalPrice = cartData.reduce((total, item) => {
        // Access sale_price from the variation object
        return total + parseFloat(item.variation.sale_price) * item.quantity;
      }, 0);
      dispatch(getCouponValue(totalPrice));
      setTotalSalePrice(totalPrice);
      if (shippingMethod === "amazon") {
        setShippingAmount(250);
      } else if (shippingMethod === "indianPost") {
        setShippingAmount(150);
      } else if (shippingMethod === "freeShip" && totalPrice > 1499) {
        setShippingAmount(0);
      }
    }
  };
  const createOrder = async (checkoutId) => {
    try {
      const options = {
        key: process.env.REACT_APP_RAZOR_KEY,
        key_secret: process.env.REACT_APP_RAZOR_SECRET,
        amount:
          couponValue != null
            ? (totalSalePrice + shippingAmount - couponValue) * 100
            : (totalSalePrice + shippingAmount) * 100,
        currency: "INR",
        name: "The Herbkey",
        description: "Payment for your product/service",
        handler: async function (response) {
          let token = localStorage.getItem("auth_Token");
          const checkoutDatas = JSON.parse(
            localStorage.getItem("checkoutData")
          );

          if (response.razorpay_payment_id !== "") {
            setPageLoad(true);
            const sendData = {
              paymentId: response.razorpay_payment_id,
              checkoutId: checkoutId,
              checkoutData: checkoutDatas,
            };
            const res = await axios.post(
              backendUrl + "complete-razorpay-checkout",
              sendData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            // Handle the success response from the backend here
            if (res.data.status === 1) {
              localStorage.removeItem("checkoutData");
              localStorage.removeItem("ShippingAddress");
              localStorage.removeItem("shippingMethod");
              localStorage.removeItem("couponCode");
              localStorage.removeItem("cart");
              localStorage.setItem("payment-success", 1);
              navigate("/payment-success");
            }
            setPageLoad(false);
          }
          // Handle success, e.g., update order status in your backend
        },
        prefill: {
          name: "",
        },
        theme: {
          color: "#4f7661",
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  return (
    <>
      {pageLoad ? (
        <FullpageLoader />
      ) : (
        <>
          <Header disabledCart={true} />

          {/* <div className='container mx-auto px-1 sm:px-2 md:px-3'>
                <h4 className=' text-lg sm:text-xl uppercase text-center font-medium '>Your order placed successfully </h4>
                <h4 className=' text-lg sm:text-xl uppercase text-center font-medium mt-3'>Your payment is done</h4>
                <div className=' text-center mt-4'>
                    <Link to='/' className=' underline'>Return to Home</Link>
                </div>
             </div> */}
          {couponLoading ? (
            <Loader />
          ) : (
            <div className="container mx-auto px-3 sm:px-2 md:px-3 text-center">
              <Steps linkname="payment" />
              <div className=" flex gap-x-4 gap-y-5 mt-4 flex-wrap lg:flex-nowrap">
                <form
                  onSubmit={handleSubmit}
                  className="w-full sm:w-full md:w-full lg:w-3/5"
                >
                  <div className="mt-3 sm:mt-4 md:mt-5">
                    <span className=" text-lg">Payment Method</span>
                    <div className=" border rounded-lg py-2 px-3 text-xs mt-4 ship_method">
                      <div className="pb-2 flex items-center justify-between">
                        <span className=" flex w-[70%] items-center">
                          <span className="w-[10%]">
                            <input
                              type="radio"
                              name="shippingMethod"
                              id="payment_method"
                              value="cod"
                              checked={selectedOption === "cod"}
                              // onChange={handleOptionChange}
                            />{" "}
                          </span>
                          <label
                            htmlFor="payment_method"
                            className=" ml-4 w-[80%] text-left"
                          >
                            Razorpay
                          </label>
                        </span>
                        <span className="font-medium cursor-pointer">
                          ₹{" "}
                          {couponValue != null
                            ? totalSalePrice + shippingAmount - couponValue
                            : totalSalePrice + shippingAmount}
                        </span>
                      </div>
                    </div>
                    {errors.radio && (
                      <div className="mt-2">
                        <span className="px-2 py-1 rounded bg-red-400 text-white block">
                          {errors.radio}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className=" mt-3 sm:mt-4 md:mt-5">
                    <div className=" flex items-center justify-between border-b pb-3 border-b-[#b5b5b5] flex-wrap sm:flex-nowrap gap-y-2">
                      <Link
                        to="/shipping"
                        className=" opacity-40 font-medium text-[13px] flex items-center w-full sm:w-1/2"
                      >
                        <span>
                          <MdOutlineKeyboardArrowLeft size={18} />
                        </span>
                        <span>Return to Shipping</span>{" "}
                      </Link>
                      <button
                        typpe="submit"
                        className={`text-white font-medium text-base sm:text-base md:text-lg bg-[#ff8a00] px-4 py-1.5 rounded-2xl text-center w-full sm:w-1/2  ${
                          loading &&
                          " cursor-wait pointer-events-none opacity-50"
                        }`}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </form>
                <div className="w-full sm:w-full md:w-full lg:w-2/5">
                  {finalCartData &&
                    finalCartData.map((cart, index) => (
                      <div className="px-2 sm:px-3 pb-4 mt-3" key={index}>
                        <div className=" px-3 py-2 bg-white rounded-lg shadow-md relative">
                          {cart?.unavailable && (
                            <span className=" absolute top-1/2 left-1/2 -translate-x-1/2 rounded -translate-y-1/2  min-w-3/5 text-white h-2 bg-red-500 h-auto">
                              Remove This
                            </span>
                          )}
                          <div className=" flex items-center justify-between">
                            <div className=" flex items-center gap-x-3 w-[45%]">
                              <img
                                src={cart?.image_link}
                                alt="cartImage"
                                className="w-[35px] h-[45px] object-cover"
                              />
                              <div className=" flex flex-col">
                                <h3 className=" font-medium text-base">
                                  {cart?.title}
                                </h3>
                                <span className=" text-xs text-left">
                                  {cart?.variation?.variation_name}{" "}
                                  {cart?.variation?.variation_type_name}
                                </span>
                              </div>
                            </div>
                            <div className=" text-base w-[30%] text-right">
                              ₹{" "}
                              <span>
                                {cart?.variation?.sale_price * cart?.quantity}
                              </span>
                            </div>
                          </div>
                          <span
                            className="absolute -top-2 -right-2 text-[#bc2128] border border-[#bc2128] bg-white p-1 rounded-full cursor-pointer"
                            onClick={() =>
                              removeCart(cart?.id, cart?.variation?.id)
                            }
                          >
                            <FaTimes size={10} />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* <div className='mt-3'>Payment Method</div> */}
              {/* <form onSubmit={handleSubmit}>
                    <div className='mt-4'>
                        <input type='radio' value='cod' id='payment_method' checked={selectedOption === 'cod'}
                            onChange={handleOptionChange} />
                        <label htmlFor='payment_method' className='ml-3'>COD</label>
                    </div>
                    {errors.radio && <div className='mt-2'><span className='px-2 py-1 rounded bg-red-400 text-white inline-block'>{errors.radio}</span></div>}
                    <button type='submit' className={`px-3 py-1 rounded theme_bg_green mt-3 text-white inline-block ${loading && ' cursor-wait pointer-events-none opacity-50'}`}>Pay Now</button>
                </form> */}
            </div>
          )}
          <Footer />
        </>
      )}
    </>
  );
};

export default Payment;
