import { createSlice } from "@reduxjs/toolkit";

const productsByCategorySlice = createSlice({
  name: "products",
  initialState: {
    loading: false,
    products: [],
    subCategory: [],
  },
  reducers: {
    subCategoryproductsRequest(state, action) {
      return {
        loading: true,
      };
    },
    subCategoryproductsSuccess(state, action) {
      return {
        loading: false,
        products: action.payload.products,
      };
    },
    subCategoryproductsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },

    getProductsbySubcategorySlugRequest(state, action) {
      return {
        loading: true,
      };
    },
    getProductsbySubcategorySlugSuccess(state, action) {
      return {
        loading: false,
        products: action.payload.products,
        subCategory: action.payload.subCategory,
      };
    },
    getProductsbySubcategorySlugFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = productsByCategorySlice;

export const {
  subCategoryproductsRequest,
  subCategoryproductsSuccess,
  subCategoryproductsFail,
  getProductsbySubcategorySlugRequest,
  getProductsbySubcategorySlugSuccess,
  getProductsbySubcategorySlugFail,
} = actions;

export default reducer;
