import React, { Fragment, useEffect, useState } from "react";
import TopHeader from "../components/TopHeader";
import Header from "../components/Header";
import Component from "../components/BestSellers/Component";
import Footer from "../components/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getConcernCategory } from "../actions/categoryActions";
import { backendImageUrl } from "../config/config";
import Loader from "../components/Loader";
import { getConcernCategoryProducts } from "../actions/subcategoryProductsActions";
const ConcernCategory = () => {
  const [hidePrevComponent, setHidePrevComponent] = useState(true);
  const [tabBgColor, setTabBgColor] = useState(0);

  const { products } = useSelector(
    (state) => state.subConcerncategoryProductsState
  );
  const { loading, category, subcategory } = useSelector(
    (state) => state.concernCategoryState
  );

  const dispatch = useDispatch();
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getConcernCategory(slug));
  }, [dispatch]);

  const handleTabLink = (id, index) => {
    dispatch(getConcernCategoryProducts(id));
    setHidePrevComponent(false);
    setTabBgColor(index);
  };
  const changeCategory = (slug) => {
    dispatch(getConcernCategory(slug));
  };

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="w-full h-[85vh] lg:h-[80vh] relative bann_img">
            <img
              src={backendImageUrl + category?.banner_image}
              alt=""
              className="h-full object-cover w-full"
            />
            <div className="absolute bottom-6 left-14 text-2xl font-medium">
              {category ? category.banner_title : ""}
            </div>
          </div>

          <div className=" bg-[#e6e7eb]">
            <div className=" overflow-x-auto pb-[5px] sm:pb-[5px] md:pb-0">
              <div className=" overflow-x-auto min-w-max sm:min-w-max md:min-w-max lg:min-w-full">
                <div className=" container mx-auto px-10 flex-nowrap sm:flex-nowrap md:flex-nowrap lg:flex-wrap min-w-max lg:min-w-full flex gap-3  justify-center">
                  {subcategory
                    ? subcategory &&
                      subcategory.map(
                        (subcat, index) =>
                          subcat.products && (
                            <span
                              key={subcat.id}
                              className={`cursor-pointer inline-block py-3 px-4  text-white font-medium rounded-b-xl min-w-[200px] text-center ${
                                index === tabBgColor
                                  ? "bg-[#ff7200]"
                                  : "bg-[#adbbaa]"
                              }`}
                              onClick={() => handleTabLink(subcat.id, index)}
                            >
                              {subcat.name}
                            </span>
                          )
                      )
                    : ""}
                </div>
              </div>
            </div>

            <div className=" container mx-auto pt-20 px-8 sm:px-16 md:px-20 lg:px-28">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5 gap-y-6">
                {hidePrevComponent && products && subcategory
                  ? subcategory &&
                    subcategory.map(
                      (subcat, index) =>
                        subcat.products &&
                        subcat.products.map(
                          (product) =>
                            index === 0 && (
                              <Component
                                key={product.id}
                                productObject={product}
                                productId={product.id}
                                pro_slug={product.slug}
                                name={product.title}
                                pro_name={product.short_desc}
                                stars={5}
                                img_link={product.product_image}
                                variations={product.variation}
                                getvariation={false}
                              />
                            )
                        )
                    )
                  : ""}
                {!hidePrevComponent &&
                  products &&
                  products.map((product, index) => (
                    <Component
                      key={product.id}
                      productObject={product}
                      productId={product.id}
                      pro_slug={product.slug}
                      name={product.title}
                      pro_name={product.short_desc}
                      stars={5}
                      img_link={product.product_image}
                      variations={product.variation}
                    />
                  ))}
              </div>
            </div>
            <div className="pt-8 container mx-auto px-10 flex gap-3 flex-wrap font-medium text-white justify-center text-center">
              <Link
                onClick={() => changeCategory("skin")}
                to={"/concern-category/skin"}
                className={`px-3 cursor-pointer  min-w-[150px] py-3 bg-[#9bb2f8] rounded-t-md ${
                  slug === "skin" ? "bg-[#ff7200]" : ""
                }`}
              >
                Face Care
              </Link>
              <Link
                onClick={() => changeCategory("baby")}
                to={"/concern-category/baby"}
                className={`px-3 cursor-pointer  min-w-[150px] py-3 bg-[#9bb2f8] rounded-t-md ${
                  slug === "baby" ? "bg-[#ff7200]" : ""
                }`}
              >
                Baby Care
              </Link>
              <Link
                onClick={() => changeCategory("hair")}
                to={"/concern-category/hair"}
                className={`px-3 cursor-pointer  min-w-[150px] py-3 bg-[#9bb2f8] rounded-t-md ${
                  slug === "hair" ? "bg-[#ff7200]" : ""
                }`}
              >
                Hair Care
              </Link>
            </div>
          </div>
        </Fragment>
      )}
      <Footer />
    </>
  );
};

export default ConcernCategory;
