import axios from "axios";
import { backendUrl } from "../config/config";
import {
  accountDetailsFail,
  accountDetailsRequest,
  accountDetailsSuccess,
} from "../slices/accountDetails";
import {
  myOrdersFail,
  myOrdersRequest,
  myOrdersSuccess,
} from "../slices/myOrdersDetails";
import {
  AddressFail,
  AddressRequest,
  AddressSuccess,
} from "../slices/AddressSlice";
import {
  CouponCodeFail,
  CouponCodeInitial,
  CouponCodeRequest,
  CouponCodeSuccess,
} from "../slices/CouponCode";
import { TestimonialsFail, TestimonialsRequest, TestimonialsSuccess } from "../slices/Testimonials";
import { WishlistFail, WishlistRequest, WishlistSuccess } from "../slices/Wishlist";
import { subscribeModalSliceFail, subscribeModalSliceRequest, subscribeModalSliceSuccess } from "../slices/subscribeModalSlice";

let token = localStorage.getItem("auth_Token");

export const getAccountDetails = async (dispatch) => {
  try {
    dispatch(accountDetailsRequest());
    const { data } = await axios.get(backendUrl + `get-ac-details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(accountDetailsSuccess(data));
  } catch (error) {
    dispatch(accountDetailsFail(error.response.data.message));
  }
};
export const getWishListDetails = async (dispatch) => {
  try {
    dispatch(WishlistRequest());
    const { data } = await axios.get(backendUrl + `get-wishlist-details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(WishlistSuccess(data));
  } catch (error) {
    dispatch(WishlistFail(error.response.data.message));
  }
};
export const getMyOrders = async (dispatch) => {
  try {
    dispatch(myOrdersRequest());
    const { data } = await axios.get(backendUrl + `get-myorders-details`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(myOrdersSuccess(data));
  } catch (error) {
    dispatch(myOrdersFail(error.response.data.message));
  }
};

export const getUserAddress = async (dispatch) => {
  try {
    dispatch(AddressRequest());
    const { data } = await axios.get(backendUrl + `get-all-address`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(AddressSuccess(data));
  } catch (error) {
    dispatch(AddressFail(error.response.data.message));
  }
};
export const getCouponValue = amount => async (dispatch) => {
   
  let discountCode = localStorage.getItem("couponCode");

  if (discountCode && discountCode != null) {
    const sendData = {
      discountCode: discountCode,
      totalAmount:amount
    };
    try {
      dispatch(CouponCodeRequest());
      const { data } = await axios.post(backendUrl + "get-discount", sendData);
      dispatch(CouponCodeSuccess(data));
    } catch (error) {
      dispatch(CouponCodeFail(error.response.data.message));
    }
  } else {
    dispatch(CouponCodeInitial());
  }
};


export const getTestimonials =  async (dispatch) => {
  try {
      dispatch(TestimonialsRequest())
      const { data } = await axios.get(backendUrl + `get-testimonials`)
      dispatch(TestimonialsSuccess(data))
  } catch (error) {
      dispatch(TestimonialsFail(error.response.data.message));
  }
}
export const getSubscribeModal =  async (dispatch) => {
  try {
      dispatch(subscribeModalSliceRequest())
      const { data } = await axios.get(backendUrl + `get-subscribe-modal`)
      dispatch(subscribeModalSliceSuccess(data))
  } catch (error) {
      dispatch(subscribeModalSliceFail(error.response.data.message));
  }
}