import axios from "axios";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { backendUrl } from "../../config/config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "../Loader";
import { getAccountDetails } from "../../actions/userDashboardActions";

const AccountDetails = () => {
  const [clickLoad, setClockLoad] = useState(false);
  const [loadData, setLoadData] = useState([]);
  const dispatch = useDispatch();
  const { loading, userDetails } = useSelector(
    (state) => state.accountDetailsState
  );
  useEffect(() => {
    dispatch(getAccountDetails);
  }, [])
  
  useEffect(() => {
    setLoadData(userDetails);
    setFormData({
      firstname: loadData?.firstName ? loadData?.firstName : "",
      lastname: loadData?.lastName ? loadData?.lastName : "",
      email: loadData?.email ? loadData?.email : "",
      contactnumber: loadData?.contactNumber ? loadData?.contactNumber : "",
      gender: loadData?.gender ? loadData?.gender : "",
      day: loadData?.day ? loadData?.day : "",
      month: loadData?.month ? loadData?.month : "",
      year: loadData?.year ? loadData?.year : "",
    });
  }, [userDetails]);

  const [formData, setFormData] = useState({
    firstname: loadData?.firstName ? loadData?.firstName : "",
    lastname: loadData?.lastName ? loadData?.lastName : "",
    email: loadData?.email ? loadData?.email : "",
    contactnumber: loadData?.contactNumber ? loadData?.contactNumber : "",
    gender: "",
    day: loadData?.day ? loadData?.day : "",
    month: loadData?.month ? loadData?.month : "",
    year: loadData?.year ? loadData?.year : "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};

    if (validator.isEmpty(formData.firstname)) {
      validationErrors.firstname = "First name is required.";
    }

    if (validator.isEmpty(formData.lastname)) {
      validationErrors.lastname = "Last name is required.";
    }

    if (!validator.isEmail(formData.email)) {
      validationErrors.email = "Invalid email address.";
    }

    if (
      !validator.isMobilePhone(formData.contactnumber.toString(), "any", {
        strictMode: false,
      })
    ) {
      validationErrors.contactnumber = "Invalid phone number.";
    }

    if (validator.isEmpty(formData.gender)) {
      validationErrors.gender = "Please select a gender.";
    }
    // Validate day
    if (!validator.isInt(formData.day.toString(), { min: 1, max: 31 })) {
      validationErrors.day = "Invalid day";
    }

    // Validate month
    if (!validator.isInt(formData.month.toString(), { min: 1, max: 12 })) {
      validationErrors.month = "Invalid month";
    }

    // Validate year
    if (!validator.isInt(formData.year.toString(), { min: 1900, max: 2100 })) {
      validationErrors.year = "Invalid year";
    }
    setClockLoad(true);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setClockLoad(false);
    } else {
      setErrors({});
      try {
        let token = localStorage.getItem("auth_Token");
        // Perform the API request here
        const response = await axios.post(
          backendUrl + "update-account-details",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status === "1") {
          toast.success("Account Details Updated Succesful", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
        if (response.data.status === "0") {
          toast.success("This email already exist", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }
        setClockLoad(false);
      } catch (error) {
        // Handle API request errors (e.g., show error message)
        if (error?.response?.data?.errors?.email['0']) {
              toast.error(error?.response?.data?.errors?.email['0'], {
                position: "top-right",
                autoClose: 3000, // Auto-close the toast after 3 seconds
              });
        } else {
          toast.error("Something went wrong , Please try again !", {
            position: "top-right",
            autoClose: 3000, // Auto-close the toast after 3 seconds
          });
        }

        setClockLoad(false);
      }
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="mt-3 sm:mt-4">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center align-center gap-x-3 flex-wrap sm:flex-wrap md:flex-nowrap gap-y-2">
          <div className="w-full sm:w-full md:w-1/2">
            <input
              type="text"
              className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
            />
            {errors.firstname && (
              <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                {errors.firstname}
              </div>
            )}
          </div>
          <div className="w-full sm:w-full md:w-1/2">
            <input
              type="text"
              className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
            />
            {errors.firstname && (
              <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
                {errors.lastname}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center align-center pt-2 sm:pt-3 md:pt-4">
          <input
            type="text"
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="Email ID"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
              {errors.email}
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center align-center pt-2 sm:pt-3 md:pt-4">
          <input
            type="text"
            className="bg-[#edf1f0] text-sm rounded-md px-4 py-1 sm:py-1 md:py-2 placeholder:text-[14px] focus-within:outline-0 placeholder:font-medium w-full"
            placeholder="Contact Number"
            name="contactnumber"
            value={formData.contactnumber}
            onChange={handleInputChange}
          />
          {errors.contactnumber && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
              {errors.contactnumber}
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center align-center pt-2 sm:pt-3 md:pt-4 acc_det">
          <span className=" flex justify-center items-center">
            <span className="pr-3 vertical-middle">Birthday</span>
            <div className="flex gap-x-2">
              <input
                type="text"
                className={`${
                  errors.day ? "border-red-400 border-2" : ""
                } bg-[#edf1f0] max-w-[45px] text-center pl-2 py-1 vertical-middle inline-block focus-within:outline-0 rounded-md px-2 `}
                placeholder="DD"
                name="day"
                value={formData.day}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className={`${
                  errors.month ? "border-red-400 border-2" : ""
                } max-w-[45px] text-center pl-2 py-1 vertical-middle inline-block focus-within:outline-0 rounded-md px-2 bg-[#edf1f0]`}
                placeholder="MM"
                name="month"
                value={formData.month}
                onChange={handleInputChange}
              />
              <input
                type="text"
                className={`${
                  errors.year ? "border-red-400 border-2" : ""
                } max-w-[70px] text-center pl-2 py-1 vertical-middle inline-block focus-within:outline-0 rounded-md px-2 bg-[#edf1f0]`}
                placeholder="YYYY"
                name="year"
                value={formData.year}
                onChange={handleInputChange}
              />
            </div>
          </span>
        </div>

        <div className="flex flex-col justify-center align-center pt-2 sm:pt-3 md:pt-4 acc_det">
          <span className=" flex justify-center items-center">
            <span className="pr-3 vertical-middle">Gender</span>
            <input
              type="radio"
              id="gender1"
              className="pl-2 vertical-middle"
              name="gender"
              value="male"
              checked={formData.gender === "male"}
              onChange={handleInputChange}
            />
            <label className="pl-1 pr-4 cursor-pointer" htmlFor="gender1">
              Male
            </label>{" "}
            <input
              type="radio"
              id="gender2"
              name="gender"
              value="female"
              checked={formData.gender === "female"}
              onChange={handleInputChange}
            />
            <label className="pl-1 pr-4 cursor-pointer" htmlFor="gender2">
              Female
            </label>{" "}
            <input
              type="radio"
              id="gender3"
              name="gender"
              value="others"
              checked={formData.gender === "others"}
              onChange={handleInputChange}
            />
            <label className="pl-1 cursor-pointer" htmlFor="gender3">
              Others
            </label>
          </span>
          {errors.gender && (
            <div className=" bg-red-400 text-white rounded-md py-1 px-1.5 mt-1">
              {errors.gender}
            </div>
          )}
        </div>
        <div className="flex justify-center align-center pt-2 sm:pt-3 md:pt-4">
          <button
            className={`px-3 py-2 text-center text-white font-medium theme_bg_green rounded-xl ${
              clickLoad ? " opacity-50 pointer-events-none	" : ""
            }`}
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccountDetails;
