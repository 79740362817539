import React, { useState } from 'react'
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import { FaPlay } from "react-icons/fa";
import VideoPopup from './VideoPopup';


const Component = ({ title, subtitle, titleUrl,imageUrl,videoUrl }) => {


  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => {
    setModalIsOpen(true);
    document.body.classList.add('scroll_disable');
  };

  const closeModal = () => {
    document.body.classList.remove('scroll_disable');
    setModalIsOpen(false);
  };

  return (
    <div className='relative text-white border mx-3 text-center h-[350px] sm:h-[350px] md:h-[350px] lg:h-[350px] xl:h-[420px] rounded-lg'>
      <img src={imageUrl} alt="imageUrl" className='h-full object-cover w-full rounded-lg'/>
      <div className=' absolute bottom-3 left-1/2 -translate-x-1/2 w-full'>
        <div className='text-xl font-semibold'>{title}</div>
        <div className='pt-2  flex justify-center'>
          <a href={titleUrl} className='flex gap-x-1 items-center relative text-xs'>
            <span>{subtitle} </span><HiOutlineArrowSmRight className='text-base' />
          </a>
        </div>
      </div>
      <div onClick={openModal} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-black text-5xl opacity-80  cursor-pointer'>
        <span className='py_btn'> <FaPlay /></span>
      </div>
      <VideoPopup isOpen={modalIsOpen} onRequestClose={closeModal} videoUrl={videoUrl}/>
    </div>
  )
}

export default Component