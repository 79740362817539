import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Component from "./Component";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getBts } from "../../actions/GeneralActions";
import Loader from "../../components/Loader";
import { backendImageUrl } from "../../config/config";
const BehindTheScenes = () => {
  const dispatch = useDispatch();

  const { bts, loading } = useSelector((state) => state.btsState);
  useEffect(() => {
    dispatch(getBts);
  }, []);

  const carouselSettings = {
    dots: false,
    infinite: true,
    draggable: true,
    speed: 500,
    slidesToShow: bts && bts.length >= 4 ? 4 : bts && bts.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />, // Custom component for previous arrow
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1181,
        settings: {
          slidesToShow: bts && bts.length >= 3 ? 3 : bts && bts.length,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: bts && bts.length >= 2 ? 2 : bts && bts.length,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: bts && bts.length >= 1 ? 1 : bts && bts.length,
        },
      },
    ],
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="container mx-auto py-4">
      <div className="p-2 text-center">
        <div className=" text-2xl font-semibold theme_green">
          Behind the Scenes{" "}
        </div>
        <p className="w-full md:w-4/5 lg:w-[50%] xl:w-[30%] mx-auto text-[14px] text-[#757575] font-medium pt-1 mt-2">
          To Ensure Transparency, we are delighted to take our customers on a
          journey to show how our products are made before placing your orders.
        </p>
      </div>
      <div className="pt-3 pb-1 px-5 sm:px-5 md:px-5 lg:px-6 bts_parent relative">
        <Slider {...carouselSettings}>
          {bts &&
            bts.map((singleData) => (
              <Component
                key={singleData?.id}
                title={singleData?.title}
                imageUrl={backendImageUrl + singleData?.image}
                subtitle={singleData?.subtitle}
                titleUrl={singleData?.link}
                videoUrl={singleData?.video_link}
              />
            ))}
        </Slider>
      </div>
    </div>
  );
};
// Custom component for previous arrow
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="text-3xl font-semibold absolute top-1/2 left-0 -translate-y-1/2 cursor-pointer z-10"
    >
      <IoIosArrowBack />
    </div>
  );
};

// Custom component for next arrow
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="text-3xl font-semibold absolute top-1/2 right-0 -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
    >
      <IoIosArrowForward />
    </div>
  );
};

export default BehindTheScenes;
