import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer/Footer";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdArrowDropdown } from "react-icons/io";
import validator from "validator";
import axios from "axios";
import { backendUrl } from "../config/config";
import { toast } from "react-toastify";

const ContactUs = () => {
  const [reason, setreason] = useState("");
  const [reasonToggle, setreasonToggle] = useState(false);
  const initialFormData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
    reason: reason,
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Validation logic
    const validationErrors = {};

    if (validator.isEmpty(formData.first_name)) {
      validationErrors.first_name = "First name is required";
    }

    if (validator.isEmpty(formData.last_name)) {
      validationErrors.last_name = "Last name is required";
    }

    if (!validator.isEmail(formData.email)) {
      validationErrors.email = "Invalid email address";
    }
    if (validator.isEmpty(formData.contact_number)) {
      validationErrors.contact_number = "Contact Number is required";
    } else if (!validator.isNumeric(formData.contact_number)) {
      // Check if contact_number is not a valid number
      validationErrors.contact_number = "Contact Number must be a valid number";
    }
    if (reason === "") {
      validationErrors.reason = "Reason is required";
    }
    if (validator.isEmpty(formData.message)) {
      validationErrors.message = "Message is required";
    }

    // Add similar validations for other fields (contact_number, reason, message, attachment_link)

    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, proceed with submission logic
      setFormData({ ...formData, reason: reason });
      const updatedFormData = {
        ...formData,
        reason // Update with the new reason value
      };
      setErrors({});
      try {
        const response = await axios.post(
          backendUrl + "contact-us-mail",
          updatedFormData
        );
        // Handle success, redirect, or update UI as needed
        if (response.data.status === 1) {
          setFormData(initialFormData);
          toast.success("Mail send successfully!");
          setreason("");
        }
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong , Please try again !");
        // Handle error and update UI accordingly
        setLoading(false);
      }
    } else {
      // Update state with validation errors
      setErrors(validationErrors);
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="container mx-auto px-3 sm:px-3 md:px-5 lg:px-8">
        <div className="theme_bg_green text-center py-3 sm:py-4 md:py-6 text-md md:text-xl lg:text-2xl rounded-3xl shadow-lg text-white">
          Contact Us
        </div>
        <form
          className="mt-3 md:mt-4 lg:mt-5 max-w-[600px] mx-auto"
          onSubmit={handleSubmit}
        >
          <label className=" font-medium">Name *</label>
          <div className="mt-1.5 flex justify-between gap-y-2 gap-x-3  flex-wrap sm:flex-nowrap items-start">
            <div className="w-full  md:w-1/2">
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="bg-[#ebeff8] rounded-lg py-1.5 px-1.5 w-full focus-visible:outline-0"
                placeholder="First Name"
              />
              {errors.first_name && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.first_name}
                </p>
              )}
            </div>
            <div className="w-full  md:w-1/2">
            <input
              type="text"
              name="last_name"
                value={formData.last_name}
                onChange={handleChange}
              className="bg-[#ebeff8] rounded-lg py-1.5 px-1.5 w-full focus-visible:outline-0"
              placeholder="Last Name"
            />
              {errors.last_name && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.last_name}
                </p>
              )}
                </div>
          </div>
          <div className="mt-1.5 flex justify-between gap-y-2 gap-x-3 flex-wrap sm:flex-nowrap items-start">
            <div className="w-full sm:w-1/2 ">
              <label className=" font-medium">Email *</label>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-[#ebeff8] rounded-lg py-1.5 px-1.5 mt-1.5 w-full focus-visible:outline-0"
                placeholder="Yourmailid@xxxx.com"
              />
                  {errors.email && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.email}
                </p>
              )}
            </div>
            <div className="w-full sm:w-1/2 ">
              <label className=" font-medium">Contact Number *</label>
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleChange}
                className="bg-[#ebeff8] rounded-lg py-1.5 px-1.5 mt-1.5 w-full focus-visible:outline-0"
                placeholder="Enter Your Mobile Number"
              />
               {errors.contact_number && (
                <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                  {errors.contact_number}
                </p>
              )}
            </div>
          </div>
          <div className="mt-1.5">
            <div className=" text-sm font-medium">Reason *</div>
            <div
              onClick={() => setreasonToggle(!reasonToggle)}
              className={`text-black relative cursor-pointer mt-1  rounded-md text-sm px-4 py-2 relative ${
                reasonToggle
                  ? "bg-[#5f8170] rounded-b-none border-2 border-[#b3c4fa] border-b-0"
                  : "bg-[#ebeff8]"
              }`}
            >
              {reason !== "" ? reason : "Select a Reason"}
              <span
                className={`absolute top-1/2 right-2 -translate-y-1/2 ${
                  reasonToggle ? "text-white" : ""
                }`}
              >
                <IoMdArrowDropdown size={20} />
              </span>
            </div>
            {reasonToggle && (
              <ul
                className={`px-4 pt-1  bg-[#ebeff8] text-sm pb-2 rounded-b-md ${
                  reasonToggle ? "border-2 border-[#b3c4fa] border-t-0" : ""
                }`}
              >
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("About Order");
                  }}
                >
                  About Order
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Payment");
                  }}
                >
                  Payment
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Corporate Gift");
                  }}
                >
                  Corporate Gift
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Contact");
                  }}
                >
                  Contact
                </li>
                <li
                  className="py-1 cursor-pointer"
                  onClick={() => {
                    setreasonToggle(false);
                    setreason("Others");
                  }}
                >
                  Others
                </li>
              </ul>
            )}
            {errors.reason && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.reason}
              </p>
            )}
          </div>
          <label className=" font-medium mt-1.5 inline-block">Write Us*</label>
          <div className="mt-1.5">
            <textarea
              rows={5}
              name="message"
              onChange={handleChange}
              value={formData.message}
              placeholder="Your Text"
              className="resize-none bg-[#ebeff8] rounded-lg py-1.5 px-1.5 w-full focus-visible:outline-0"
            >{formData.message}</textarea>
              {errors.message && (
              <p className="bg-red-500 text-white text-xs mt-1 px-2 py-1 rounded-sm">
                {errors.message}
              </p>
            )}
          </div>
          <div className="mt-4 text-center flex justify-center">
            <button className={`theme_bg_green py-1.5 text-white px-4 min-w-[130px] rounded-lg flex justify-center items-center ${loading ? " opacity-50 cursor-none pointer-events-none":""}`}>
              Submit
            </button>
          </div>
        </form>
        <div className="max-w-[600px] mx-auto text-center text-md">
          <p className="mt-0 pt-1.5">OR</p>
          <p className="mt-0 pt-1">+91 95000 88250</p>
          <p className="mt-0 pt-1">
            We are in
            <a
              href="tel:+919500088250"
              className=" inline-block align-middle ml-2"
            >
              <FaPhoneAlt size={14} className="text-[#4f7661]" />
            </a>
            <a
              href="https://wa.me/+919500088250"
              target="_blank"
              className=" inline-block align-middle mx-2"
            >
              <IoLogoWhatsapp size={17} className="text-[#4f7661]" />
            </a>
            at 09:30AM-06:00PM
          </p>
          <p className="mt-0 pt-1">
            <a href="mailto:hello@theherbkey.com">hello@theherbkey.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
