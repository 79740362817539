import React, { useEffect } from 'react'
import TopHeader from '../components/TopHeader'
import Header from '../components/Header'
import Footer from '../components/Footer/Footer'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
const PaymentSuccess = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('payment-success')) {
            // Item exists, you can perform any necessary actions here
            // For example, remove the item and then proceed with your page logic
            localStorage.removeItem('payment-success');
        } else {
            // Item doesn't exist, redirect to another page
            navigate('/');
        }
    }, [])
    
    return (
        <>
            
            <Header disabledCart={true} />

            <div className='container mx-auto px-1 sm:px-2 md:px-3'>
                <h4 className=' text-lg sm:text-xl uppercase text-center font-medium '>Your order placed successfully </h4>
                <h4 className=' text-lg sm:text-xl uppercase text-center font-medium mt-3'>Your payment is done</h4>
                <div className=' text-center mt-4 flex justify-center gap-4'>
                    <Link to='/' className=' underline'>Return to Home</Link>
                    <Link to='/user-dashboard?tbOption=my_order' className=' underline'>Return to User Dashboard</Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PaymentSuccess